import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NotificationReducerState {
  text: string
  show: boolean
  data?: string
}

const initialState: NotificationReducerState = {
  text: '',
  show: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationText: (state, action: PayloadAction<string>) => {
      state.text = action.payload
    },
    setNotificationData: (state, action: PayloadAction<Record<string, any>>) => {
      state.data = JSON.stringify(action.payload)
    },
    setNotificationShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload
    },
    showNotification: (state, action: PayloadAction<{ text: string, data: Record<string, any> | string }>) => {
      state.show = true
      if (action?.payload != null) {
        state.text = action.payload.text
        if (typeof action.payload.data === 'string') {
          state.data = action.payload.data
        }
        else {
          state.data = JSON.stringify(action.payload.data)
        }
      }
    },
    hideNotification: (state) => {
      state.show = false
    },
    clearNotification: (_state) => {
      _state = {
        show: false,
        text: '',
        data: undefined
      }
    },
  },
})

export const {
  setNotificationData,
  setNotificationShow,
  setNotificationText,
  hideNotification,
  clearNotification,
  showNotification
} = notificationSlice.actions

export default notificationSlice.reducer
