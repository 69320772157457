import {SimpleButtonGroup} from "../../styled/SimpleButtonGroup";
import {Button} from "@mui/material";
import {MinusIcon, PlusIcon} from "../../../assets/icons/SvgIcons";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import * as d3 from "d3";
import {ZoomTransform} from "d3";
import {updateCurrentTransition} from "../../../store/mapSlice";
import {MapFormatter} from "../../../utils/MapFormatter";

export const Scale = () => {
  const {currentTransition: {scale, translate}} = useSelector((state: RootState) => state.map)
  const dispatch = useAppDispatch()

  const onScale = (increment: number) => () => {
    const newScale = MapFormatter.getScaleInRange(scale + increment)
    dispatch(updateCurrentTransition({scale: newScale}))
    d3.zoom().transform(d3.select("#svg-container"), new ZoomTransform(newScale, translate.x, translate.y))
  }

  return (
    <SimpleButtonGroup
      variant="text"
      color="secondary"
      aria-label="scale button group"
      size="small"
      sx={{mr: 2}}
    >
      <Button onClick={onScale(-0.1)}>
        <MinusIcon/>
      </Button>
      <Button>
        {Math.round(scale * 100)}%
      </Button>
      <Button onClick={onScale(0.1)}>
        <PlusIcon/>
      </Button>
    </SimpleButtonGroup>
  )
}
