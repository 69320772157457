import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { ValueRow } from "../../../amrs/ValueRow";
import { LocationIconSmall, RotationIconSmall } from "../../../../assets/icons/SmallSvgIcons";
import { useMutation, useQueryClient } from "react-query";
import { deleteDockParam, useDockParam } from "../../../../api/docking_params";
import { ROUTES } from "../../../../api/routes";
import { useEffect } from "react";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { clearCurrentDP, updateCurrentDP } from "../../../../store/dockparamsSlice";
import { useSelector } from "react-redux";

export const DockingParamsDetails = () => {
  let params = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { currentDP } = useSelector((state: RootState) => state.dps)
  const { isFetchedAfterMount, data: docking_params } = useDockParam(params.dockparamID ?? "")

  const deleteDPMutation = useMutation(deleteDockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.docking.key)
    }
  })

  useEffect(() => {
    if (isFetchedAfterMount && docking_params) {
      dispatch(updateCurrentDP({ dockParams: docking_params }))
    }
    return function cleanup() {
      dispatch(clearCurrentDP())
    };
  }, [docking_params, isFetchedAfterMount])

  const handleEditDP = () => {
    navigate("edit")
  }

  const handleDeleteDP = () => {
    currentDP && currentDP.id && deleteDPMutation.mutate(currentDP.id)
    navigate(`/fleet/dockingParams`)
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ToolbarTitle title={"Docking Parameters"} back={"dockingParams"} />
      {
        currentDP && <Box sx={{ height: "100%", display: "flex", flexDirection: "column", mt: 2 }}>
          <Typography variant="body1" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            {currentDP.name}
          </Typography>
          <Typography variant="body2" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            Dock Pose
          </Typography>
          <ValueRow icon={<LocationIconSmall fontSize="small" />} title="Location"
            value={`x${currentDP.dock_pose.x}, y${currentDP.dock_pose.y}`}
            sx={{ mb: 1 }}
          />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Rotation"
            value={`${currentDP.dock_pose.yaw}°`} />
          <Typography variant="body2" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            Approach pose
          </Typography>
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Lateral"
            value={`${currentDP.approach_pose.lateral}`} />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Direct"
            value={`${currentDP.approach_pose.direct}`} />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Angle Degrees"
            value={`${currentDP.approach_pose.angle_degrees}`} />
          <Typography variant="body2" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            Alignment Pose Distance: {currentDP.alignment_pose_distance}
          </Typography>
          <Typography variant="body2" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            Dock Forwards: {currentDP.dock_forwards ? "True" : "False"}
          </Typography>

          <Box sx={{
            pt: 2,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}>
            <Button variant="outlined" sx={{ mb: 1, borderRadius: 0.5 }} onClick={handleEditDP}>Edit</Button>
            <Button variant="outlined" color="error" sx={{ borderRadius: 0.5 }} onClick={handleDeleteDP}>Delete</Button>
          </Box>
        </Box>
      }
    </Box>
  )
}
