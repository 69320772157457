import { useSelector } from "react-redux";
import { Fragment, useCallback, useState } from "react";
import { IconButton, List, ListItemText, Typography } from "@mui/material";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import ListItemButton from '@mui/material/ListItemButton';
import {
  getMapList,
  setSelectedMapId,
  getSelectedMapId,
  setMaps,
} from "store/mapSlice";
import { useAppDispatch } from "store/store";
import { IMap } from "model/Map";
import { updateCurrentTransition } from "store/mapSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useMutation } from "react-query";
import { getAllMaps } from "api/map";
import { useEffect } from "react";

export const MapsPanel = () => {
  const [reloadPage, setReloadPage] = useState(true)
  const dispatch = useAppDispatch()
  const maps = useSelector(getMapList)
  const selectedMapId = useSelector(getSelectedMapId)
  const handleItemClick = useCallback((map: IMap) => {
    localStorage.setItem('lastMap', map.id)
    dispatch(setSelectedMapId(map.id))
    const { naturalWidth, naturalHeight } = map.info
    const scale = 1080 / naturalHeight;
    dispatch(updateCurrentTransition({ translation: {x: 0, y: 0}, scale}))
  }, [selectedMapId])

  useEffect(() => {
    if (reloadPage) {
      const fetch = async () => {
        const mapData = await getAllMaps()
        if (mapData) {
          dispatch(setMaps(mapData))
        }
      }
      fetch()
      setReloadPage(false)
    }
  })

  return (
    <>
      <ToolbarTitle title={"Maps"} />
      <List>
        {maps.map((map, id) =>
          <Fragment key={id}>
            <ListItemButton
              key={map.id}
              sx={{ pl: 4, borderRadius: 1 }}
              onClick={() => handleItemClick(map)}
            >
              {map.id === selectedMapId ?
                <IconButton>
                  <RadioButtonCheckedIcon sx={{ color: "secondary.main", width: 18, height: 18 }} />
                </IconButton>
                :
                <IconButton>
                  <RadioButtonUncheckedIcon sx={{ color: "secondary.main", width: 18, height: 18 }} />
                </IconButton>
              }

              <ListItemText>
                <Typography variant="body2">
                  {map.mapName}
                </Typography>
              </ListItemText>

            </ListItemButton>
          </Fragment>

        )}
      </List>
    </>
  )
}
