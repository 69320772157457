import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import {
  getNodes,
  getSelectedNode,
  getNewNode,
  setSelectedNode,
  getIsAddingEdge,
  getIsEditingNode,
  getIsAddingNode,
  getIsRemovingEdge,
  getIsPlacingNewNode,
  addEdgesToRemove,
  addEdge,
  getEdges,
  updateNewNode,
  updateSelectedNode,
  toggleAddingEdge,
  toggleIsRemovingEdge,
  toggleIsPlacingNewNode,
} from "store/nodeSlice";
import {
  removeNodesFromIndex,
  getIsBuildingOrder,
  getNewOrderAction,
  addNodeToOrder,
  addEdgeToOrder,
  getNewOrder,
  getSelectedOrder,
  setSingleOrderNode,
  removeEdgesFromIndex,
  getAmrAsPathStart,
  getPathingType,
} from "store/orderSlice"
import {
  dock as dockIcon,
  undock as undockIcon,
  lift as liftIcon,
  drop as dropIcon,
  playCircleFilled as startIcon,
  pathGoal as stopIcon,
  undefinedAction as undefinedActionIcon,
  charging as chargingIcon,
} from "assets/iconPath/iconPaths";

import { getMapInfo, getSelectedMapId } from "store/mapSlice";
import { mapPixelLocationToM, mapMToPixel } from "model/Map";

import { INode, IFrontierNode } from "model/Nodes";
import * as d3 from "d3";


const CIRCLE_RADIUS = 20

const mapActionToIcon = (action: string) => {
  switch (action) {
    case "drop":
      return dropIcon
    case "pick":
      return liftIcon
    case "dock":
      return dockIcon
    case "undock":
      return undockIcon
    case "charge":
      return chargingIcon
    default:
      return undefinedActionIcon
  }
}

export const NodesLayer = () => {
  const nodes = useSelector(getNodes)
  const edges = useSelector(getEdges)
  const mapInfo = useSelector(getMapInfo)
  const selectedNode = useSelector(getSelectedNode)
  const newNode = useSelector(getNewNode)
  const editing = useSelector(getIsEditingNode)
  const isAddingEdge = useSelector(getIsAddingEdge)
  const isAddingNode = useSelector(getIsAddingNode)
  const isPlacingNewNode = useSelector(getIsPlacingNewNode)
  const isBuildingOrder = useSelector(getIsBuildingOrder)
  const isRemovingEdge = useSelector(getIsRemovingEdge)
  const newOrderAction = useSelector(getNewOrderAction)
  const amrAsPathStart = useSelector(getAmrAsPathStart)
  const pathingType = useSelector(getPathingType)
  const mapId = useSelector(getSelectedMapId)
  const newOrder = useSelector(getNewOrder)
  const selectedOrder = useSelector(getSelectedOrder)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()

  const placeCircle = useCallback((dimension: string) => (d: any) => {
    return mapMToPixel(mapInfo, d.location[dimension], dimension)
  }, [mapInfo])

  useEffect(() => {
    if (!amrAsPathStart) {
      return
    }
    const robotX = amrAsPathStart.status.location.x
    const robotY = amrAsPathStart.status.location.y


    const closestNode = nodes.reduce((acc, cur) => {
      const newDistance = Math.hypot((cur.location.x - robotX), (cur.location.y - robotY))
      if (newDistance < acc['distance']) {
        return {'node': cur, 'distance': newDistance}
      }
      return acc
    }, { 'node': nodes[0], 'distance': 1000 })
    dispatch(setSingleOrderNode(closestNode.node.id))
  }, [amrAsPathStart])


  const dragging = (nodeType: string) => (event: any, d: INode) => {
    const location = { x: event.x, y: event.y }
    const mappedLocation = mapPixelLocationToM(mapInfo, location)
    if (nodeType === "current") {
      dispatch(updateSelectedNode(mappedLocation))
    } else {
      dispatch(updateNewNode(mappedLocation))
    }
  }


  const handleAddEdgeToRemove = (clickedNode: INode) => {
    const edge = edges.find(edge => {
      return edge.node_ids.includes(clickedNode.id) && edge.node_ids.includes(selectedNode?.id || " ")
    })
    dispatch(addEdgesToRemove(edge?.id || " "))
    dispatch(toggleIsRemovingEdge(false))
  }


  const generatePath = useCallback((clickedNode: INode, pathingType: string) => {

    if (pathingType === "manual") {
      generateManualPath(clickedNode)
      return
    }

    if (!newOrder || newOrder.nodes.length === 0) {
      return
    }

    const targetNode = newOrder.nodes[newOrder.nodes.length - 1]
    let tree: IFrontierNode[] = []
    let frontier: IFrontierNode[] = [{ "node_id": clickedNode.id }]
    let routeFound = false
    let failed = false
    let nodesToAdd = []
    let edgesToAdd = []
    while (!routeFound && !failed) {
      // for each node in the frontier,
      // find adjoining nodes(s)
      // check if they are target,
      // if not, add adjoining nodes as frontier
      if (frontier.length === 0) {
        failed = true
      }

      const frontierNode = frontier.pop()
      const frontierEdges = edges.filter(edge => edge.node_ids.includes(frontierNode?.node_id || " "))
      const otherNodes = frontierEdges.map(edge =>
      ({
        "node_id": edge.node_ids.find(id => id !== frontierNode?.node_id),
        "edge_id": edge.id
      })
      )
      const linkingOtherNode = otherNodes.find(node => node.node_id === targetNode.node_id)
      if (linkingOtherNode) {
        routeFound = true
        const linkingEdge = linkingOtherNode.edge_id
        let nextNode = frontierNode
        edgesToAdd.push(linkingEdge)
        while (nextNode) {
          nodesToAdd.push(nextNode.node_id)
          edgesToAdd.push(nextNode.previous_edge)
          const previous_id = nextNode?.previous_node
          nextNode = tree.find(treeItem => treeItem.node_id === previous_id)
        }
        break
      }

      otherNodes.forEach(node => {
        if (!tree.find(treeNode => treeNode.node_id === node.node_id)) {
          frontier.push(
            {
              "node_id": node?.node_id || " ",
              "previous_node": frontierNode?.node_id || " ",
              "previous_edge": node.edge_id
            }
          )
        }
      })
      if (frontierNode) {
        tree.push(frontierNode)
      }

    }

    nodesToAdd.forEach(node => {
      dispatch(addNodeToOrder(node))
    })

    edgesToAdd.forEach(edge => {
      if (edge) {
        dispatch(addEdgeToOrder(edge))
      }
    })

  }, [newOrder])


  const generateManualPath = useCallback((clickedNode: INode) => {
    if (!newOrder) {
      return
    }
    const targetNode = newOrder.nodes[newOrder.nodes.length - 1]
    const adjoiningEdge = edges.find(edge => {
      return edge.node_ids.includes(clickedNode.id) && edge.node_ids.includes(targetNode.node_id)
    })
    if (adjoiningEdge) {
      dispatch(addNodeToOrder(clickedNode.id))
      dispatch(addEdgeToOrder(adjoiningEdge.id))
    }

  }, [newOrder])


  const nodeIsAtTop = (order: any, nodeId: string) => {
    if (!order || order.nodes.length < 1) {
      return false
    }
    return order.nodes[order.nodes.length - 1].node_id === nodeId
  }

  const handleNodeForOrderClicked = useCallback((clickedNode: INode) => {
    // case first node in order
    if (newOrder && newOrder.nodes.length === 0) {
      dispatch(addNodeToOrder(clickedNode.id))
    } else if (newOrderAction) {
      dispatch(setSingleOrderNode(clickedNode.id))
    }

    // case node not in order, and no action
    else if (!nodeIsAtTop(newOrder, clickedNode.id)) {

        generatePath(clickedNode, pathingType)
      //generatePath(clickedNode)
    }

    // node is already in order
    else {
      const idx = newOrder?.nodes.findIndex(node => node.node_id === clickedNode.id) || 0
      dispatch(removeNodesFromIndex(idx))
      dispatch(removeEdgesFromIndex(idx - 1))
    }


  }, [newOrder, nodes, edges, pathingType])

  const onNodeClicked = useCallback((e: any, clickedNode: INode) => {
    // https://motius.atlassian.net/browse/MVS-3236
    if (isRemovingEdge) {
      handleAddEdgeToRemove(clickedNode)
    } else if (isBuildingOrder) {
      handleNodeForOrderClicked(clickedNode)
    } else if (isAddingEdge && isAddingNode && newNode) {
      dispatch(addEdge([clickedNode, newNode]))
      dispatch(toggleAddingEdge(false))
    } else if (isAddingEdge && selectedNode) {
      dispatch(addEdge([clickedNode, selectedNode]))
      dispatch(toggleAddingEdge(false))
    } else if (!isAddingNode) {
      dispatch(setSelectedNode(clickedNode))
      navigate("nodes/edit");
    }

  }, [pathingType,
    isAddingNode,
    isRemovingEdge,
    selectedNode,
    newOrder,
    isBuildingOrder,
    isAddingEdge,
    isPlacingNewNode,
    newNode])

  // TODO refactor and tidy maybe pls.
  const setColor = useCallback((d: INode) => {
    if (d.id === selectedNode?.id) {
      return theme.palette.error.light
    } else if (isPlacingNewNode && isAddingNode   && d.id === newNode?.id) {
      return theme.palette.error.main
    } else if (!isPlacingNewNode && isAddingNode && d.id === newNode?.id) {
      return theme.palette.error.light
    } else if (newOrder && newOrder.nodes.find(node => node.node_id === d.id)) {
      return theme.palette.error.light
    } else if (selectedOrder && selectedOrder.nodes.find(node => node.node_id === d.id)) {
      return theme.palette.error.light
    } else {
      return theme.palette.info.main
    }
  }, [selectedOrder, newOrder, selectedNode, isPlacingNewNode, isAddingNode, newNode])

  useEffect(() => {
    d3.select('#node-layer').raise()
  }, [])

  const newNodeClicked = useCallback((e: any, d: INode) => {
      dispatch(toggleIsPlacingNewNode(false))

  }, [])


  useEffect(() => {
    d3.select("#map-svg").on("mousemove", function (event) {
      if (isPlacingNewNode) {
        let coords = d3.pointer(event);
        const location = { x: coords[0], y: coords[1] }
        const mappedLocation = mapPixelLocationToM(mapInfo, location)
        dispatch(updateNewNode(mappedLocation))
      }
    });
  }, [mapInfo, editing, isAddingNode, isPlacingNewNode]);


  useEffect(() => {
    const nodesWithoutSelected = nodes.filter(node => node.id !== selectedNode?.id)
    if (!mapId) {
      d3.select("#saved-nodes-layer")
        .selectAll('circle')
        .remove()

      return
    }
    d3.select("#saved-nodes-layer")
      .selectAll('circle')
      .data(nodesWithoutSelected, (d: any) => d.id)
      .join(enter => enter.append('circle')
        .attr('cx', placeCircle("x"))
        .attr('cy', placeCircle("y"))
        .attr('r', 20)
        .style("fill", setColor)
        .style("cursor", "pointer")
        .on('click', onNodeClicked),
        update => update.on('click', onNodeClicked).transition().duration(1000)
          .style("fill", setColor),
        exit => exit.remove()
      )
  }, [selectedOrder,
    isRemovingEdge,
    newOrder,
    isBuildingOrder,
    nodes,
    selectedNode,
    isAddingEdge,
    isAddingNode,
    newNode,
    mapId,
  ])

  useEffect(() => {
    if (!selectedNode) {
      d3.select("#current-node-layer")
        .selectAll('circle')
        .remove()

      return
    }
    d3.select("#current-node-layer")
      .selectAll('circle')
      .data([selectedNode])
      .join(enter => enter.append('circle')
        .attr('cx', placeCircle("x"))
        .attr('cy', placeCircle("y"))
        .attr('r', 20)
        .style("cursor", "pointer")
        .style("fill", setColor),
        update => update.transition().duration(20)
          .attr('cx', placeCircle("x"))
          .attr('cy', placeCircle("y"))
          .style("fill", setColor),
        exit => exit.remove()
        // @ts-expect-error
      ).call(d3.drag()
        // @ts-expect-error
        .on('drag', dragging("current"))
      )

  }, [mapInfo, nodes, selectedNode])

  useEffect(() => {
    if (!newNode) {
      d3.select("#new-node-layer").selectAll('circle').remove()
      return
    }
    if (!!newNode.location.x && !!newNode.location.y) {
      d3.select("#new-node-layer")
        .selectAll('circle')
        .data([newNode])
        .join(enter => enter.append('circle')
          .attr('cx', placeCircle("x"))
          .attr('cy', placeCircle("y"))
          .attr('r', 20)
          .on("click", newNodeClicked)
          .style("fill", setColor),
          update => update.on("click", newNodeClicked)
            .transition().duration(50)
            .attr('cx', placeCircle("x"))
            .attr('cy', placeCircle("y"))
            .style("fill", setColor),
          exit => exit.remove()
          // @ts-expect-error
        ).call(d3.drag()
          // @ts-expect-error
          .on('drag', dragging("new"))
        )
    }
  }, [mapInfo, isPlacingNewNode, newNode])


  const translateImage = (d: INode) => {
    return `translate(${mapMToPixel(mapInfo, d.location.x, 'x') - CIRCLE_RADIUS * 3}, ${mapMToPixel(mapInfo, d.location.y, 'y') - CIRCLE_RADIUS * 5}) scale(3.0)`
  }

  const placeActionIcon = (d: INode) => {
    return `translate(${mapMToPixel(mapInfo, d.location.x, 'x') + CIRCLE_RADIUS * 0}, ${mapMToPixel(mapInfo, d.location.y, 'y') - CIRCLE_RADIUS * 5}) scale(3.0)`
  }


  useEffect(() => {
    if (!newOrder || newOrder.nodes.length < 2) {
      d3.select("#order-info-layer")
        .selectAll("path")
        .remove()
    }

    if (newOrder && newOrder.nodes.length > 1) {

      const firstNode = nodes.find(node => node.id === newOrder.nodes[0].node_id)
      const finalNode = nodes.find(node => node.id === newOrder.nodes[newOrder.nodes.length -1 ].node_id)
      d3.select("#order-info-layer")
        .selectAll('path')
        .data([firstNode, finalNode])
        .join(enter => enter.append('path')
          .attr('d', (d, i) => i === 0 ? startIcon : stopIcon)
          .style('fill', 'black')
          .style('stroke', 'white')
          // @ts-expect-error
          .attr('transform', translateImage),
          // @ts-expect-error
          update => update.attr('transform', translateImage),
        )
    }

    if (!newOrder || !newOrder.nodes.find(node => node.actions.length > 0)) {
      d3.select("#action-info-layer")
        .selectAll('path')
        .remove()

    } else {
      const actionNode = newOrder.nodes.find(node => node.actions.length > 0)
      if (!actionNode) {
        return
      }
      const action = actionNode.actions[0]
      const correspondingNode = nodes.find(node => node.id === actionNode.node_id)
      d3.select("#action-info-layer")
        .selectAll('path')
        .data([action], (d: any) => d.action_type)
        .join(enter => enter.append('path')
          .attr('d', (d: any) => mapActionToIcon(d.action_type))
          .style('fill', 'black')
          .style('stroke', 'white')
          // @ts-expect-error
          .attr('transform', () => placeActionIcon(correspondingNode)),
          update => update.attr('d', (d: any) => mapActionToIcon(d.action_type))
            // @ts-expect-error
            .attr('transform', () => placeActionIcon(correspondingNode)),
        )

    }
  }, [newOrder, nodes])



  return (
    <g id="node-layer">
      <g id="saved-nodes-layer"></g>
      <g id="current-node-layer"></g>
      <g id="new-node-layer"></g>
      <g id="order-info-layer"></g>
      <g id="action-info-layer"></g>
    </g>
  )
}
