import {ROUTES} from "./routes";
import {useQuery} from "react-query";
import {axiosInstance} from "./axios";
import {z} from "zod";
import { zMapResponse, mapMapFromApi, mapMapToApi, IMap, IMapApi } from "model/Map"

export const zMapsResponse = z.array(zMapResponse)

// @ts-ignore
export const DEFAULT_MAP_ID = window._env?.REACT_APP_DEFAULT_MAP_ID ?? ""

export const createMap = async (map: IMap) => {
  // TODO make sure to fetch map id map
  const { data } = await axiosInstance.post(`${ROUTES.map.url}`, mapMapToApi(map))
  return data
};

export const updateMapZone = async (mapId: string, zoneId: string) => {
  const { data } = await axiosInstance.patch(`${ROUTES.map.url}/set_zone/${mapId}`, {"active_zone_id": zoneId} )
  return data
}

export const getAllMaps = async() => {
  try {
    const { data } = await axiosInstance.get(`${ROUTES.map.url}`)
    return data.map(mapMapFromApi)
  } catch {
    return 
  }
}

export const getMapActiveZone = async (mapId: string ) => {
  const { data } = await axiosInstance.get(`${ROUTES.map.url}/get_active_zone_id/${mapId}`)
  return data
}

export const getMap = async () => {
  const {data} = await axiosInstance.get(`${ROUTES.map.url}`)
  const safeParse = zMapsResponse.safeParse(data)
  if (safeParse.success) {
    return safeParse.data
  } else return []
}

export function useMap() {
  return useQuery(ROUTES.map.key, getMap);
}
