import {
  Box,
  Button,
  List,
  ListItemText,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "store/store";
import { useMutation, useQueryClient } from "react-query";
import { defaultNode, INode } from "model/Nodes";
import { useSelector } from "react-redux";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { MapFormatter } from "utils/MapFormatter";
import { createJob, getJob } from "api/jobs"
import { IOrder } from "model/Orders";
import {
  addJob,
  getEditingJob,
  addOrderToJob,
  updateEditingJobName,
  toggleIsBuildingJob,
  clearEditingJob,
  removeOrderFromJob,
} from "store/jobSlice"

import { getOrdersBySelectedMap } from "store/orderSlice"


export const AddJob = () => {
  const [error, setError] = useState(false);

  const newJob = useSelector(getEditingJob)
  const orders = useSelector(getOrdersBySelectedMap).map(j => j).sort((a, b) => a.name < b.name ? -1 : 1)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const { mutateAsync: asyncJob } = useMutation(createJob)
  const { mutateAsync: asyncGetJob } = useMutation(getJob)

  useEffect(() => {
    if (!newJob) {
      navigate('/fleet/jobs')
    }
  }, [newJob])


  const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(updateEditingJobName({ name: event.target.value }))
  };

  const handleSaveJob = async () => {
    if (newJob) {
      try {
        const jobId = await asyncJob(newJob)
        const fetchedJob = await asyncGetJob(jobId)
        dispatch(addJob(fetchedJob))
        dispatch(toggleIsBuildingJob(false));
        dispatch(clearEditingJob());
        navigate('/fleet/orders')

      } catch (error) {
        console.error('Creation of order failed', error)
      }

    }

  }

  const onClickHandler = () => {
    dispatch(clearEditingJob())
    dispatch(toggleIsBuildingJob(false))
  }

  const handleOrderClicked = (order: IOrder) => () => {
    dispatch(addOrderToJob(order))
  }

  const handleOrderDelete = (orderId: string) => () => {
    dispatch(removeOrderFromJob(orderId))
  }

  return (
    <>{newJob && <>
      <ToolbarTitle title={"Build Job"} onClick={onClickHandler} back="order" />
      <FormControl fullWidth error={error}>
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
          Job Name
        </Typography>
        <OutlinedInput placeholder="Please enter text" value={newJob.name} onChange={handleChangeName} />

      </FormControl>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body1"}>
        Selected Orders
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        {newJob.orders.map(order =>
          <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
            handleDelete={handleOrderDelete(order.id)}
          >
            <ListItemText>
              <Typography variant="body2">
                {order.name}
              </Typography>
            </ListItemText>
          </ListItemWithCRUD>)}
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body1"}>
        Orders Choices
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        {orders.map(order =>
          <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
            onClick={handleOrderClicked(order)}
          >
            <ListItemText>
              <Typography variant="body2">
                {order.name}
              </Typography>
            </ListItemText>
          </ListItemWithCRUD>
        )}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save POI"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ p: 1.5 }}
          onClick={handleSaveJob}
          disabled={!(newJob.name && newJob.orders.length > 0)}
        >
          Save Job
        </Button>
      </Box>
    </>}
    </>
  )
}
