import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getIsAddingZone, getZoneBrush } from "store/zoneSlice";
import { getCurrentTransition } from "store/mapSlice";
import { IZoneCircle, IZoneBrush } from "model/Zones";

import * as d3 from "d3";

export const NewZoneLayer = () => {
  const isAddingZone = useSelector(getIsAddingZone)
  const zoneBrush = useSelector(getZoneBrush)
  const { translate, scale } = useSelector(getCurrentTransition)
  const defaultState: IZoneCircle[] = []
  const [circles, setCircles] = useState(defaultState)


  useEffect(() => {

  }, [zoneBrush])

  const circleDistance = (a: IZoneCircle, b: IZoneCircle) => {
    return (Math.abs(a.location.x - b.location.x)) + Math.abs((a.location.y - b.location.y))
  }

  const shouldPlace = (oldCircles: IZoneCircle[], newCircle: IZoneCircle) => {
    if (oldCircles.length === 0) {
      return true
    }

    return circleDistance(oldCircles[oldCircles.length - 1], newCircle) > (zoneBrush.radius / 3)
  }

  const handleDrag = (event: any) => {
    const newCircle = { location: { x: (event.x - translate.x) / scale, y: (event.y - translate.y) / scale } }
    setCircles(oldCircles => {
      if (shouldPlace(oldCircles, newCircle)) {
        return [...oldCircles, newCircle]
      }
      return oldCircles
    })
  }


  const placeCircle = (dimension: string) => (d: any) => {
    if (dimension === "x") {
      return d.location.x
    } else {
      return d.location.y
    }
  }

  useEffect(() => {
    if (isAddingZone) {
      // @ts-expect-error
      d3.select("#map-svg").call(d3.drag().on('drag', handleDrag))
    }

  }, [isAddingZone])

  const makeRadius = (zoneBrush: IZoneBrush) => {
    return zoneBrush.radius
  }

  const makeColor = (zoneBrush: IZoneBrush) => {
    return zoneBrush.color
  }

  useEffect(() => {
    if (!isAddingZone) {
      if (circles.length > 0) {
        setCircles([])
        d3.select("#new-zone-layer")
          .selectAll('circle')
          .remove()
      }
      return
    }

    d3.select("#new-zone-layer")
      .selectAll('circle')
      .data(circles)
      .join(enter => enter.append('circle')
        .attr('cx', placeCircle("x"))
        .attr('cy', placeCircle("y"))
        .attr('r', makeRadius(zoneBrush))
        .style("fill", makeColor(zoneBrush))
      )

  }, [circles, isAddingZone, zoneBrush])




  return (
    <>
      <g id="new-zone-layer">
      </g>
    </>
  )
}
