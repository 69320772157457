import {alpha, IconButton, IconButtonProps, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface BigIconButtonProps extends IconButtonProps {
  tooltip?: string
  active: boolean
}

export const BigIconButton = (props: BigIconButtonProps) => {
  const theme = useTheme()
  const propsToRender =  {...props, "active": props.active ? "true": "false"}
  return (
    <Tooltip title={props.tooltip ?? ''} disableHoverListener={!props.tooltip}>
      <IconButton
        {...propsToRender}
        sx={{
          ...props.sx,
          bgcolor: props.active ? "primary.main" : "#F2F2F2",
          color: props.active ? "#FFFFFF" : "textPrimary",
          "&:hover": {
            bgcolor: alpha(theme.palette.primary.light, 0.6)
          }
        }}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}
