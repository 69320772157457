import {styled} from "@mui/material/styles";
import {Card} from "@mui/material";

export const SOFT_SHADOW = "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.07))";

export const SoftShadowCard = styled(Card)(({theme}) => ({
  borderRadius: 10,
  filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.07))",
  boxShadow: "none",
}));
