import {FleetOverlay} from "../map/fleetOverlay/FleetOverlay";
import {FleetManagerMap} from "../map/FleetManagerMap";
import {useAppDispatch} from "../../store/store";
import {setSelectedMapId} from "../../store/mapSlice";

export const FleetManagerPage = () => {

  const dispatch = useAppDispatch();
  const lastMap = localStorage.getItem('lastMap');
  if (lastMap !== null) {
      dispatch(setSelectedMapId(lastMap))
  }

  return (
    <>
      <FleetManagerMap/>
      <FleetOverlay/>
    </>
  )
}
