import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {ReactNode} from "react";
import {SimpleTableRow} from "./SimpleTableRow";

export interface AnyTableData {
  [key: string]: any
}

export interface Column<T extends AnyTableData> {
  id: keyof T;
  label: string;
  minWidth?: number | string;
  align?: TableCellProps["align"];
  format?: (data: T) => string | ReactNode;
  component?: (data: T) => ReactNode
}

interface SimpleStickyTableProps<T extends AnyTableData> {
  columns: Column<T>[]
  rows: T[]
  progress?: boolean
  noDataLabel?: string
}

export function SimpleStickyTable<T extends AnyTableData>(props: SimpleStickyTableProps<T>) {
  let {columns, rows, progress, noDataLabel} = props;
  return (
    <TableContainer sx={{maxHeight: 440}}>
      <Table stickyHeader aria-label="sticky table" sx={{caption: {textAlign: "center"}}}>
        {rows && rows.length === 0 &&
          <caption><Typography sx={{textAlign: "center"}} variant="caption"
                               color="textSecondary">{noDataLabel ?? "No data"}</Typography>
          </caption>}
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                sx={{
                  p: 0.5,
                  pl: 2,
                  width: 150,
                  fontSize: 12,
                  bgcolor: "background.paper",
                  color: "text.secondary"
                }}
                key={column.id.toString()}
                align={"left"}
                style={{minWidth: column.minWidth}}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .map((row, rowIndex) => {
              return (
                <SimpleTableRow key={rowIndex} columns={columns} data={row} progress={progress}/>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
