import { INodeAction } from "./Actions"

export enum OrderStatus {
  UNASSIGNED = "UNASSINGED",
  QUEUED = "QUEUED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  COMPLETE = "COMPLETE"
}

export interface IOrderNode {
  node_id: string
  sequence_id?: number
  released: boolean
  actions: INodeAction[]
}

export interface IOrderEdge {
  edge_id: string
  released: boolean
  start_node_id: string
  end_node_id: string
  max_speed?: number
  sequence_id?: number
  actions: INodeAction[]
}

export interface IOrderAction {
  node_id: string
  action?: INodeAction
}


export interface IOrder {
  id: string
  order_status?: OrderStatus
  order_update_id?: number
  name: string
  nodes: IOrderNode[]
  edges: IOrderEdge[]
}

export interface IOrderAPIResponse {
  _id: string,
  order_status?: OrderStatus
  name: string
  nodes: IOrderNode[]
  edges: IOrderEdge[]
}

export interface IOrderToAPI {
  order_status?: OrderStatus
  name: string
  order_update_id?: number
  nodes: IOrderNode[]
  edges: IOrderEdge[]
}


export const mapOrderFromApi = (apiResponse: IOrderAPIResponse): IOrder => {
  const { _id: _, ...order } = apiResponse
  return {
    ...order,
    id: apiResponse._id
  }
}

export const mapOrderToApi = (order: IOrder): IOrderToAPI => {
  const { id, ...toAPIOrder } = order
  toAPIOrder.order_update_id = 0
  toAPIOrder.order_status = OrderStatus.UNASSIGNED
  toAPIOrder.nodes = order.nodes.map((node, idx) => (
    {
      ...node,
      sequence_id: idx * 2,
    }
  )
  )

  toAPIOrder.edges = order.edges.map((edge, idx) => (
    {
      ...edge,
      sequence_id: (idx * 2) + 1,
      start_node_id: toAPIOrder.nodes.filter((node) => node.sequence_id === (idx * 2))[0].node_id,
      end_node_id: toAPIOrder.nodes.filter((node) => node.sequence_id === ((idx * 2) + 2))[0].node_id
    }
  )
  )

  return toAPIOrder

}
