import { NavDrawer } from "./NavDrawer";
import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes, } from 'react-router-dom'
import * as d3 from "d3";

// Pages
// import { Mapping } from "components/map/mapping/Mapping";
import { FleetManagerPage } from "components/pages/FleetManagerPage";
import { AdminPage } from "components/pages/AdminPage";
import { LiveJobsPage } from "components/pages/LiveJobsPage";
import { MappingPage } from "components/pages/MappingPage";
import { NotificationsPage } from "components/pages/NotificationsPage";

// import { ZonesPanel } from "components/map/fleetOverlay/zones/ZonesPanel";

import { NotificationSnackbar } from "components/snackbar/NotificationSnackbar"
import { ErrorSnackbar } from "components/notifications/ErrorSnackbar"

// Fleet Panels
// import { AddZone } from "components/map/fleetOverlay/zones/AddZone";
// NODES
import { NodesPanel } from "components/fleetPanels/nodes/NodesPanel";
import { AddNode } from "components/fleetPanels/nodes/AddNode";
import { EditNode } from "components/fleetPanels/nodes/EditNode";

// ORDERS
import { OrdersPanel } from "components/fleetPanels/orders/OrdersPanel";
import { AddOrder } from "components/fleetPanels/orders/AddOrder";
import { ViewOrder } from "components/fleetPanels/orders/ViewOrder";
import { EditOrder } from "components/fleetPanels/orders/EditOrder";

// JOBS
import { JobsPanel } from "components/fleetPanels/jobs/JobsPanel";
import { AddJob } from "components/fleetPanels/jobs/AddJob";
import { SelectedJob } from "components/fleetPanels/jobs/SelectedJob";
import { EditJob } from "components/fleetPanels/jobs/EditJob";

// AMRS
import { AMRsPanel } from "components/fleetPanels/amrs/AMRsPanel";
// MAPS
import { MapsPanel } from "components/fleetPanels/maps/MapsPanel";

// ZONES
import { ZonesPanel } from "components/fleetPanels/zones/ZonesPanel";
import { AddZone } from "components/fleetPanels/zones/AddZone";



// Deprecated Panels
import { JobWorkerPage } from "components/pages/JobWorkerPage";
// import { AMRPage } from "components/pages/AMRPage";
import { AddDockingParams } from "components/map/fleetOverlay/docking/AddDockingParams";
import { DockingParamsDetails } from "components/map/fleetOverlay/docking/DockingParamsDetails";
import { EditDockingParas } from "components/map/fleetOverlay/docking/EditDockParams";
import { AddUndockingParams } from "components/map/fleetOverlay/undocking/AddUndockingParams";
import { UndockingParamsDetails } from "components/map/fleetOverlay/undocking/UndockingParamsDetails";
import { EditUndockingParas } from "components/map/fleetOverlay/undocking/EditUndockParams";
import { DockingPanel } from "components/map/fleetOverlay/docking/DockingPanel";
import { UndockingPanel } from "components/map/fleetOverlay/undocking/UndockingPanel";
import { useEffect } from "react";
import { setNodes, setEdges, getIsNodesLoaded } from "store/nodeSlice";
import { setOrders } from "store/orderSlice";
import { setJobs } from "store/jobSlice";
import { setRobotConfigs } from "store/amrSlice";

import { useSelector } from "react-redux";
import { getAllNodes } from "api/nodes";
import { getAllEdges } from "api/edges";
import { getAllOrders } from "api/orders";
import { getAllJobs } from "api/jobs";
import { getAllRobotConfigs } from "api/robotConfig";
import { useAppDispatch } from "store/store";

export const Root = () => {

    const isNodesLoaded = useSelector(getIsNodesLoaded)
    const dispatch = useAppDispatch()

    // load nodes, orders, jobs
    useEffect(() => {
        if (!isNodesLoaded) {
            const fetch = async () => {
                try {
                    // TODO: https://motius.atlassian.net/browse/MVS-3236
                    const nodeData = await getAllNodes()
                    dispatch(setNodes(nodeData))
                    const edgeData = await getAllEdges()
                    dispatch(setEdges(edgeData))
                    const orderData = await getAllOrders()
                    dispatch(setOrders(orderData))
                    const jobData = await getAllJobs()
                    dispatch(setJobs(jobData))
                    const robotConfigs = await getAllRobotConfigs()
                    dispatch(setRobotConfigs(robotConfigs))
                    d3.select('#node-layer').raise();
                } catch (e) {
                    console.error("Initial fetch failed.", e)
                }
            }
            fetch()
        }
    }, [isNodesLoaded])

    return (
        <>
            <BrowserRouter>
                <Box sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row", } }}>
                    <NavDrawer />
                    <Box component="main" sx={{ flexGrow: 1, height: "100vh", overflow: "auto" }}>
                        <Routes>
                            <Route path='/' element={<FleetManagerPage />} />
                            <Route path='/fleet' element={
                                <FleetManagerPage />
                            }>
                                <Route path="nodes">
                                    <Route index element={<NodesPanel />} />
                                    <Route path="add" element={<AddNode />} />
                                    <Route path="edit" element={<EditNode />} />
                                </Route>
                                <Route path="orders">
                                    <Route index element={<OrdersPanel />} />
                                    <Route path="add" element={<AddOrder />} />
                                    <Route path="view" element={<ViewOrder />} />
                                    <Route path="edit" element={<EditOrder />} />
                                </Route>
                                <Route path="jobs">
                                    <Route index element={<JobsPanel />} />
                                    <Route path="add" element={<AddJob />} />
                                    <Route path="selected" element={<SelectedJob />} />
                                    <Route path="edit" element={<EditJob />} />
                                </Route>
                                <Route path="maps">
                                    <Route index element={<MapsPanel />} />
                                </Route>
                                <Route path="zones">
                                    <Route index element={<ZonesPanel />} />
                                    <Route path="add" element={<AddZone />} />
                                </Route>
                                <Route path="dockingParams">
                                    <Route index element={<DockingPanel />} />
                                    <Route path="add" element={<AddDockingParams />} />
                                    <Route path=":dockparamID">
                                        <Route index element={<DockingParamsDetails />} />
                                        <Route path="edit" element={<EditDockingParas />} />
                                    </Route>
                                </Route>
                                <Route path="undockingParams">
                                    <Route index element={<UndockingPanel />} />
                                    <Route path="add" element={<AddUndockingParams />} />
                                    <Route path=":undockparamID">
                                        <Route index element={<UndockingParamsDetails />} />
                                        <Route path="edit" element={<EditUndockingParas />} />
                                    </Route>
                                </Route>
                                <Route path="amrs">
                                    <Route index element={<AMRsPanel />} />
                                </Route>
                            </Route>
                            {/* AMR page is currently disabled, but leaving the source incase we decide to reable.
              //  <Route path='/amrs'>
              // <Route index element={<AMRPage />} />
              //  <Route path="details" element={<AMRDetailPage />} />
              //</Route>
              */}
                            <Route path='/mapping' >
                                <Route index element={<MappingPage />} />
                            </Route>
                            <Route path='/jobs'>
                                <Route index element={<LiveJobsPage />} />
                               <Route path='worker'>                                    
                                 <Route path=":taskID" element={<JobWorkerPage />} />  
                               </Route>                                                 
                            </Route>
                            <Route path='/notifications'>
                                <Route index element={<NotificationsPage />} />
                            </Route>
                            <Route path='/admin'>
                                <Route index element={<AdminPage />} />
                            </Route>
                        </Routes>
                    </Box>
                </Box>
            </BrowserRouter>
            <NotificationSnackbar />
            <ErrorSnackbar />
        </>
    )
}
