import {
    Box,
    Button,
    TextField,
    FormControl,
    FormHelperText,
    OutlinedInput,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { createEdges, getEdges, deleteEdges } from "api/edges"
import { updateNode, getNode, deleteNode } from "api/nodes"
import {
    setSelectedNode,
    clearSelectedNode,
    toggleNodeEditing,
    toggleAddingEdge,
    toggleIsRemovingEdge,
    updateSelectedNode,
    getSelectedNode,
    getNumOfNodeEdges,
    getEdgesToRemove,
    saveSelectedNode,
    getIsAddingEdge,
    clearNewEdges,
    clearEdgesToRemove,
    getNewEdges,
    getIsRemovingEdge,
    updateNode as updateNodeInSlice,
    addEdges,
    removeNode,
    removeEdges,
    getEdges as getEdgesInSlice,
} from "store/nodeSlice";

import { useMutation } from "react-query";
import { defaultNode, INode, renderedEdgeToIEdge } from "model/Nodes";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";

export const EditNode = () => {
    const [newNode, setNewNode] = useState<INode>(defaultNode);
    const [error] = useState(false);
    const isAddingEdge = useSelector(getIsAddingEdge)
    const isRemovingEdge = useSelector(getIsRemovingEdge)
    const edgesToRemove = useSelector(getEdgesToRemove)
    const newEdges = useSelector(getNewEdges)
    const edges = useSelector(getEdgesInSlice)
    const selectedNode = useSelector(getSelectedNode)
    const edgeCount = useSelector(getNumOfNodeEdges(selectedNode))
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { mutateAsync: asyncGetNode } = useMutation(getNode)
    const { mutateAsync: asyncUpdateNode } = useMutation(updateNode)
    const { mutateAsync: asyncCreateEdges } = useMutation(createEdges)
    const { mutateAsync: asyncGetEdges } = useMutation(getEdges)
    const { mutateAsync: asyncDeleteNode } = useMutation(deleteNode)
    const { mutateAsync: asyncDeleteEdges } = useMutation(deleteEdges)

    useEffect(() => {
        if (!selectedNode) {
            navigate('/fleet/nodes')
        }
    }, [selectedNode])


    const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNewNode({ ...newNode, name: event.target.value })
    };


    const handleChangeLocation = (type: ('x' | 'y' | 'rot')) => (event: any) => {
        const val = event.target.value;
        if (!!val && selectedNode) {
            dispatch(updateSelectedNode({ [type]: Math.round(+val * 1000) / 1000 }))
        }
    }

    const handleSaveNode = async () => {
        if (selectedNode) {

            const nodeId = await asyncUpdateNode(selectedNode)
            const fetchedNode = await asyncGetNode(nodeId)
            dispatch(updateNodeInSlice(fetchedNode))
            // dispatch(saveSelectedNode(selectedNode));
            dispatch(setSelectedNode(undefined))
            dispatch(toggleNodeEditing(false));
            navigate(-1)
        }

        if (newEdges) {
            const edgesWithId = await asyncCreateEdges(newEdges.map(renderedEdgeToIEdge))
            const fetchedEdges = await asyncGetEdges(edgesWithId)
            dispatch(addEdges(fetchedEdges))
        }

        if (edgesToRemove) {
            try {
                await asyncDeleteEdges(edgesToRemove)
                dispatch(removeEdges(edgesToRemove))
            } catch (e) {
                console.error('Deleting edges failed.', e)
            }
        }

        dispatch(clearEdgesToRemove())
        dispatch(clearNewEdges())
    }


    const handleRemoveEdge = () => {
        dispatch(toggleIsRemovingEdge(!isRemovingEdge))
    }

    // TODO repeted code, put this logic somewhere else pls.
    const handleDeleteNode = async (id: string) => {
        try {
            await asyncDeleteNode(id)
            dispatch(removeNode(id))
            const edge_ids = edges.filter(edge => edge.node_ids.includes(id))
                .map(e => e.id)
            // This really should be happening somewhere else.
            await asyncDeleteEdges(edge_ids)
            dispatch(clearSelectedNode())
            dispatch(clearNewEdges())
        } catch (e) {
            console.error('Deleting node failed', id, e)
        }
    }

    const handleAddEdge = () => {
        dispatch(toggleAddingEdge(true))
    }

    const onBackClickHandler = () => {
        dispatch(clearEdgesToRemove())
        dispatch(clearSelectedNode())
        dispatch(clearNewEdges())
    }

    return (
        <> {selectedNode && <>
            <ToolbarTitle title={"Edit node"} onClick={onBackClickHandler} back="nodes" />
            <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
                Location
            </Typography>
            <FormControl error={error}>
                <TextField type="Number" value={Math.round(selectedNode.location.x * 100) / 100} onChange={handleChangeLocation('x')} />
            </FormControl>
            <FormControl error={error}>
                <TextField type="Number" value={Math.round(selectedNode.location.y * 100) / 100} onChange={handleChangeLocation('y')} />
            </FormControl>
            <FormControl error={error}>
                <TextField type="Number" value={Math.round(selectedNode.rotation * 10000) / 10000} onChange={handleChangeLocation('rot')} />
            </FormControl>
            <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
                Edges
            </Typography>
            {edgeCount}
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end", gap: "10px" }}>
                {error && <FormHelperText sx={{ textAlign: "center", mb: 2 }}>Could not save POI</FormHelperText>}
                <Button
                    variant={isAddingEdge ? "contained" : "outlined"}
                    sx={{ p: 1.5 }}
                    onClick={handleAddEdge}
                >
                    {isAddingEdge ? "Click other node" : "Add Edge"}
                </Button>
                {error && <FormHelperText sx={{ textAlign: "center", mb: 2 }}>Could not save POI</FormHelperText>}
                <Button
                    variant={isRemovingEdge ? "contained" : "outlined"}
                    color="error"
                    sx={{ p: 1.5 }}
                    onClick={() => handleRemoveEdge()}
                >
                    {isRemovingEdge ? "Click other node" : "Remove Edge"}
                </Button>

                <Button
                    variant="outlined"
                    color="error"
                    sx={{ p: 1.5 }}
                    onClick={() => handleDeleteNode(selectedNode.id)}
                >
                    Delete Node
                </Button>
                {error && <FormHelperText sx={{ textAlign: "center", mb: 2 }}>Could not save POI</FormHelperText>}
                <Button
                    variant="contained"

                    sx={{ p: 1.5 }}
                    onClick={handleSaveNode}
                >
                    Save Changes
                </Button>
            </Box>
        </>}
        </>
    )
}
