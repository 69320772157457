import { getPrioFromString } from "components/pages/LiveJobsPage"
import { IOrder } from "./Orders"
import { WSLiveJob } from "./Websocket"

export const defaultJobWithOrder = {
    id: String(Math.random()),
    name: "new job",
    orders: []
}

// TODO: figure out if we need all these different types of orders in the FE
export interface IJob {
    id: string,
    name: string,
    orders: string[]
}

export interface IJobWithOrder {
    id: string,
    name: string,
    orders: IOrder[]
}

export interface IJobToAPI {
    name: string,
    status: string,
    orders: string[]
}

export interface IJobFromApi {
    _id: string,
    name: string,
    status: string,
    orders: string[]
}

export enum PRIORITY {
    HIGH = "HIGH",
    MEDIUM = "MEDIUM",
    LOW = "LOW"
}

export enum IJobStatus {
    WAITING = "WAITING",
    INITIALIZING = "INITIALIZING",
    CANCELLED = "CANCELLED",
    RUNNING = "RUNNING",
    PAUSED = "PAUSED",
    FINISHED = "FINISHED",
    FAILED = "FAILED"
}

export enum IOrderStatus {
    WAITING = "Waiting",
    RUNNING = "Running",
    CANCELLED = "Cancelled",
    REJECTED = "Rejected",
    FINISHED = "Finished",
    FAILED = "Failed"
}

export interface ILiveJob {
    jobId: string,
    liveJobId: string,
    jobStatus: IJobStatus,
    assignedAmr: string | null,
    repeatJob: boolean,
    isBlocking: boolean,
    priority: PRIORITY,
    activeOrderId: string | null,
    activeOrderStatus: IOrderStatus | null,
    activeOrderIndex: number,
}

export interface ILiveJobFull extends ILiveJob {
    name: string,
    orders: IOrder[],
}

const mapLiveJobFromWS = (job: WSLiveJob): ILiveJob => ({
    jobId: job.job_id,
    liveJobId: job.live_job_id,
    jobStatus: job.job_status,
    assignedAmr: job.assigned_amr || job.pre_assigned_amr,
    repeatJob: job.repeat_job,
    isBlocking: job.is_blocking,
    priority: getPrioFromString(job.job_priority),
    activeOrderId: job.active_order_id,
    activeOrderStatus: job.active_order_status,
    activeOrderIndex: job.active_order_index < 0 ? 0 : job.active_order_index,
})

export const mapLiveJobsFromWS = (jobs: WSLiveJob[]): ILiveJob[] => {
    return jobs.map(mapLiveJobFromWS)
}


export const mapJobWithOrderToApi = (job: IJobWithOrder): IJobToAPI =>
({
    name: job.name,
    orders: job.orders.map(order => order.id),
    status: "unassigned"
})

export const mapJobFromApi = (job: IJobFromApi): IJob =>
({
    id: job._id,
    name: job.name,
    // TODO: align with Jaco what this means and how we want to handle this.
    // THIS IS A BUG, the IJOB should also just be a string of orders. eep.
    orders: job.orders,
})
