import { axiosInstance } from "./axios";
import { ROUTES } from "./routes";
import { DataFormatter } from "../utils/DataFormatter";
import { useQuery } from "react-query";
import { NotificationConfig } from "../components/pages/NotificationsPage";

// TODO: add zod and schemas to all API message
// Make sure we always return proper TypeScript types
export interface SubscriberResponse {
  _id: string
  email: string
  enabled: boolean
  time_end: string
  time_start: string
  weekdays: string[]
}

const getSubscribers = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.subscriber.url}`)
  return data.map((subscriber: SubscriberResponse) => DataFormatter.parseSubscriber(subscriber));
};

export const getSubscriber = async (id: string) => {
  const { data } = await axiosInstance.get(`${ROUTES.subscriber.url}/${id}`)
  return DataFormatter.parseSubscriber(data)
};

const getSettings = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.settings.url}`)
  return data;
};

export function useSubscribers() {
  return useQuery(ROUTES.subscriber.key, getSubscribers);
}

export function useSettings() {
  return useQuery(ROUTES.settings.key, getSettings);
}

export function useSubscriber(id: string) {
  return useQuery([ROUTES.subscriber.key, { id }], () => getSubscriber(id), { retry: false });
}

export const createSubscriber = async (subscriber: NotificationConfig) => {
  return axiosInstance.post(`${ROUTES.subscriber.url}`, DataFormatter.formatSubscriber(subscriber))
};

export const patchSubscriber = async (subscriber: NotificationConfig) => {
  return axiosInstance.patch(`${ROUTES.subscriber.url}/${subscriber.id}`, DataFormatter.formatSubscriber(subscriber))
};

export const deleteSubscriber = async (subscriberID: string) => {
  return axiosInstance.delete(`${ROUTES.subscriber.url}/${subscriberID}`)
};

export const patchSettings = async (value: boolean) => {
  return axiosInstance.patch(`${ROUTES.settings.url}?enabled=${value}`)
};
