import {
  FormControl,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
  Select,
  Box,
  Button,
  MenuItem,
  Typography
} from "@mui/material";
import { useState, useCallback } from "react";
import { ManualControl } from "components/manualControl/ManualControl";
import { AMRsIcon } from "assets/icons/SvgIcons";
import { useWebSocket } from "components/hooks/websocket";
import { SoftShadowCard } from "components/styled/SoftShadowCard";
import { useSelector } from "react-redux";
import { getAmrNames } from "store/amrSlice";
import { getMappingAmr, setIsMapping, setMappingAmrId, getIsMapping } from "store/mapSlice";
import { useAppDispatch } from "store/store";

export const MappingToolbar = () => {
  const dispatch = useAppDispatch()
  const { startMapping, stopMapping } = useWebSocket()
  const [newMapName, setMapName] = useState("")

  const availableAmrNames = useSelector(getAmrNames)
  const mappingAmr = useSelector(getMappingAmr)
  const isMapping = useSelector(getIsMapping)
  const handleChangeMapName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMapName(event.target.value)
  }

  const handleStartMapping = () => {
    if (mappingAmr) {
      startMapping(mappingAmr.id, newMapName)
      dispatch(setIsMapping(true))
    }
  }

  const handleEndMapping = () => {
    if (mappingAmr) {
      stopMapping(mappingAmr.id)
      dispatch(setIsMapping(false))
      //setOngoingMapping(false)
    }
  }

  const handleSelectAmr = useCallback((event: SelectChangeEvent) => {
    dispatch(setMappingAmrId(event.target.value))
  }, [availableAmrNames])


  return (
    <Box sx={{
      display: "flex",
      height: "100%",
      width: {
        xs: "90%",
        sm: "auto",
      },
      marginBottom: "10px",
      position: "fixed",
      right: 10,
      alignItems: "flex-start",
      zIndex: 10,
    }}>
      <SoftShadowCard sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: {
          xs: "85%",
          sm: "100%",
        },
        p: 2,
        pb: 5,
        justifyContent: "space-between",
        alignItems: "center",
        width: {
          xs: "100%",
          sm: 340,
        },
        overflow: "auto"
      }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}>
          <Typography sx={{ mb: 2 }}>
            Create a new map
          </Typography>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            ml: 1
          }}>
            <AMRsIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2" sx={{ mr: 1 }}>
              {mappingAmr && mappingAmr.name}
            </Typography>
          </Box>
          <Box
            sx={{ display: "block" }}>
            <FormControl fullWidth sx={{ paddingBottom: "10px" }}>
              <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
                Map Name
              </Typography>
              <OutlinedInput placeholder="Map Name" value={newMapName} onChange={handleChangeMapName} />
            </FormControl>
            <FormControl fullWidth sx={{ paddingBottom: "10px" }} >
              <InputLabel id="demo-simple-select-helper-label2">Amr Name</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper3"
                value={mappingAmr && mappingAmr.name || ""}
                label="AMR"
                onChange={handleSelectAmr}
                sx={{ fontSize: 14 }}
              >
                {availableAmrNames.map((amr, index) => <MenuItem key={amr} value={amr}>{amr}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>

          <Button sx={{ p: 1.5, mb: 1 }} variant="outlined" disabled={isMapping || !mappingAmr || !newMapName } disableElevation onClick={handleStartMapping}>
            Start Mapping
          </Button>
          <Button sx={{ p: 1.5 }} variant="contained" disabled={!isMapping} disableElevation onClick={handleEndMapping}>
            Stop Mapping
          </Button>
        </Box>

        {mappingAmr && <ManualControl robotId={mappingAmr ? mappingAmr.id : ""} />}
      </SoftShadowCard>
    </Box>

  )
}
