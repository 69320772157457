// @ts-ignore
export const WS_URL = window._env.REACT_APP_WS_URL ?? `wss://overmind.apps.motius.ci`

export class WSTopic {
  TOPIC_EXECUTE_JOB: string
  TOPIC_CANCEL_JOB: string
  TOPIC_MANUAL_DRIVE: string
  TOPIC_OPERATE_LIFT: string
  TOPIC_OPERATE_SAFETY: string
  TOPIC_START_MAPPING: string
  TOPIC_STOP_MAPPING: string
  TOPIC_SET_MAP: string
  TOPIC_INIT_POSITION: string
  TOPIC_GO_CHARGE: string
  TOPIC_HOME_LIFT: string
  TOPIC_FLEET_STATE: string
  TOPIC_ROBOT_STATE: string
  TOPIC_LIVE_JOBS: string
  TOPIC_POSITION: string
  TOPIC_FIRE_ALARM: string
  REFRESH_FROM_DB: string

  constructor() {
    // From FE to Overmind
    this.TOPIC_EXECUTE_JOB = `EXECUTE_JOB`
    this.TOPIC_CANCEL_JOB = `CANCEL_JOB`
    this.TOPIC_MANUAL_DRIVE = `MANUAL_DRIVE`
    this.TOPIC_OPERATE_LIFT = `OPERATE_LIFT`
    this.TOPIC_OPERATE_SAFETY = `OPERATE_SAFETY`
    this.TOPIC_START_MAPPING = `START_MAPPING`
    this.TOPIC_STOP_MAPPING = `STOP_MAPPING`
    this.TOPIC_SET_MAP = `SET_MAP`
    this.TOPIC_INIT_POSITION = `INIT_POSITION`
    this.TOPIC_GO_CHARGE = `GO_TO_CHARGE`
    this.TOPIC_HOME_LIFT = `HOME_LIFT`


    // From Overmind to FE
    this.TOPIC_LIVE_JOBS = `LIVE_JOBS`
    this.TOPIC_FLEET_STATE = `FLEET_STATE`
    this.TOPIC_ROBOT_STATE = `ROBOT_STATE`
    this.TOPIC_POSITION = `POSITION`
    this.TOPIC_FIRE_ALARM = `FIRE_ALARM`
    this.REFRESH_FROM_DB = "REFRESH_FROM_DB"
  }
}
