import {SvgIcon, SvgIconProps} from "@mui/material";
import {SmallSvgIcon} from "../../components/styled/SmallSvgIcon";


export function MaintenanceIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 10 10">
      <path
        d="M8.99951 3.34L9.65451 3.555C9.85816 3.62378 9.99506 3.81504 9.99451 4.03V5.945C10.0055 6.17043 9.86418 6.3753 9.64951 6.445L8.99951 6.66L9.36451 7.265C9.46308 7.45745 9.42677 7.69146 9.27451 7.845L7.89951 9.22C7.74598 9.37225 7.51197 9.40857 7.31951 9.31L6.70951 9L6.49451 9.65C6.42481 9.86467 6.21994 10.006 5.99451 9.995H4.05451C3.82908 10.006 3.62421 9.86467 3.55451 9.65L3.33951 9L2.72951 9.315C2.53705 9.41357 2.30304 9.37725 2.14951 9.225L0.779509 7.9C0.627252 7.74646 0.59094 7.51245 0.689509 7.32L0.999509 6.71L0.349509 6.495C0.13484 6.4253 -0.00652209 6.22043 0.00450892 5.995V4.055C-0.00652209 3.82957 0.13484 3.6247 0.349509 3.555L0.999509 3.34L0.684509 2.73C0.58594 2.53754 0.622252 2.30353 0.774509 2.15L2.14951 0.774997C2.30304 0.62274 2.53705 0.586429 2.72951 0.684997L3.33951 0.999997L3.52951 0.349997C3.59921 0.135328 3.80408 -0.00603381 4.02951 0.0049972H5.94451C6.16994 -0.00603381 6.37481 0.135328 6.44451 0.349997L6.65951 0.999997L7.26951 0.684997C7.46197 0.586429 7.69598 0.62274 7.84951 0.774997L9.21951 2.15C9.37177 2.30353 9.40808 2.53754 9.30951 2.73L8.99951 3.34ZM2.49951 5C2.49951 6.38071 3.6188 7.5 4.99951 7.5C6.38022 7.5 7.49951 6.38071 7.49951 5C7.49951 3.61928 6.38022 2.5 4.99951 2.5C3.6188 2.5 2.49951 3.61928 2.49951 5Z"
        fill="#4C4C4C"/>
      <circle cx="5" cy="5" r="1.5" fill="#4C4C4C"/>
    </SvgIcon>
  );
}

export function WifiIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <path
        d="M14.6798 6.24286C10.9323 2.58504 5.10373 2.58638 1.35765 6.24286C1.21677 6.38036 1.21402 6.61317 1.35024 6.7558L2.0745 7.51406C2.20438 7.65022 2.41336 7.65313 2.54831 7.52254C5.63487 4.53862 10.4022 4.53795 13.4894 7.52254C13.6243 7.65313 13.8333 7.65 13.9632 7.51406L14.6874 6.7558C14.8234 6.61317 14.8207 6.38036 14.6798 6.24286ZM8.01874 10.6429C7.271 10.6429 6.66499 11.2824 6.66499 12.0714C6.66499 12.8605 7.271 13.5 8.01874 13.5C8.76647 13.5 9.37249 12.8605 9.37249 12.0714C9.37249 11.2824 8.76647 10.6429 8.01874 10.6429ZM12.3057 8.77701C9.86766 6.50179 6.16706 6.50424 3.73179 8.77701C3.58584 8.91317 3.58118 9.14955 3.71973 9.29375L4.44822 10.0525C4.57513 10.1846 4.77946 10.1935 4.91463 10.0703C6.69037 8.45045 9.35112 8.45402 11.1226 10.0703C11.2578 10.1935 11.4621 10.1848 11.589 10.0525L12.3175 9.29375C12.4563 9.14955 12.4514 8.91295 12.3057 8.77701Z"/>
    </SvgIcon>
  )
}

export function ParkingIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 17">
      <path
        d="M14.4403 6.66664L13.3337 5.65997L12.7603 5.16664L8.44032 1.3333C8.18865 1.11221 7.81199 1.11221 7.56032 1.3333L3.24032 5.16664L2.66699 5.65997L1.56032 6.66664C1.30293 6.91386 1.28589 7.31994 1.52166 7.58786C1.75743 7.85578 2.16238 7.89051 2.44032 7.66664L2.66699 7.46664V13.1666C2.66699 14.2712 3.56242 15.1666 4.66699 15.1666H6.00032V11.1666C6.00032 10.0621 6.89575 9.16664 8.00032 9.16664C9.10489 9.16664 10.0003 10.0621 10.0003 11.1666V15.1666H11.3337C12.4382 15.1666 13.3337 14.2712 13.3337 13.1666V7.46664L13.5603 7.66664C13.8366 7.90929 14.2572 7.88244 14.5003 7.60664C14.743 7.33037 14.7161 6.90981 14.4403 6.66664Z"/>
    </SvgIcon>
  )
}

export function LocationIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M8.00014 1.28577C11.0773 1.28577 13.5716 3.78005 13.5716 6.85719C13.5716 9.21148 11.9173 11.7772 8.65157 14.5818C8.46998 14.7378 8.23846 14.8234 7.99907 14.8232C7.75968 14.823 7.52831 14.7369 7.347 14.5806L7.131 14.3932C4.00985 11.6618 2.42871 9.15891 2.42871 6.85719C2.42871 3.78005 4.923 1.28577 8.00014 1.28577ZM8.00014 4.71434C7.43182 4.71434 6.88678 4.9401 6.48491 5.34197C6.08305 5.74383 5.85728 6.28887 5.85728 6.85719C5.85728 7.42552 6.08305 7.97056 6.48491 8.37242C6.88678 8.77429 7.43182 9.00005 8.00014 9.00005C8.56846 9.00005 9.11351 8.77429 9.51537 8.37242C9.91723 7.97056 10.143 7.42552 10.143 6.85719C10.143 6.28887 9.91723 5.74383 9.51537 5.34197C9.11351 4.9401 8.56846 4.71434 8.00014 4.71434Z"/>
    </SvgIcon>
  )
}

export function RotationIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M14.5 7.875C14.5 8.77214 14.3246 9.62901 13.9738 10.4456C13.623 11.2623 13.1514 11.9667 12.5591 12.5591C11.9667 13.1514 11.2623 13.623 10.4456 13.9738C9.62901 14.3246 8.77214 14.5 7.875 14.5C6.88585 14.5 5.94558 14.2915 5.0542 13.8746C4.16281 13.4577 3.4037 12.8696 2.77686 12.1105C2.7366 12.053 2.71791 11.9883 2.72078 11.9164C2.72366 11.8445 2.7481 11.7856 2.79411 11.7396L3.97591 10.5492C4.03342 10.4974 4.10531 10.4715 4.19157 10.4715C4.28358 10.483 4.34972 10.5175 4.38997 10.575C4.80979 11.1214 5.32449 11.5441 5.93408 11.8431C6.54367 12.1421 7.19065 12.2917 7.875 12.2917C8.47309 12.2917 9.04386 12.1752 9.58732 11.9423C10.1308 11.7094 10.6009 11.3945 10.9977 10.9977C11.3945 10.6009 11.7094 10.1308 11.9423 9.58732C12.1752 9.04386 12.2917 8.47309 12.2917 7.875C12.2917 7.27691 12.1752 6.70614 11.9423 6.16268C11.7094 5.61922 11.3945 5.14909 10.9977 4.75228C10.6009 4.35547 10.1308 4.04061 9.58732 3.8077C9.04386 3.57479 8.47309 3.45833 7.875 3.45833C7.31141 3.45833 6.77083 3.56041 6.25326 3.76457C5.73568 3.96872 5.27561 4.26058 4.87305 4.64014L6.05485 5.83057C6.23313 6.00309 6.27338 6.2015 6.17562 6.42578C6.07785 6.65582 5.9082 6.77083 5.66667 6.77083H1.80208C1.65256 6.77083 1.52317 6.7162 1.4139 6.60693C1.30463 6.49767 1.25 6.36827 1.25 6.21875V2.35417C1.25 2.11263 1.36502 1.94298 1.59505 1.84521C1.81934 1.74745 2.01774 1.78771 2.19027 1.96598L3.31169 3.07878C3.92703 2.49794 4.63007 2.04793 5.42082 1.72876C6.21156 1.40959 7.02962 1.25 7.875 1.25C8.77214 1.25 9.62901 1.4254 10.4456 1.7762C11.2623 2.12701 11.9667 2.59858 12.5591 3.19092C13.1514 3.78326 13.623 4.48774 13.9738 5.30436C14.3246 6.12099 14.5 6.97786 14.5 7.875Z"/>
    </SvgIcon>
  )
}

export function MapMatchIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M1.99967 6.66671C2.36786 6.66671 2.66634 6.36823 2.66634 6.00004V3.33337C2.66634 2.96518 2.96482 2.66671 3.33301 2.66671H5.99967C6.36786 2.66671 6.66634 2.36823 6.66634 2.00004C6.66634 1.63185 6.36786 1.33337 5.99967 1.33337H3.33301C2.22844 1.33337 1.33301 2.2288 1.33301 3.33337V6.00004C1.33301 6.36823 1.63148 6.66671 1.99967 6.66671Z"/>
      <path
        d="M12.6663 1.33337H9.99967C9.63148 1.33337 9.33301 1.63185 9.33301 2.00004C9.33301 2.36823 9.63148 2.66671 9.99967 2.66671H12.6663C13.0345 2.66671 13.333 2.96518 13.333 3.33337V6.00004C13.333 6.36823 13.6315 6.66671 13.9997 6.66671C14.3679 6.66671 14.6663 6.36823 14.6663 6.00004V3.33337C14.6663 2.2288 13.7709 1.33337 12.6663 1.33337Z"/>
      <path
        d="M13.9997 9.33337C13.6315 9.33337 13.333 9.63185 13.333 10V12.6667C13.333 13.0349 13.0345 13.3334 12.6663 13.3334H9.99967C9.63148 13.3334 9.33301 13.6318 9.33301 14C9.33301 14.3682 9.63148 14.6667 9.99967 14.6667H12.6663C13.7709 14.6667 14.6663 13.7713 14.6663 12.6667V10C14.6663 9.63185 14.3679 9.33337 13.9997 9.33337Z"/>
      <path
        d="M5.99967 13.3334H3.33301C2.96482 13.3334 2.66634 13.0349 2.66634 12.6667V10C2.66634 9.63185 2.36786 9.33337 1.99967 9.33337C1.63148 9.33337 1.33301 9.63185 1.33301 10V12.6667C1.33301 13.7713 2.22844 14.6667 3.33301 14.6667H5.99967C6.36786 14.6667 6.66634 14.3682 6.66634 14C6.66634 13.6318 6.36786 13.3334 5.99967 13.3334Z"/>
      <rect x="4" y="4" width="8" height="8" rx="2"/>
    </SvgIcon>
  )
}

export function CalendarIconSmall(props: SvgIconProps) {
  return (
    <SmallSvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M12.6667 3.33331H12V1.99998C12 1.63179 11.7016 1.33331 11.3334 1.33331C10.9652 1.33331 10.6667 1.63179 10.6667 1.99998V3.33331H5.33337V1.99998C5.33337 1.63179 5.0349 1.33331 4.66671 1.33331C4.29852 1.33331 4.00004 1.63179 4.00004 1.99998V3.33331H3.33337C2.2288 3.33331 1.33337 4.22874 1.33337 5.33331V5.99998H14.6667V5.33331C14.6667 4.22874 13.7713 3.33331 12.6667 3.33331Z"/>
      <path
        d="M1.33337 12.6666C1.33337 13.7712 2.2288 14.6666 3.33337 14.6666H12.6667C13.7713 14.6666 14.6667 13.7712 14.6667 12.6666V7.33331H1.33337V12.6666Z"/>
    </SmallSvgIcon>
  )
}

export function ClockIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M5.33337 12C5.33337 8.31808 8.31814 5.33331 12 5.33331C13.7682 5.33331 15.4638 6.03569 16.7141 7.28593C17.9643 8.53618 18.6667 10.2319 18.6667 12C18.6667 15.6819 15.6819 18.6666 12 18.6666C8.31814 18.6666 5.33337 15.6819 5.33337 12ZM14 13.3333C14.2988 13.3489 14.5714 13.1636 14.6667 12.88C14.7224 12.7076 14.7056 12.52 14.6201 12.3602C14.5346 12.2005 14.3877 12.0825 14.2134 12.0333L12.6667 11.52V8.66665C12.6667 8.29846 12.3682 7.99998 12 7.99998C11.6319 7.99998 11.3334 8.29846 11.3334 8.66665V12C11.3326 12.2866 11.5152 12.5416 11.7867 12.6333L13.7867 13.3C13.8561 13.3203 13.9278 13.3315 14 13.3333Z"/>
    </SvgIcon>
  )
}

export function PathIconSmall(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M7.25004 10.0533C7.12845 10.1912 6.91817 10.2046 6.78004 10.0833L4.00004 7.61332L1.22004 10.0833C1.08107 10.1953 0.878595 10.1779 0.760709 10.0439C0.642824 9.90997 0.651345 9.70693 0.78004 9.58332L3.78004 6.91665C3.90587 6.8061 4.0942 6.8061 4.22004 6.91665L7.22004 9.58332C7.35794 9.70491 7.37136 9.91519 7.25004 10.0533Z"
        stroke="#BABABA"/>
      <path
        d="M7.22004 15.4167L4.22004 18.0833C4.0942 18.1939 3.90587 18.1939 3.78004 18.0833L0.78004 15.4167C0.651345 15.2931 0.642824 15.09 0.760709 14.9561C0.878595 14.8221 1.08107 14.8047 1.22004 14.9167L4.00004 17.3867L6.78004 14.9167C6.86843 14.8318 6.99628 14.8022 7.11295 14.8397C7.22963 14.8772 7.31632 14.9757 7.33868 15.0962C7.36105 15.2167 7.31549 15.3398 7.22004 15.4167Z"
        stroke="#BABABA"/>
    </SvgIcon>
  )
}

