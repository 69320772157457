import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IZone, IZoneBrush, defaultZoneBrush } from "model/Zones";
import { RootState } from "store/store"


export interface ZonesState {
  zones: IZone[],
  zoneBrush: IZoneBrush,
  isAddingZone: boolean
}

const initialState: ZonesState = {
  zones: [],
  zoneBrush: defaultZoneBrush,
  isAddingZone: false
}

const withPayload = <T,>(func: (state: ZonesState, payload: T) => void) => (state: ZonesState, action: PayloadAction<T>) => {
  func(state, action.payload)
}


const zoneFunctions = {
  setZones: withPayload<IZone[]>((s, p) => {
      s.zones = p
  }),
  setZoneBrush: withPayload<IZoneBrush>((s,p) => {
    s.zoneBrush = p
  }),
  setIsAddingZone: withPayload<boolean>((s,p) => {
    s.isAddingZone = p
  }),
  saveNewZone: withPayload<IZone>((s,p) => {
    s.zones.push(p)
  }),
  removeZone: withPayload<string>((s,p) => {
    s.zones = s.zones.filter(zone => zone.id !== p)
  })
}

export const zoneSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    ...zoneFunctions,
  }
})


export const {
  setZones,
  saveNewZone,
  setZoneBrush,
  setIsAddingZone,
  removeZone,
} = zoneSlice.actions

export const getZones = (state: RootState) => state.zones.zones
export const getZonesByMap = (state: RootState) => {
  return state.map.maps.reduce((acc, red) => {
    const zones = state.zones.zones.filter(zone => zone.correspondingMapName === red.mapName)
    if (zones) {
      acc[red.id] = zones
    } else {
      acc[red.id] = []
    }
    return acc
  }, {} as any)
}

export const getZonesOfSelectedMap = (state: RootState) => {
  if (!state.map.selectedMapId) {
    return []
  }
  const activeMap = state.map.maps.find(map => map.id === state.map.selectedMapId)
  if (!activeMap) {
    return []
  }
  return state.zones.zones.filter(zone => zone.correspondingMapName === activeMap.mapName)
}
export const getActiveZone = (state: RootState) => {
  if (!state.map.selectedMapId) {
    return
  }
  const activeMap = state.map.maps.find(map => map.id === state.map.selectedMapId)
  if (!activeMap) {
    return
  }
  return state.zones.zones.find(zone => zone.id === activeMap.activeZoneId)
}

export const getZoneBrush = (state: RootState) => state.zones.zoneBrush
export const getIsAddingZone = (state: RootState) => state.zones.isAddingZone
export default zoneSlice.reducer
