// @ts-ignore
export const BASE_URL = process.env.NODE_ENV !== 'test' ? localStorage.getItem('fe-api-url') ?? window._env?.REACT_APP_API_URL : "https://fleet-api.apps.moti.us";

interface Dictionary {
  [key: string]: Route
}

export interface Route {
  key: string,
  url: string
}

export const ROUTES: Dictionary = {
  edges: {
    key: "edges",
    url: "/edges"
  },
  nodes: {
    key: "nodes",
    url: "/nodes"
  },
  orders: {
    key: "orders",
    url: "/orders"
  },
  jobs: {
    key: "jobs",
    url: "/jobs"
  },
  robot: {
    key: "robots",
    url: "/robot/"
  },
  poi: {
    key: "pois",
    url: "/poi"
  },
  map: {
    key: "map",
    url: "/map"
  },
  trajectory: {
    key: "trajectory",
    url: "/trajectory"
  },
  subscriber: {
    key: "subscriber",
    url: "/email_subscribers"
  },
  settings: {
    key: "settings",
    url: "/settings"
  },
  actionType: {
    key: "actionType",
    url: "/action_type/"
  },
  docking: {
    key: "docking",
    url: "docking_params"
  },
  undocking: {
    key: "undocking",
    url: "undocking_params"
  },
  zones: {
    key: "zones",
    url:"zones"
  },
  robotConfigs: {
    key: "robotConfigs",
    url: "robot_configs"
  }
}
