import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionType } from 'model/Actions';
import { PointOfInterest, ProgressStatus, Task, TaskInProgress, Trajectory } from "../model/Order";

export interface TaskState {
  tasks: Task[],
  trajectories: Trajectory[]
  currentTask?: Task
  currentTrajectory?: Trajectory
  tasksInProgress: TaskInProgress[]
}

// TODO: figure out how to handle this
interface WSCurrentTask{}

interface WSExecuteTask{}


const initialState: TaskState = {
  tasks: [],
  trajectories: [],
  tasksInProgress: [],
}

const defaultTask: Task = {
  id: "",
  name: "",
  steps: [{ poi: undefined, actionType: undefined }],
  trajectories: [],
  createdAt: new Date(),
  duration: 0,
  repeat: 0,
  hours: 0,
}

export const defaultTrajectory: Trajectory = {
  id: "",
  name: "",
  poses: [],
  mapID: ""
}

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTask: (state) => {
      // only use the default task if the current task is undefined 
      // if thats not the case it means we started editing a task before
      if (state.currentTask === undefined) state.currentTask = defaultTask
    },
    updateCurrentTask: (state, action: PayloadAction<{ task?: Task, name?: string, description?: string }>) => {
      if (action.payload.task) state.currentTask = action.payload.task
      if (action.payload.name !== undefined && state.currentTask) state.currentTask.name = action.payload.name
      if (action.payload.description !== undefined && state.currentTask) state.currentTask.description = action.payload.description
    },
    clearCurrentTask: (state) => {
      state.currentTask = undefined
    },
    updateCurrentTaskStep: (state, action: PayloadAction<{ step: number, poi?: PointOfInterest, actionType?: ActionType }>) => {
      if (state.currentTask && state.currentTask.steps.length > action.payload.step) {
        if (action.payload.poi) {
          state.currentTask.steps[action.payload.step].poi = action.payload.poi
        }
        if (action.payload.actionType) {
          state.currentTask.steps[action.payload.step].actionType = action.payload.actionType
        }
      }
    },
    updateCurrentTaskTrajectory: (state, action: PayloadAction<{ step: number, trajectory?: string }>) => {
      if (state.currentTask && state.currentTask.steps.length > action.payload.step) {
        state.currentTask.trajectories[action.payload.step] = action.payload.trajectory
      }
    },
    setCurrentTrajectory: (state, action: PayloadAction<{ trajectory?: Trajectory }>) => {
      state.currentTrajectory = action.payload.trajectory
    },
    resetActionType: (state, action: PayloadAction<number>) => {
      if (state.currentTask && state.currentTask.steps.length > action.payload) {
        state.currentTask.steps[action.payload].actionType = undefined
      }
    },
    addStep: (state) => {
      if (state.currentTask) state.currentTask.steps = [...state.currentTask.steps, {
        poi: undefined,
        actionType: undefined
      }]
    },
    deleteStep: (state, action: PayloadAction<number>) => {
      state.currentTask?.trajectories.forEach((entry, index) => {
        if (state.currentTask && index >= action.payload && index - 1 < state.currentTask?.trajectories.length) {
          state.currentTask.trajectories[index] = state.currentTask?.trajectories[index + 1]
        }
      })
      if (state.currentTask) {
        state.currentTask.trajectories.pop()
        state.currentTask.steps.splice(action.payload, 1)
      }
    },
    addTaskInProgressByID: (state, action: PayloadAction<{ taskID: string, amrID?: string }>) => {
      state.tasksInProgress = [...state.tasksInProgress, {
        orderID: state.tasksInProgress.length.toString(),
        taskID: action.payload.taskID,
        status: ProgressStatus.REQUESTED,
        currentStep: -1,
        completedSteps: -1,
        feedback: "",
        description: "",
        amrID: action.payload.amrID ?? "",
        requestedAt: new Date()
      }]
    },
    updateTaskInProgress: (state, action: PayloadAction<{ update: WSCurrentTask, amrRef: string }>) => {
      // TODO: implement this function
      // if (state.tasksInProgress.filter((entry) => entry.taskID === action.payload.update.task_id).length === 0) {
      //   addTaskInProgressByID({ taskID: action.payload.update.task_id, amrID: action.payload.amrRef })
      // }
      // state.tasksInProgress.forEach((entry, index) => {
      //   if (entry.taskID === action.payload.update.task_id) {
      //     state.tasksInProgress[index].feedback = action.payload.update.feedback
      //     state.tasksInProgress[index].description = action.payload.update.description
      //     state.tasksInProgress[index].amrID = action.payload.amrRef
      //     if (action.payload.update.feedback.toLowerCase() !== "success") {
      //       state.tasksInProgress[index].status = ProgressStatus.FAILED
      //     } else {
      //       state.tasksInProgress[index].status = ProgressStatus.RUNNING
      //       state.tasksInProgress[index].completedSteps = action.payload.update.step_index
      //     }
      //   }
      // })
    },
    updateExecuteTask: (state, action: PayloadAction<{ update: WSExecuteTask, amrRef: string }>) => {
      // TODO: implement this function
      // if (state.tasksInProgress.filter((entry) => entry.taskID === action.payload.update.task_id).length === 0) {
      //   addTaskInProgressByID({ taskID: action.payload.update.task_id, })
      // }
      // state.tasksInProgress.forEach((entry, index) => {
      //   if (entry.taskID === action.payload.update.task_id) {
      //     state.tasksInProgress[index].currentStep = action.payload.update.step_index
      //     state.tasksInProgress[index].currentAction = action.payload.update.action_type
      //     state.tasksInProgress[index].poses = action.payload.update.poses
      //     state.tasksInProgress[index].amrID = action.payload.amrRef
      //   }
      // })
    },
    updateTaskInProgressStatus: (state, action: PayloadAction<{ taskID: string, status: ProgressStatus }>) => {
      state.tasksInProgress.forEach((entry, index) => {
        if (entry.taskID === action.payload.taskID) {
          state.tasksInProgress[index].status = action.payload.status
        }
      })
    },
    cancelTaskByOrderID: (state, action: PayloadAction<string>) => {
      const filter = state.tasksInProgress.filter((entry) => entry.orderID !== action.payload)
      state.tasksInProgress = [...filter]
    },
    clearTasksInProgress: (state) => {
      state.tasksInProgress = []
    }
  },
})

export const {
  addTask,
  updateCurrentTask,
  updateCurrentTaskStep,
  updateCurrentTaskTrajectory,
  clearCurrentTask,
  addStep,
  deleteStep,
  resetActionType,
  setCurrentTrajectory,
  addTaskInProgressByID,
  updateTaskInProgress,
  updateExecuteTask,
  updateTaskInProgressStatus,
  clearTasksInProgress,
  cancelTaskByOrderID
} = taskSlice.actions

export default taskSlice.reducer
