import { configureStore } from '@reduxjs/toolkit'
import mapReducer from './mapSlice'
import taskReducer from './taskSlice'
import notificationReducer from './notificationSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import configReducer from "./configSlice";
import amrReducer from "./amrSlice";
import nodeReducer from "./nodeSlice";
import orderReducer from "./orderSlice";
import jobReducer from "./jobSlice";
import dockparamsReducer from "./dockparamsSlice";
import undockparamsReducer from "./undockparamsSlice";
import zoneReducer from "./zoneSlice";
import errorsReducer from "./errorstateSlice"
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore, } from 'redux-persist'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, configReducer)


export const store = configureStore({
  reducer: {
    config: persistedReducer,
    map: mapReducer,
    tasks: taskReducer,
    notifications: notificationReducer,
    errorStates: errorsReducer,
    amrs: amrReducer,
    nodes: nodeReducer,
    orders: orderReducer,
    jobs: jobReducer,
    dps: dockparamsReducer,
    ups: undockparamsReducer,
    zones: zoneReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
