import { Location } from "./Order"
import { v4 as uuidv4 } from 'uuid';
// TODO: move this to a createDefaultNode function and handle it with proper default values
export const defaultNode: INode = {
  id: '1',
  name: "Node Name",
  location: { x: 100, y: 100 },
  rotation: 0,
  mapId: "defaultMapId"
}


export interface INode {
  id: string,
  name?: string,
  rotation: number,
  location: Location,
  mapId: string
}

export interface INodeUpdate {
  name?: string,
  x?: number,
  y?: number
}

export interface IFrontierNode {
  node_id: string
  previous_node?: string
  previous_edge?: string
}

export interface IEdge {
  id: string,
  node_ids: string[],
}

export interface IRenderedEdge {
  id: string,
  nodes: INode[]
}

export interface IEdgeItemProps {
  elementId: string
  edge: IEdge
}

export interface IEdgeApi {
  _id: string
  node_ids: string[]
  agv_type: string
}

export interface INodeApi {
  _id: string
  map_id: string
  x: number
  y: number
  rotation: number
}

export const createNewNode = (currentMapId?: string, x?: number, y?: number, rotation?: number): INode => {
  return {
    id: uuidv4(),
    mapId: currentMapId || "",
    location: {
      x: x || 0,
      y: y || 0
    },
    rotation: rotation || 0
  }
}

export const renderedEdgeToIEdge = (edge: IRenderedEdge): IEdge =>
({
  id: edge.id,
  node_ids: edge.nodes.map(node => node.id)
})

export const mapEdgeToApi = (edge: IEdge): IEdgeApi =>
({
  _id: "temp id",
  node_ids: edge.node_ids,
  agv_type: "ALL"
})

export const mapEdgeFromApi = (edge: IEdgeApi): IEdge =>
({
  id: edge._id,
  node_ids: edge.node_ids
})

export const mapNodeToApi = (node: INode): INodeApi =>
({
  _id: node.id,
  x: node.location.x,
  y: node.location.y,
  rotation: node.rotation,
  map_id: node.mapId

})

export const mapNodeFromApi = (node: INodeApi): INode =>
({
  id: node._id, // TODO: handle this properly
  location: { x: Math.round(node.x * 100) / 100, y: Math.round(node.y * 100) / 100 },
  rotation: node.rotation,
  mapId: node.map_id
})

export interface INodeItemProps {
  elementId: string
  defaultX: number
  defaultY: number
  rotation: number
  width: number
  height: number
  id?: string
  node: INode
}
