import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import * as d3 from "d3";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { getMapInfo, getMapName, getSelectedMapId } from "store/mapSlice";
import { Coordinates } from "model/Map";
import { useWebSocket } from "components/hooks/websocket";
import { ErrorLevel, IRobotState, nullAmr } from "model/Amr";
import { ROBOT_SVG } from "constants/robots";
import { mapOrientationToRobot, mapMLocationToPixel, mapPixelLocationToM } from "model/Map";
import {
  setIsPlacingAmr,
  setIsAmrPlaced,
  setPlacingAmrId,
  getAmrs,
  getIsPlacingAmr,
  getIsAmrPlaced,
  getPlacingAmrId
} from "store/amrSlice";

const getAngleRadians = (p1: Coordinates, p2: Coordinates) => {
  return Math.atan2(p2.y - p1.y, p2.x - p1.x);
}

const getAngleDegrees = (p1: Coordinates, p2: Coordinates) => {
  return getAngleRadians(p1, p2) * 180 / Math.PI
}

export const AMRLayer = () => {
  const amrs = useSelector(getAmrs)
  const theme = useTheme()
  const mapInfo = useSelector(getMapInfo)
  const selectedMapId = useSelector(getSelectedMapId)
  const mapName = useSelector(getMapName(selectedMapId ? selectedMapId : ""))
  const dispatch = useAppDispatch()
  const { initPosition } = useWebSocket()
  const [placingAmr, setPlacingAmr] = useState(nullAmr)
  const [dragStartLocation, setDragStartLocation] = useState({x: 0, y: 0})
  const isPlacingAmr = useSelector(getIsPlacingAmr)
  const isAmrPlaced = useSelector(getIsAmrPlaced)
  const placingAmrId = useSelector(getPlacingAmrId)

  const scale = useMemo(() => {
    return ((1 / ((mapInfo && mapInfo.resolution) ?? 1)) * 1 / 1000)
  }, [mapInfo])

  const translateRobot = (amr: any) => {
    const location = mapMLocationToPixel(mapInfo, amr.status.location)
    const rotation = amr.status.rotation
    return `translate(${location.x},${location.y})  rotate(${rotation}) scale(${scale})`
  }

  const getRobotColor = (amr: IRobotState) => {
    if (amr.robotColor) {
      return amr.robotColor
    }
    if (amr.status.errors) {
      if (amr.status.errors.length === 0)
        return theme.palette.primary.main
      else {
        let errorLevel = amr.status.errors.find(error => error.error_level === ErrorLevel.FATAL)
        if (errorLevel) return 'red'
        else return 'yellow'
      }
    }
    else
      return theme.palette.primary.main
  }
  useEffect(() => {
    d3.select("#amr-layer")
      .selectAll('path')
      .data(amrs.filter(amr => {
        return amr.status.localizationScore == 1
      }))
      .join(enter => enter.append('path')
        .style("stroke-width", 20)
        .style("stroke", 'black')
        .attr("fill", getRobotColor)
        .attr("d", ROBOT_SVG)
        .attr('transform', translateRobot),
        update => update.transition().duration(1)
          .attr('transform', translateRobot)
          .attr("fill", getRobotColor),
        exit => exit.remove()
      )
  }, [mapInfo, amrs])


  const dragStarted = (event: any, d: any) => {
    const location = { x: event.x, y: event.y }
    const mappedLocation = mapPixelLocationToM(mapInfo, location)
    setDragStartLocation(mappedLocation)
    dispatch(setIsAmrPlaced(true))
  }

  const dragEnded = (event: any, d: any) => {
    const location = { x: event.x, y: event.y }
    const mappedLocation = mapPixelLocationToM(mapInfo, location)
    const angle = -90 - getAngleDegrees(mappedLocation, placingAmr.status.location)
    const radians = mapOrientationToRobot(angle)

    setDragStartLocation(old => {
      initPosition(old.x, old.y, radians, placingAmrId, mapName ? mapName : "", '1')
      return {x:0, y: 0 }
    })
    dispatch(setIsPlacingAmr(false))
    dispatch(setIsAmrPlaced(false))
    dispatch(setPlacingAmrId(""))
    setPlacingAmr(nullAmr)
  }

  const dragging = (event: any) => {
    const location = { x: event.x, y: event.y }
    const mappedLocation = mapPixelLocationToM(mapInfo, location)
    setPlacingAmr(oldAmr => {
      const angle = -90 - getAngleDegrees(mappedLocation, oldAmr.status.location)
      const radians = mapOrientationToRobot(angle)
      return {
        ...oldAmr,
        "status": { location: oldAmr['status']['location'], rotation: angle }
      }
    })
  }


  useEffect(() => {
    d3.select("#map-svg").on("mousemove", function (event) {
      if (isPlacingAmr && !isAmrPlaced) {
        let coords = d3.pointer(event);
        const location = { x: coords[0], y: coords[1] }
        const mappedLocation = mapPixelLocationToM(mapInfo, location)
        setPlacingAmr(oldAmr => {
          return {
            ...oldAmr,
            "status": { location: mappedLocation, rotation: oldAmr['status']['rotation'] }
          }
        })
      }
    });
  }, [mapInfo, isPlacingAmr, isAmrPlaced]);

  useEffect(() => {
    if (!isPlacingAmr) {
      d3.select("#place-amr-layer")
        .selectAll('path')
        .remove()

      return
    }
    d3.select("#place-amr-layer")
      .selectAll('path')
      .data([placingAmr])
      .join(enter => enter.append('path')
        .style("stroke-width", 20)
        .style("stroke", "black")
        .style("fill", theme.palette.primary.main)
        .attr("d", ROBOT_SVG)
        .attr('transform', translateRobot),
        update => update.transition().duration(1)
          .attr('transform', translateRobot),
        exit => exit.remove()
        // @ts-expect-error
      ).call(d3.drag()
        .on('start', dragStarted)
        .on('drag', dragging)
        .on('end', dragEnded)


      )



  }, [mapInfo, placingAmr, isPlacingAmr, isAmrPlaced])



  return (
    <>
      <g id="amr-layer"></g>
      <g id="place-amr-layer"></g>
    </>
  )
}
