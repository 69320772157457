import { Box, Button, Snackbar, Typography } from "@mui/material";
import { CloseIcon, NotificationsIcon } from "../../assets/icons/SvgIcons";
import { RootState } from "../../store/store";
import { useSelector } from 'react-redux'
import { DataFormatter } from "utils/DataFormatter"
import { useAppDispatch } from "store/store";
import { hideErrorState } from 'store/errorstateSlice';
import { ErrorLevel} from 'model/Amr'

export const ErrorSnackbar = () => {
  const dispatch = useAppDispatch()

  const errors = useSelector(
    (state: RootState) => state.errorStates.errorStates
  )

  const handleSnackbarCloseClick = (idx: number)  => {
    dispatch(hideErrorState({ index: idx }))
  };

  return (
    < Box>
      {errors.map((error, idx) => <Snackbar
        key={idx}
        open={error.show}
        message={
          <Box>
            <NotificationsIcon />
            <Typography color="primary">
              {"Robot: ".concat(error.robot_id, ". ", DataFormatter.formatErrorString(error.error_level + (error.error_level === ErrorLevel.FATAL ? "_error": "") ))}
            </Typography>
            <Box>
              {DataFormatter.formatErrorString(error.error_type)}
            </Box>
          </Box>
        }
        action={
          < Button
            key={idx}
            size='medium'
            onClick={() => handleSnackbarCloseClick(idx)}
          >
            <CloseIcon />
          </Button>
        }
      />)}
    </Box>
  )
}
