import {MappingMapContainer} from "components/map/MappingMapContainer";
import { MappingToolbar} from "components/map/MappingToolbar";
export const MappingPage = () => {

  return (
    <>
      <MappingMapContainer />
    </>
  )
}
