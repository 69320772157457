import { createSlice, PayloadAction, StateFromReducersMapObject } from '@reduxjs/toolkit'
import { UndockingParams } from "../model/Order";
import { defaultUP } from "../components/map/fleetOverlay/undocking/AddUndockingParams";
import { CardActionArea } from '@mui/material';
import { Action } from '@remix-run/router';
import { StaticDatePicker, StaticTimePicker } from '@mui/x-date-pickers';
import { UndockingPanel } from '../components/map/fleetOverlay/undocking/UndockingPanel';
import { number } from 'zod';

export interface UPState {
  ups: UndockingParams[]
  currentUP?: UndockingParams
}

const initialState: UPState = {
  ups: []
}

export const undockparamsSlice = createSlice({
  name: 'undocking_params',
  initialState,
  reducers: {
    addUP: (state) => {
      // only use the default task if the current task is undefined 
      // if thats not the case it means we started editing a task before
      if (state.currentUP === undefined) state.currentUP = defaultUP
    },
    updateCurrentUP: (state, action: PayloadAction<{ undockParams: UndockingParams }>) => {
      state.currentUP = action.payload.undockParams
    },
    updateCurrentUPName: (state, action: PayloadAction<{ name: string }>) => {
      if (state.currentUP) state.currentUP.name = action.payload.name
    },
    updateCurrentUPNumber: (state, action: PayloadAction<{ key: number, value: number }>) => {
      if (state.currentUP) {
        switch (action.payload.key) {
          case 0:
            state.currentUP.first_drive = action.payload.value
            break;
          case 1:
            state.currentUP.second_drive = action.payload.value
            break;
          case 2:
            state.currentUP.first_rotate = action.payload.value
            break;
          case 3:
            state.currentUP.second_rotate = action.payload.value
            break;

        }
      }
    },
    setCurrentUP: (state, action: PayloadAction<UndockingParams | undefined>) => {
      state.currentUP = action.payload
    },
    setUPs: (state, action: PayloadAction<UndockingParams[]>) => {
      state.ups = action.payload
    },
    clearCurrentUP: (state) => {
      state.currentUP = undefined
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addUP,
  setUPs,
  updateCurrentUP,
  updateCurrentUPName,
  updateCurrentUPNumber,
  setCurrentUP,
  clearCurrentUP
} = undockparamsSlice.actions

export default undockparamsSlice.reducer
