import {alpha, Box, IconButton, IconButtonProps, lighten, Skeleton, Zoom} from "@mui/material";
import {AMRsIcon} from "../../../assets/icons/SvgIcons";
import React from "react";
import {useTheme} from "@mui/material/styles";

const PulsateAnimation = React.forwardRef((props, ref) => {
  const theme = useTheme()

  return (
    <Box {...props} ref={ref}>
      <Skeleton variant={"circular"} color="primary"
                sx={{bgcolor: lighten(theme.palette.primary.light, 0.5)}}>
        <IconButton
          {...props}
          sx={{
            width: "30vh",
            height: "30vh"
          }}
        >
          <AMRsIcon sx={{width: "8vh", height: "8vh"}}/>
        </IconButton>
      </Skeleton>
    </Box>
  );
})

export const PulsateIconButton = (props: IconButtonProps) => {
  const theme = useTheme()
  return (
    <Box sx={{
      position: 'relative', width: "30vh",
      height: "30vh",
    }}>
      <Zoom in={props.disabled}
            style={{transitionDuration: "500ms"}}>
        <PulsateAnimation/>
      </Zoom>
      <IconButton
        {...props}
        sx={{
          width: "26vh",
          height: "26vh",
          bgcolor: "primary.main",
          color: "primary.contrastText",
          boxShadow: `0px 4px 25px ${alpha(theme.palette.primary.main, 0.4)}`,
          "&:hover": {
            bgcolor: "primary.dark",
          },
          "&.Mui-disabled": {
            color: "primary.light",
            bgcolor: "primary.main",
            opacity: 0.7
          },
          position: "absolute",
          top: "2vh",
          left: "2vh",
        }}
      >
        <AMRsIcon sx={{width: "8vh", height: "8vh"}}/>
      </IconButton>
    </Box>

  )

}
