import {SoftShadowCard} from "../styled/SoftShadowCard";
import {Box, FormGroup, Typography} from "@mui/material";
import {IOSSwitch} from "../styled/input/Switch";
import {patchSettings, useSettings} from "../../api/subscription";
import {useMutation, useQueryClient} from "react-query";
import {ROUTES} from "../../api/routes";

export const GlobalNotificationSwitch = () => {
  const {data: global} = useSettings()
  const queryClient = useQueryClient()

  const patchGlobalNotificationsMutation = useMutation(patchSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.settings.key)
    }
  })

  const handleGlobalSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    patchGlobalNotificationsMutation.mutate(event.target.checked)
  }

  return (
    <SoftShadowCard sx={{px: 2, py: 1, m: 6, mt: 8, mb: 0, display: "flex"}}>
      <Box sx={{ml: 1, py: 2, display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
        <Box>
          <Typography variant="body1">
            Send email notifications
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {global ? "On" : "Off"}
          </Typography>
        </Box>
        <FormGroup>
          <IOSSwitch checked={global} onChange={handleGlobalSwitch}/>
        </FormGroup>
      </Box>
    </SoftShadowCard>
  )
}
