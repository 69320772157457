import {createTheme} from "@mui/material";
import fendtLogo from "../assets/svg/fendt-logo.svg"

declare module '@mui/material/styles' {
  interface Theme {
    logo: string
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    logo?: string
  }
}

export const softShadow = {filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.07))"}

export const themeOptions = createTheme({
  logo: fendtLogo,
  palette: {
    mode: "light",
    primary: {
      main: '#64AA32',
      light: '#D0E5C1',
      dark: '#477E30',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      light: '#ddd',
      main: '#aaa',
      dark: '#4c4c4c',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    text: {
      primary: '#000000',
      secondary: '#BABABA'
    },
    error: {
      light: '#e599ac',
      main: '#c00031',
    },
    warning: {
      light: '#F1C999',
      main: '#DE7800',
      dark: '#9b5300',
    },
    info: {
      main: '#0288D1'
    },
    divider: '#BABABA',
    background: {
      default: '#F2F2F2',
    },
    success: {
      main: '#64aa32',
      light: '#83bb5b',
      dark: '#467623',
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 16,
    button: {
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 14,
    },
    caption: {
      fontSize: 12,
    },
    h1: {
      fontSize: 95,
      fontWeight: 200,
    },
    h2: {
      fontSize: 60,
      fontWeight: 200,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 32,
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 12,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
    },
    overline: {
      fontSize: 12,
    },
  },
});

export const defaultTheme = createTheme(themeOptions, {
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: themeOptions.spacing(3),
          height: themeOptions.spacing(3),
        },
        fontSizeSmall: {
          width: themeOptions.spacing(2),
          height: themeOptions.spacing(2),
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8
        },
        standardError: {
          backgroundColor: "#F8E5EA"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: "none",
          filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.07))",
          borderRadius: 10
        }
      }
    }
  }
});

export const tldTheme = createTheme(defaultTheme, {
  palette: {
    primary: {
      light: '#8bf6ff',
      main: "#28B9DA",
      dark: "#0093c4",
      contrastText: 'rgba(255,255,255,0.87)',
    },
  },
  logo: undefined,
})
