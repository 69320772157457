import { TaskResponse, TaskStepResponse } from "../api/tasks";
import { PointOfInterest, POIType, ProgressStatus, Task, Trajectory, DockingParams, UndockingParams } from "../model/Order";
import { DEFAULT_MAP_ID } from "../api/map";
import { POIResponse } from "../api/poi";
import { SubscriberResponse } from "../api/subscription";
import { DockParamsResponse } from "../api/docking_params";
import { UndockParamsResponse } from "../api/undocking_params";
import { NotificationConfig } from "../components/pages/NotificationsPage";
import { format, parse } from 'date-fns'


export const DataFormatter = {
  formatErrorString: (value: string) => {
    let val_spaces = value.replaceAll("_", " ")
    var firstChar = val_spaces.charAt(0)
    var remainingChars = val_spaces.slice(1).toLowerCase()
    return firstChar + remainingChars
  },
  displayDate: (date: Date) => {
    return format(date, "dd MMM yyyy")
  },
  parsePoi: (poi: POIResponse) => {
    return {
      id: poi._id,
      name: poi.name,
      type: poi.type as POIType,
      location: { x: poi.x, y: poi.y },
      rotation: poi.rotation
    } as PointOfInterest
  },
  formatPoi: (poi: PointOfInterest, map?: string) => {
    return {
      "x": poi.location.x,
      "y": poi.location.y,
      "name": poi.name,
      "map_id": poi.mapID ?? map ?? DEFAULT_MAP_ID,
      "type": poi.type,
      "rotation": poi.rotation
    }
  },
  parseTaskTrajectories: (taskStepResponse: TaskStepResponse[]) => {
    // let parsedTrajectories: (string | undefined)[] = []
    // taskStepResponse.forEach((item, index) => {
    //   if (item.action_type === ActionType.TRAJECTORY) {
    //     parsedTrajectories[index] = item.step_ref_id
    //   }
    // })
    // return parsedTrajectories
  },
  parseTask: (taskResponse: TaskResponse) => {
    // return {
    //   id: taskResponse._id,
    //   name: taskResponse.name,
    //   description: taskResponse.description,
    //   createdAt: new Date(taskResponse.created_at),
    //   steps:
    //     taskResponse.steps.map((step) => {
    //       return { stepRefID: step.step_ref_id, actionType: step.action_type  }
    //     }),
    //   trajectories: DataFormatter.parseTaskTrajectories(taskResponse.steps)
    // } as Task
  },
  formatTask: (task: Task, map?: string) => {
    // const formattedSteps: TaskStepResponse[] = []

    // task.steps.forEach((item, index) => {
    //   if (task.trajectories[index]) {
    //     formattedSteps.push({ "step_ref_id": task.trajectories[index] ?? "", "action_type": ActionType.TRAJECTORY })
    //   }
    //   else if (item.poi?.id || item.stepRefID) {
    //     formattedSteps.push({
    //       "step_ref_id": item.poi?.id ?? item.stepRefID ?? "",
    //       "action_type": item.actionType?.toString() ?? ""
    //     })
    //   }
    // })


    // return {
    //   "name": task.name,
    //   "map_id": map ?? DEFAULT_MAP_ID,
    //   "steps": formattedSteps,
    //   "created_at": new Date(),
    //   "description": task.description
    // }
  },
  parseDockParams: (DP: DockParamsResponse) => {
    return {
      id: DP._id,
      name: DP.name,
      dock_pose: DP.dock_pose,
      approach_pose: DP.approach_pose,
      alignment_pose_distance: DP.alignment_pose_distance,
      dock_forwards: DP.dock_forwards
    } as DockingParams
  },
  formatDockParams: (DP: DockingParams) => {
    return {
      "name": DP.name,
      "dock_pose": DP.dock_pose,
      "approach_pose": DP.approach_pose,
      "alignment_pose_distance": DP.alignment_pose_distance,
      "dock_forwards": DP.dock_forwards
    }
  },
  parseUndockParams: (UP: UndockParamsResponse) => {
    return {
      id: UP._id,
      name: UP.name,
      first_drive: UP.first_drive,
      second_drive: UP.second_drive,
      first_rotate: UP.first_rotate,
      second_rotate: UP.second_rotate
    } as UndockingParams
  },
  formatUndockParams: (UP: UndockingParams) => {
    return {
      "name": UP.name,
      "first_drive": UP.first_drive,
      "second_drive": UP.second_drive,
      "first_rotate": UP.first_rotate,
      "second_rotate": UP.second_rotate
    }
  },
  weekdays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"],
  defaultTDate: new Date(0, 0, 0, 0, 0, 0),
  parseSubscriber: (response: SubscriberResponse) => {
    const parsedWeekdays = new Set(response.weekdays.map((el) => DataFormatter.weekdays.indexOf(el)))
    return {
      id: response._id,
      email: response.email,
      enabled: response.enabled,
      from: parse(response.time_start, "HH:mm:ss", DataFormatter.defaultTDate),
      to: parse(response.time_end, "HH:mm:ss", DataFormatter.defaultTDate),
      weekdays: parsedWeekdays
    } as NotificationConfig
  },
  formatSubscriber: (item: NotificationConfig) => {
    const formattedWeekdays: string[] = []
    item.weekdays.forEach((el) => {
      formattedWeekdays.push(DataFormatter.weekdays[el])
    })
    return {
      "email": item.email,
      "weekdays": formattedWeekdays,
      "enabled": item.enabled,
      "time_start": item.from ? format(item.from, 'HH:mm:ss') : "",
      "time_end": item.to ? format(item.to, 'HH:mm:ss') : "",
    }
  },
  // actionTypeString: (type: ActionType | undefined) => {
  //    switch (type) {
  //      case ActionType.pick:
  //        return "pick-up"
  //      case ActionType.DROPOFF:
  //        return "drop-off"
  //      case ActionType.CHARGE:
  //        return "charge"
  //      case ActionType.MOVE:
  //        return "move"
  //      case ActionType.TRAJECTORY:
  //        return "trajectory"
  //      default:
  //        return ""
  //    }
  // },
  progressStatusString: (type: ProgressStatus | undefined) => {
    // switch (type) {
    //   case ProgressStatus.REQUESTED:
    //     return "pending"
    //   case ProgressStatus.FAILED:
    //     return "failed"
    //   case ProgressStatus.RUNNING:
    //     return "running"
    //   case ProgressStatus.COMPLETE:
    //     return "complete"
    //   default:
    //     return ""
    // }
  },
}
