import {Box, IconButton, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router-dom";

export const ToolbarTitle = (props: { title: string, back?: string, onClick?: () => void }) => {
  const navigate = useNavigate()
  const onClickHandler = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(-1);
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", ml: props.back ? 0 : 2}}>
      {props.back && <IconButton onClick={() => onClickHandler()}>
        <ArrowBackIcon fontSize="small"/>
      </IconButton>}
      <Typography sx={{my: 1}} variant="body2">
        {props.title}
      </Typography>
    </Box>
  )
}
