import {ROUTES} from "./routes";
import { axiosInstance } from "./axios";
import { IEdge, IEdgeApi, mapEdgeToApi, mapEdgeFromApi } from "model/Nodes"


// TODO: add zod and schemas to all API message
// Make sure we always return proper TypeScript types
export const getAllEdges = async () => {
  const {data} = await axiosInstance.get(`${ROUTES.edges.url}`)
  return data.map((datum: IEdgeApi) => mapEdgeFromApi(datum))
};

export const getEdge = async (id: string) => {
  const {data} = await axiosInstance.get(`${ROUTES.edges.url}/${id}`)
  return mapEdgeFromApi(data)
};

export const getEdges = async (ids: string[]) => {
  const data = await Promise.all(ids.map(async(id) => {
    const { data: da} = await axiosInstance.get(`${ROUTES.edges.url}/${id}`)
    return da
  }))
  return data.map(( datum: IEdgeApi): IEdge => mapEdgeFromApi(datum))
};

export const createEdge = async (edge: IEdge) => {
  return createEdges([edge])
};

export const createEdges = async (edges: IEdge[]) => {
  const { data } = await axiosInstance.post(`${ROUTES.edges.url}`, edges.map(mapEdgeToApi))
  return data
};

export const deleteEdge = async (id: string) => {
  const { data } = await axiosInstance.delete(`${ROUTES.edges.url}/${id}`)
  return data
};

export const deleteEdges = async (edge_ids: string[]) => {
  const data = await Promise.all(edge_ids.map(deleteEdge))
  return data
}
