import {Box, BoxProps, Typography} from "@mui/material";
import {ReactNode} from "react";

interface ValueRowProps extends BoxProps {
  title: string
  value: string
  icon?: ReactNode
  background?: boolean
  small?: boolean
}

export const ValueRow = (props: ValueRowProps) => {
  let {icon, title, value, background, small} = props;
  return (
    <Box sx={{
      ...props.sx,
      display: "flex",
      pt: 1,
      bgcolor: background ? "#F2F2F266" : "paper"
    }}>
      <Box sx={{flex: 3, display: "flex", alignItems: "center"}}>
        {
          icon && <Box sx={{m: 0.25, mr: 1, display: "flex", alignItems: "center", color: "secondary.dark"}}>
            {icon}
          </Box>
        }
        <Typography color="textPrimary" component="span" fontSize={small ? "smaller" : "medium"}>{title}</Typography>
      </Box>
      <Box sx={{flex: 2, display: "flex", alignItems: "center"}}>
        <Typography component="span" fontSize={small ? "smaller" : "medium"}>{value}</Typography>
      </Box>
    </Box>
  )
}
