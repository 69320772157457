
import { alpha, Box, Button, LinearProgress, linearProgressClasses, Typography } from "@mui/material";
import { useWebSocket } from "components/hooks/websocket";
import { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { PulsateIconButton } from "../styled/buttons/PulsateIconButton";
import { CloseIcon } from "../../assets/icons/SvgIcons";
import { getJobs, getLiveJobsFull } from "store/jobSlice";
import { IJobStatus, ILiveJobFull, PRIORITY } from "model/Jobs";
import { isEqual } from "lodash";

const ThickLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: theme.spacing(5),
  width: theme.spacing(20),
  opacity: 0.9,
  borderRadius: 16,
  border: `1px solid ${theme.palette.primary.main} `,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.background.default,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: alpha(theme.palette.primary.light, 0.6),
  },
}));

export const JobWorkerPage = () => {
  const { sendJob, cancelJob } = useWebSocket()

  const path = window.location.pathname.split("/")
  const jobId = path[3]
  const job = useSelector(getJobs).find((job) => job.id === jobId)
  const jobs_pending = useSelector(getLiveJobsFull(IJobStatus.WAITING))
  const jobs_running = useSelector(getLiveJobsFull(IJobStatus.RUNNING))
  const [liveJobFull, setliveJobFull] = useState<ILiveJobFull | undefined>(undefined)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
  let [progress, setProgress] = useState(0)

  const memoizedIsEqual = useMemo(() => isEqual, []);



  useEffect(() => {
    let liveJob = jobs_running.find(running_job => running_job.jobId === jobId)
    if (!liveJob)
      liveJob = jobs_pending.find(pending_job => pending_job.jobId === jobId)

    if (!memoizedIsEqual(liveJobFull, liveJob))
      setliveJobFull(liveJob)

  }, [jobs_pending, jobs_running])

  useEffect(() => {
    if (progress >= 200) {
      clearInterval(intervalId)
      setIntervalId(undefined)
      setProgress(0)
    }
    else if (progress >= 100) {
      clearInterval(intervalId)
      setIntervalId(undefined)
      setProgress(0)
      sendJob(jobId, PRIORITY.MEDIUM, false, false)
    }
  }, [progress, intervalId])


  const handleExecuteJob = () => {
    if (!intervalId) {
      const interval = setInterval(() => {
        setProgress((progress) => progress + 1)
      }, 50);
      setIntervalId(interval)
    }
  }

  const handleCancelJob = () => {
    if (liveJobFull)
      cancelJob(liveJobFull.liveJobId)
  }

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      bgcolor: "background.paper"
    }}>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Typography variant="h5" color="secondary" sx={{ m: 4, mb: 2 }}>
          Job: {job?.name}
        </Typography>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.paper",
        flex: 1
      }}>
        <Typography variant="h6" color="secondary" sx={{ mb: 3 }}>
          {liveJobFull ? (liveJobFull.jobStatus === IJobStatus.WAITING ? "Assigning AMR to the Job" : "Executing Job") : "Execute Job"}
        </Typography>

        <PulsateIconButton onClick={handleExecuteJob}
          disabled={liveJobFull !== undefined}
        />

        {progress === 0 && <>
          <Typography textAlign="center" variant="body2" sx={{ mt: 3 }} color="secondary">
            {liveJobFull ? "Job Status:" : "Press button to generate job"}
          </Typography>
          <Typography textAlign="center" variant="body2" color="secondary">
            {liveJobFull?.jobStatus}
          </Typography>
        </>}

        {progress > 0 &&
          <Box sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
            onClick={() => {
              setProgress(500)
            }
            }
          >
            <ThickLinearProgress sx={{
              mt: 3, "&:hover": {
                backgroundColor: "secondary.light"
              }
            }} variant="determinate" value={progress} />
            <Box sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 40,
              top: 24,
              pointerEvents: "none"
            }}>
              <Typography textAlign="center" variant="body2">
                Cancel request
              </Typography>
              <CloseIcon sx={{ ml: 1.5, width: 16, height: 16 }} />
            </Box>
          </Box>
        }

        {liveJobFull && <Box>
          <Button variant={"outlined"} color={"secondary"} size={"large"} sx={{ mt: 3 }} onClick={handleCancelJob} >
            Cancel job
          </Button>
        </Box>}
      </Box>

      <Box sx={{ display: "flex", flex: 1 }} />


    </Box>
  )
}

