import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IJob, ILiveJob, IJobWithOrder, ILiveJobFull, defaultJobWithOrder, IJobStatus } from "model/Jobs";
import { IOrder } from "model/Orders";
import { RootState } from "store/store"
import { getOrdersBySelectedMap } from './orderSlice';

interface JobsState {
  jobs: IJob[]
  liveJobs: ILiveJob[]
  editingJob?: IJobWithOrder
  selectedJob?: IJob
  initialLoad: boolean
  isBuildingJob: boolean
  isEditingJob: boolean
}

const initialState: JobsState = {
  jobs: [],
  liveJobs: [],
  editingJob: undefined,
  initialLoad: false,
  isBuildingJob: false,
  isEditingJob: false,
  selectedJob: undefined
}


const withPayload = <T,>(func: (state: JobsState, payload: T) => void) => (state: JobsState, action: PayloadAction<T>) => {
  func(state, action.payload)
}
const newJobFunctions = {
  initEditingJob: withPayload<void>((s, p) => {
    s.editingJob = defaultJobWithOrder
  }),
  addOrderToJob: withPayload<IOrder>((s, p) => {
    if (s.editingJob) {
      s.editingJob.orders.push(p)
    }
  }),
  updateEditingJobName: withPayload<{ name?: string }>((s, p) => {
    if (p.name !== undefined && s.editingJob) s.editingJob.name = p.name
  }),
  setEditingJob: withPayload<IJobWithOrder>((s, p) => {
    s.editingJob = p
  }),
  removeOrderFromJob: withPayload<string>((s, p) => {
    if (s.editingJob) {
      s.editingJob.orders = s.editingJob.orders.filter(order => order.id !== p)
    }
  }),
  clearEditingJob: withPayload<void>((s, p) => {
    s.editingJob = undefined
  })
}


const jobsFunctions = {
  addJob: withPayload<IJob>((s, p) => {
    s.jobs.push(p)
  }),
  setJobs: withPayload<IJob[]>((s, p) => {
    s.jobs = p
  }),
  updateJobState: withPayload<IJob>((s, p) => {
    const index = s.jobs.findIndex(job => job.id === p.id)
    if (index === -1) {
      console.error(`Job not found in state. ${p.name} ${p.id}`)
      return
    }
    s.jobs[index] = p
  }),
  setSelectedJob: withPayload<IJob>((s, p) => {
    s.selectedJob = p
  }),
  removeJob: withPayload<string>((s, p) => {
    s.jobs = s.jobs.filter(job => job.id !== p)
  })
}


const liveJobFunctions = {
  addLiveJob: withPayload<ILiveJob>((s, p) => {
    s.liveJobs.push(p)
  }),
  saveLiveJobs: withPayload<ILiveJob[]>((s, p) => {
    s.liveJobs = p
  })
}


const toggleFunctions = {
  toggleIsBuildingJob: withPayload<boolean>((s, p) => {
    s.isBuildingJob = p
  }),
  toggleIsEditingJob: withPayload<boolean>((s, p) => {
    s.isEditingJob = p
  }),

}

export const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    ...jobsFunctions,
    ...newJobFunctions,
    ...toggleFunctions,
    ...liveJobFunctions,
  },
})

// Action creators are generated for each case reducer function
export const {

  //new job
  initEditingJob,
  addOrderToJob,
  removeOrderFromJob,
  clearEditingJob,
  updateEditingJobName,
  setEditingJob,
  setSelectedJob,
  //
  addJob,
  setJobs,
  updateJobState,
  removeJob,

  // toggle functions
  toggleIsBuildingJob,
  toggleIsEditingJob,

  // live job functions
  addLiveJob,
  saveLiveJobs,


} = jobSlice.actions


export const getJobs = (state: RootState): IJob[] => state.jobs.jobs

export const getJobsBySelectedMap = (state: RootState): IJob[] => {
  const orders = getOrdersBySelectedMap(state)
  return state.jobs.jobs.filter(job => (job.orders.every(order => orders.some(o => o.id === order))))
}


export const getEditingJob = (state: RootState): (IJobWithOrder | undefined) => state.jobs.editingJob
export const isBuildingJob = (state: RootState): boolean => state.jobs.isBuildingJob
export const isEditingJob = (state: RootState): boolean => state.jobs.isEditingJob
export const getSelectedJob = (state: RootState): (IJob | undefined) => {
  if (!state.jobs.selectedJob) {
    return undefined
  }
  return {
    "id": state.jobs.selectedJob.id,
    "orders": state.jobs.selectedJob.orders,
    "name": state.jobs.selectedJob.name,
  }

}

export const getRunningJobByRobot = (robotId: string) => (state: RootState): ILiveJobFull | undefined => {
  const liveJob = state.jobs.liveJobs.find(job => job.assignedAmr === robotId && job.jobStatus === IJobStatus.RUNNING)
  if (!liveJob) {
    return
  }
  const savedJob = state.jobs.jobs.find((savedJob: IJob) => savedJob.id === liveJob.jobId)
  if (!savedJob) {
    return
  }
  const orders = savedJob.orders.flatMap((orderId: string) => {
    const order = state.orders.orders.find(stateOrder => stateOrder.id === orderId)
    if (!order) {
      return []
    }
    return [order]
  })
  return {
    //normal params
    jobId: liveJob.jobId,
    jobStatus: liveJob.jobStatus,
    assignedAmr: liveJob.assignedAmr,
    activeOrderId: liveJob.activeOrderId,
    activeOrderStatus: liveJob.activeOrderStatus,
    activeOrderIndex: liveJob.activeOrderIndex,
    liveJobId: liveJob.liveJobId,
    isBlocking: liveJob.isBlocking,
    repeatJob: liveJob.repeatJob,
    priority: liveJob.priority,
    //extended fields
    name: savedJob.name,
    orders: orders,

  }

}


//export const getLiveJobs = (state: RootState): ILiveJob[] => state.jobs.liveJobs
export const getLiveJobsFull = (jobStatus: string) => (state: RootState): ILiveJobFull[] => {
  return state.jobs.liveJobs.filter(job => job.jobStatus === jobStatus)
    .flatMap(liveJob => {
      const savedJob = state.jobs.jobs.find((savedJob: IJob) => savedJob.id === liveJob.jobId)
      if (!savedJob) {
        return []
      }

      const orders = savedJob.orders.flatMap((orderId: string) => {
        const order = state.orders.orders.find(stateOrder => stateOrder.id === orderId)
        if (!order) {
          return []
        }
        return [order]
      })

      return [{
        //normal params
        jobId: liveJob.jobId,
        jobStatus: liveJob.jobStatus,
        assignedAmr: liveJob.assignedAmr,
        activeOrderId: liveJob.activeOrderId,
        activeOrderStatus: liveJob.activeOrderStatus,
        activeOrderIndex: liveJob.activeOrderIndex,
        liveJobId: liveJob.liveJobId,
        isBlocking: liveJob.isBlocking,
        repeatJob: liveJob.repeatJob,
        priority: liveJob.priority,
        //extended fields
        name: savedJob.name,
        orders: orders,

      }]
    })

}


// export const getLiveJobsByJobId = (id: string) => (state: RootState): ILiveJobFull[] => {
//return state.jobs.liveJobs.filter(job => job.id === jobStatus)
//  .flatMap(liveJob => {
//    const savedJob = state.jobs.jobs.find((savedJob: IJob) => savedJob.id === liveJob.jobId)
//    if (!savedJob) {
//      return []
//    }

//    const orders = savedJob.orders.flatMap((orderId: string) => {
//      const order = state.orders.orders.find(stateOrder => stateOrder.id === orderId)
//      if (!order) {
//        return []
//      }
//      return [order]
//    })

//    return [{
//      //normal params
//      jobId: liveJob.jobId,
//      jobStatus: liveJob.jobStatus,
//      assignedAmr: liveJob.assignedAmr,
//      activeOrderId: liveJob.activeOrderId,
//      activeOrderStatus: liveJob.activeOrderStatus,
//      activeOrderIndex: liveJob.activeOrderIndex,
//      liveJobId: liveJob.liveJobId,
//      isBlocking: liveJob.isBlocking,
//      repeatJob: liveJob.repeatJob,
//      priority: liveJob.priority,
//      //extended fields
//      name: savedJob.name,
//      orders: orders,

//    }]
//  })
// }

export default jobSlice.reducer
