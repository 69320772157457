import React, { createContext, ReactNode, useEffect, useRef, useState, useCallback } from 'react'
import { WS_URL, WSTopic } from 'constants/wsTopics';
import { RootState, useAppDispatch } from "store/store";
import { showNotification } from 'store/notificationSlice';
import { useSelector } from "react-redux";
import { Coordinates } from 'model/Map';
import { moveAMR, saveRobotState } from 'store/amrSlice';
import { WSFormatter } from "utils/WSFormatter";
import { mapRobotPositionFromWS, mapRobotStateFromWS } from "model/Amr";
import { saveLiveJobs } from "store/jobSlice";
import { mapLiveJobsFromWS } from "model/Jobs";
import { setWSLiveState } from "store/configSlice";
import { v4 as uuidv4 } from 'uuid';
import { Error } from "model/Amr";
import { updateErrors } from 'store/errorstateSlice';
import { getOrder } from "api/orders";
import { addOrder } from "store/orderSlice";
import { addJob } from "store/jobSlice";
import { getJob } from "api/jobs";

interface WebSocketContextInterface {
    socket: React.MutableRefObject<WebSocket | null>
    sendMessage: (topic: string, payload?: any) => void,
    lastUpdate: Date
    startMapping: (robotId: string, mapId: string) => void
    stopMapping: (robotId: string) => void
    sendToCharge: (robotID: string) => void
    sendRobot: (to: Coordinates, rotation: number, robotID: string) => void
    sendRobotToCharge: (robotID: string) => void
    sendHomeLift: (robotId: string) => void
    sendJob: (jobID: string, priority: string, repeat_job: boolean, isBlocking: boolean, amr?: string) => void
    cancelJob: (liveJobId: string) => void
    sendManualDriving: (x: number, y: number, robotId: string) => void
    initPosition: (x: number, y: number, theta: number, robotId: string, mapId: string, lastNodeId?: string) => void
    sendOperateLift: (to: boolean, robotId: string) => void
    sendOperateSafety: (to: boolean, robotId: string) => void
    sendAbortTask: (robotID: string) => void
    sendSetMap: (robotId: string, mapId: string) => void
    reconnect: () => void
}

const validateJSON = (payload: any) => {
    try {
        const payloadBody = JSON.parse(payload)
        return { result: true, payloadBody }
    } catch (e) {
        console.error("Parsing of JSON failed", e)
        return { result: false }
    }
}


// TODO: better implementation of throttle for state updates .
let stateCount = 0
const acceptEveryN = 1

const WebSocketContext = createContext<WebSocketContextInterface | null>(null)

export { WebSocketContext }

const d = new Date()
// move it back one day so "last update" doesnt trigger falst isLive state
d.setDate(d.getDate() - 1);

export const WebSocketProvider = (props: { children: ReactNode }) => {
    const [lastUpdate, setLastUpdate] = useState<Date>(d);
    //const [timeCallback, setTimeCallback] = useState<Date>(new Date());
    //const [reconnectCount, setReconnectCount] = useState(0);
    const socket = useRef<WebSocket | null>(null);
    const dispatch = useAppDispatch()
    const { ws: wsUrl } = useSelector((state: RootState) => state.config)

    useEffect(() => {
        if (wsUrl) reconnect()
    }, [wsUrl])

    //useEffect(() => {
    //  const diff = (timeCallback.valueOf() - lastUpdate.valueOf()) / 1000;
    //  const newLiveState = diff < 2.0
    //  //dispatch(setWSLiveState(newLiveState))

    //}, [timeCallback])


    //useEffect(() => {
    //  const interval = setInterval(() => {
    //    setTimeCallback(new Date())
    //  }, 2000);
    //  return () => clearInterval(interval);
    //}, []);


    const keyboardControl = (e: KeyboardEvent) => {
        let x = 0;
        let y = 0;
        switch (e.key) {
            case 'ArrowUp':
                y = 0.1;
                break;
            case 'ArrowDown':
                y = -0.1;
                break;
            case 'ArrowLeft':
                x = 0.1;
                break;
            case 'ArrowRight':
                x = -0.1;
                break;
            default:
                return
        }
        if (socket.current && socket.current.OPEN) {
            // socket.current.send(formatWSMessageMove(x, y, mockedAMRs[0].id));
        }
    }

    const endKeyboardControl = (e: KeyboardEvent) => {
        // TODO: implement this function
        // if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].indexOf(e.key) >= 0 && socket.current && socket.current.OPEN) {
        //   socket.current.send(formatWSMessageMove(0, 0, mockedAMRs[0].id));
        // }
    }

    const handleErrors = (robot_id: string, errors: Error[]) => {
        dispatch(updateErrors({ robot_id: robot_id, errors: errors }))
    }


    const handleReceiveMessage = (m: MessageEvent<any>) => {
        // dispatch(setLastUpdateDate(new Date()))
        try {
            let data = JSON.parse(m.data)
            const wsTopic = new WSTopic()
            const { result, payloadBody } = validateJSON(data.payload)
            if (!result) return
            switch (data.topic) {
                case wsTopic.TOPIC_LIVE_JOBS: {
                    const liveJobUpdate = mapLiveJobsFromWS(payloadBody["live_jobs"])
                    dispatch(saveLiveJobs(liveJobUpdate))
                    break;
                }
                case wsTopic.TOPIC_POSITION: {
                    //const robotPosition = mapRobotPositionFromWS(payloadBody["position"], payloadBody["robot_id"])
                    //dispatch(moveAMR(robotPosition))
                    break;
                }
                case wsTopic.TOPIC_FLEET_STATE: {
                    break;
                }
                case wsTopic.TOPIC_ROBOT_STATE: {
                    const robotState = mapRobotStateFromWS(payloadBody["robot"])
                    dispatch(saveRobotState(robotState))
                    handleErrors(robotState.id, robotState.status.errors)

                    break;
                }
                case wsTopic.TOPIC_FIRE_ALARM: {
                    dispatch(showNotification({
                        text: "Fire Alarm is triggered." as string,
                        data: "All robots are halted "
                    }))
                    break;
                }
                case wsTopic.REFRESH_FROM_DB: {
                    switch (payloadBody['key']) {
                        case "order": {
                            (async () => {
                                const order = await getOrder(payloadBody['value'])
                                dispatch(addOrder(order))
                            })()
                            break;
                        }
                        case "job": {
                            (async () => {
                                const job = await getJob(payloadBody['value'])
                                dispatch(addJob(job))
                            })()
                            break;
                        }
                        default: {
                            console.error('Unknown state to refresh', payloadBody)
                        }
                    }

                    break;
                }
                default: {
                    // TODO: handle this properly
                    console.log(`Unknown topic: ${data.topic}`)
                }
            }
            setLastUpdate(new Date())
        } catch (error) {
            console.error(error)
            dispatch(showNotification({
                text: "An error occured while processing a WebSocket message." as string,
                data: error as Record<string, any>
            }));
        }
    }

    const connect = () => {
        socket.current = new WebSocket(wsUrl ?? WS_URL);
        console.warn('Trying to reconnect')
        console.warn('Socket,', socket.current)
        try {
            socket.current.onopen = (m) => {
                //window.addEventListener('keyup', endKeyboardControl);
                //window.addEventListener('keydown', keyboardControl);
                //setLastUpdate(new Date())
                // console.log('tried to open')
            }
        } catch (error) {
            dispatch(showNotification({
                text: "An error occured opening the WebSocket Connection." as string,
                data: error as Record<string, any>
            }));
            console.error('Failed to open websocket connection', error)
            // setLastUpdate(new Date())
        }

        socket.current.onmessage = handleReceiveMessage

        socket.current.onclose = (m) => {
            try {
                if (true) {
                    //setReconnectCount(count => count + 1)
                    //dispatch(showNotification({
                    //  text: "Lost WebSocket connection. Trying to reconnect." as string,
                    //  data: ""
                    //}));
                    // do not reconnect for now
                    setTimeout(function () {
                        connect();
                    }, 5000);
                } else {

                }
                // setLastUpdate(new Date())
            } catch (error) {
                //dispatch(showNotification({
                //  text: "An error occured closing the WebSocket Connection." as string,
                // data: ``
                // }));
                // setLastUpdate(new Date())
            }
        }

        socket.current.onerror = (m) => {
            //dispatch(showNotification({
            //  text: "An error occured with the WebSocket Connection." as string,
            //  data: m.type
            //}));
            // setLastUpdate(new Date())
        }
    }

    useEffect(() => {
        if (!socket.current) {
            connect()
            // setLastUpdate(new Date())
        }
    })


    function sendMessage(topic: string, payload?: any) {
        let message = {
            topic: topic,
            payload: payload,
            timestamp: "hello"
        }
        try {
            socket.current && socket.current?.OPEN && socket.current.send(JSON.stringify(message));
        } catch (error) {
            console.error(error)
            dispatch(showNotification({
                text: "An error occured sending a WebSocket message." as string,
                data: error as Record<string, any>
            }));
        }
    }

    const sendJob = useCallback((jobID: string, priority: string, repeat_job: boolean, isBlocking: boolean, amr?: string) => {
        sendMessage(new WSTopic().TOPIC_EXECUTE_JOB, {
            pre_assigned_amr: amr,
            job_id: jobID,
            repeat_job: repeat_job,
            is_blocking: isBlocking,
            job_priority: priority
        })
    }, [])

    const cancelJob = useCallback((liveJobId: string) => {
        sendMessage(new WSTopic().TOPIC_CANCEL_JOB, { live_job_id: liveJobId })
    }, [])

    const sendManualDriving = useCallback((x: number, y: number, robotId: string) => {
        sendMessage(new WSTopic().TOPIC_MANUAL_DRIVE, { x: x, y: y, robot_id: robotId })
    }, [])

    const initPosition = useCallback((x: number, y: number, theta: number, robotId: string, map_name: string, lastNodeId = "1") => {
        sendMessage(new WSTopic().TOPIC_INIT_POSITION, { action_id: uuidv4(), x: x, y: y, theta: theta, robot_id: robotId, map_id: map_name, last_node_id: lastNodeId })
    }, [])

    function sendOperateLift(to: boolean, robotId: string) {
        sendMessage(new WSTopic().TOPIC_OPERATE_LIFT, { raise: to, robot_id: robotId })
    }

    function sendOperateSafety(to: boolean, robotId: string) {
        sendMessage(new WSTopic().TOPIC_OPERATE_SAFETY, { sls: to, robot_id: robotId })
    }

    function sendHomeLift(robotId: string) {
      sendMessage(new WSTopic().TOPIC_HOME_LIFT, { "action_id": uuidv4(), "robot_id": robotId })
    }


    const stopMapping = useCallback((robotId: string) => {
        sendMessage(new WSTopic().TOPIC_STOP_MAPPING, { "action_id": uuidv4(), "robot_id": robotId })
    }, [])

    const startMapping = useCallback((robotId: string, mapId: string) => {
        sendMessage(new WSTopic().TOPIC_START_MAPPING, { "action_id": uuidv4(), "robot_id": robotId, "map_name": mapId })
    }, [])

    const sendSetMap = useCallback((robotId: string, mapId: string) => {
        sendMessage(new WSTopic().TOPIC_SET_MAP, { "action_id": uuidv4(), "robot_id": robotId, "map_id": mapId })
    }, [])


    const sendRobotToCharge = (robotId: string) => {
        sendMessage(new WSTopic().TOPIC_GO_CHARGE, { robot_id: robotId })
    }


    function sendToCharge(robotID: string) {
        //TODO: implement this function
        // sendMessage(new WSTopic().TOPIC_CHARGE, { "operating_mode": "charging" })
    }


    function sendRobot(to: Coordinates, rotation: number, robotID: string) {
        //TODO: implement this function
        // sendMessage(new WSTopic().TOPIC_GO_TO, { "x": to.x, "y": to.y, "yaw": rotation })
    }

    //function sendRobotToCharge(robotID: string) {
    //  //TODO: implement this function
    // sendMessage(new WSTopic().TOPIC_GO_CHARGE, { "x": to.x, "y": to.y, "yaw": rotation })
    // }

    function sendAbortTask() {
        //TODO: implement this function
        // sendMessage(new WSTopic().TOPIC_ABORT_TASK, { "timestamp": new Date() })
    }

    function reconnect() {
        connect()
    }

    const ws = {
        socket,
        sendMessage,
        lastUpdate,
        startMapping,
        stopMapping,
        sendToCharge,
        sendRobot,
        sendRobotToCharge,
        sendAbortTask,
        reconnect,
        sendJob,
        cancelJob,
        sendManualDriving,
        initPosition,
        sendSetMap,
        sendOperateLift,
        sendOperateSafety,
        sendHomeLift,
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {props.children}
        </WebSocketContext.Provider>
    )
}
