import {
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { updateCurrentUP, updateCurrentUPName, updateCurrentUPNumber } from "../../../../store/undockparamsSlice";

export const UndockParamsForm = () => {
  const dispatch = useAppDispatch()
  const { currentUP } = useSelector((state: RootState) => state.ups)
  const { ups } = useSelector((state: RootState) => state.ups)

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCurrentUPName({ name: event.target.value }))
  }

  const handleChangeFirstDrive = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentUPNumber({ key: 0, value: parseFloat(event.target.value) }))
  }

  const handleChangeSecondDrive = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentUPNumber({ key: 1, value: parseFloat(event.target.value) }))
  }

  const handleChangeFirstRotate = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentUPNumber({ key: 2, value: parseFloat(event.target.value) }))
  }

  const handleChangeSecondRotate = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentUPNumber({ key: 3, value: parseFloat(event.target.value) }))
  }


  return (
    <>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Name
      </Typography>
      <OutlinedInput placeholder="Write name here" value={currentUP?.name ?? ""} onChange={handleChangeName} />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        First drive
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeFirstDrive}
        value={currentUP?.first_drive ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Second drive
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeSecondDrive}
        value={currentUP?.second_drive ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        First rotate
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeFirstRotate}
        value={currentUP?.first_rotate ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Second rotate
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeSecondRotate}
        value={currentUP?.second_rotate ?? 0.0}
      />



    </>
  )

}
