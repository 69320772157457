import { useSelector } from "react-redux";
import { useCallback } from "react";
import { IconButton, List, ListItemText, Typography } from "@mui/material";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { AddButton } from "components/styled/toolbar/AddButton";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { getZonesOfSelectedMap, setIsAddingZone, removeZone } from "store/zoneSlice";
import { getSelectedMapId, getActiveZoneId, saveSelectedMapActiveZone } from "store/mapSlice";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { useAppDispatch } from "store/store";
import { IZone } from "model/Zones";
import { updateMapZone, getMapActiveZone } from "api/map";
import { deleteZone } from "api/zones";


export const ZonesPanel = () => {
  const selectedMapId = useSelector(getSelectedMapId)
  const activeZoneId = useSelector(getActiveZoneId)
  const dispatch = useAppDispatch()
  const zones = useSelector(getZonesOfSelectedMap)

  const handleItemClick = useCallback((zone: IZone) => {
    const fetch = async() => {
      if (selectedMapId) {
        await updateMapZone(selectedMapId, zone.id)
        const activeZoneId = await getMapActiveZone(selectedMapId)
        dispatch(saveSelectedMapActiveZone(activeZoneId["active_zone_id"]))
      }
    }
    fetch()
   }, [selectedMapId])

  const handleItemDelete = useCallback((zoneId: string) => {
      const asyncDelete = async() => {
        const success = await deleteZone(zoneId)
        if (success) {
          dispatch(removeZone(zoneId))
        }
      }
      asyncDelete()

  }, [zones])

  const onCreateClick = useCallback(() => {
    dispatch(setIsAddingZone(true))
  },[])

  return (
    <>
      <ToolbarTitle title={"Zones"}/>
      <AddButton onClickHandler={onCreateClick} title="Create New Zone"/>
      <List>
        {zones.map((zone, id) =>
        <ListItemWithCRUD
              key={zone.id}
              sx={{pl: 1, borderRadius: 1}}
              onClick={() => handleItemClick(zone)}
              handleDelete={zone.id !== activeZoneId ? () => handleItemDelete(zone.id) : undefined}
        >
        {zone.id === activeZoneId ?
            <IconButton>
              <RadioButtonCheckedIcon sx={{color: "secondary.main", width: 18, height: 18}}/>
            </IconButton>
            :
            <IconButton>
              <RadioButtonUncheckedIcon sx={{color: "secondary.main", width: 18, height: 18}}/>
            </IconButton>
        }
          <ListItemText>
            <Typography variant="body2">
              {zone.zoneName}
            </Typography>
          </ListItemText>

        </ListItemWithCRUD>
      )}
      </List>
    </>
  )
}
