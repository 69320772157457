import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "store/store";
import { IZone } from "model/Zones";
import {useSelector} from "react-redux";
import {ToolbarTitle} from "components/styled/toolbar/ToolbarTitle";
import { saveNewZone, setZoneBrush, getZoneBrush, setIsAddingZone, getZonesOfSelectedMap } from "store/zoneSlice";
import { getSelectedMap, resetTransition } from "store/mapSlice";
import d3ToPng from "d3-svg-to-png";
import { createZone, getZone } from "api/zones";
import * as d3 from "d3";

const contentLayers = [
  "#amr-layer",
  "#place-amr-layer",
  "#edge-layer",
  "#new-edge-layer",
  "#node-layer",
  "#zone-image-group"
]

const layersNotOnZone = [
  "#amr-layer",
  "#place-amr-layer",
  "#edge-layer",
  "#new-edge-layer",
  "#node-layer",
]

const zoneLayerId = [
  "#zone-image-group"
]

export const AddZone = () => {
  const [error] = useState(false)
  const [newZoneName, setNewZoneName] = useState({name: " name "})
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const zoneBrush = useSelector(getZoneBrush)
  const selectedMap = useSelector(getSelectedMap)
  const zones = useSelector(getZonesOfSelectedMap)
  const [isUsingZoneTemplate, setIsUsingZoneTemplate] = useState(false)

  useEffect(() => {
    lowerLayers(zoneLayerId)
  }, [])

  const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewZoneName(zone => ({...zone, "name": event.target.value}))
  };


  const lowerLayers = (layersList: string[]) => {
    layersList.forEach(layer => {
     d3.select(layer).style("visibility", "hidden")
    })
  }

  const raiseLayers = (layerlist: string[]) => {
    contentLayers.forEach(layer => {
      d3.select(layer).style("visibility", "visible")
    });
  }


  const handleSaveZone = async() => {
    if (selectedMap) {
      lowerLayers(layersNotOnZone)
      dispatch(resetTransition())
      await new Promise(resolve => setTimeout(resolve, 1000));
      const zoneData = await d3ToPng("#map-svg", 'helooo', { scale: 5, download: false})
      raiseLayers(contentLayers)
      const zoneMapString = zoneData.split("data:image/png;base64,")[1]
      const newZone: IZone = {
        correspondingMapName: selectedMap.mapName,
        zoneMapData: zoneMapString,
        zoneName: newZoneName.name,
        resolution: selectedMap.info.resolution,
        originX: selectedMap.info.origin.x,
        originY: selectedMap.info.origin.y,
        id: "tempID"
      }
      const createdId = await createZone(newZone)
      const fetchedZone = await getZone(createdId)
      dispatch(saveNewZone(fetchedZone))
      dispatch(setIsAddingZone(false))
      navigate(-1)

    }
  }

  const onBackHandler = () => {
    raiseLayers(contentLayers)
    dispatch(setIsAddingZone(false))
  }

  const setBrushColor = (color: string) => {
    dispatch(setZoneBrush({ ...zoneBrush, "color": color}))
  }

  const setBrushSize = (change: string) => {
    const sizeChange = change === "BIGGER" ? 5 : -5
    dispatch(setZoneBrush({ ...zoneBrush, "radius": zoneBrush["radius"] + sizeChange}))
  }

  const getTemplateButtonColor = (templateActive: boolean) => {
      return templateActive == isUsingZoneTemplate ? "primary" : "secondary"
  }

  const toggleZoneTemplate = (toggle: boolean) => {
    setIsUsingZoneTemplate(toggle)
    if (toggle) {
      raiseLayers(zoneLayerId)
    } else {
      lowerLayers(zoneLayerId)
    }
  }

  const getButtonColor = (color: string) => {
    return color === zoneBrush.color ? "primary" : "secondary"
  }

  return (
   <>
      <ToolbarTitle title={"Add Zone"} onClick={onBackHandler} back="zones"/>
      <FormControl fullWidth  error={error}>
        <Typography sx={{mb: 1, mt: 2}} variant={"body2"}>
          Zone Name
        </Typography>
        <OutlinedInput placeholder="Please enter text" value={newZoneName.name} onChange={handleChangeName}/>

      </FormControl>
      Select Brush Color
      <Box sx={{display: "flex", alignItems: "end"}}>
        <Button variant={zoneBrush.color === "WHITE" ? "contained" : "outlined"} color="primary" onClick={() => setBrushColor("WHITE")}
                >
          White
        </Button>
        <Button variant={zoneBrush.color === "BLACK" ? "contained" : "outlined"} color="primary" sx={{ml: 1}} onClick={() => setBrushColor("BLACK")}
                >
          Black
        </Button>
      </Box>
      <br/>
        {"Current Brush Size: "}{zoneBrush.radius}
        <Box sx={{display: "flex", alignItems: "end"}}>
              <Button variant="outlined" color="primary" onClick={() => setBrushSize("SMALLER")}
                      >
                Smaller
              </Button>
              <Button variant="outlined" color="primary" sx={{ml: 1}} onClick={() => setBrushSize("BIGGER")}
                      >
                Bigger
              </Button>
         </Box>
         <br/>
         <Typography sx={{mb: 1, mt: 2}} variant={"body2"}>
           Use Template
         </Typography>
         <Box sx={{display: "flex", alignItems: "end"}}>
           <Button variant="outlined" color={getTemplateButtonColor(true)} sx={{ml: 2}} onClick={() => toggleZoneTemplate(true)}
                   >
             ON
           </Button>
           <Button variant="outlined" color={getTemplateButtonColor(false)} sx={{ml: 1}} onClick={() => toggleZoneTemplate(false)}
                   >
             OFF
           </Button>
         </Box>

        <Box sx={{display: "flex", flexDirection: "column", flex: 1, justifyContent: "end"}}>
          <FormHelperText sx={{textAlign: "center", mb: 2}}>{error && "Could not save POI"}</FormHelperText>
          <Button
            variant={"contained"}
            sx={{p: 1.5}}
            onClick={handleSaveZone}
          >
            Save Zone
          </Button>
        </Box>
     </>

  )
}
