import { MapImageLayer } from "components/map/layers/MapImageLayer";
import { ZoneImageLayer } from "components/map/layers/ZoneImageLayer";
import { EdgesLayer } from "components/map/layers/EdgesLayer";
import { NodesLayer } from "components/map/layers/NodesLayer";
import { AMRLayer } from "components/map/layers/AMRLayer";
import { NewZoneLayer } from "components/map/layers/NewZoneLayer";

export const MapContentLayer = (props: {}) => {
  return (
    <>
      <g id="map-image-group">
          <MapImageLayer />
      </g>
      <g id="zone-image-group">
          <ZoneImageLayer />
      </g>
      <g id="map-layers-group">
          <AMRLayer/>
          <NodesLayer/>
          <EdgesLayer/>
          <NewZoneLayer/>
      </g>

    </>
  )
}
