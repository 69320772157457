import { MapContainer } from "./MapContainer";
import { useEffect, useState } from "react";
import { setMaps, setMapLoadNeeded, getCurrentTransition, getMapLoadNeeded } from "store/mapSlice";
import { setZones } from "store/zoneSlice";
import { useAppDispatch } from "store/store";
import { ZoomTransform } from "d3";
import { useSelector } from "react-redux";
import { getAllMaps } from "api/map";
import { getAllZones } from "api/zones";
import { useMutation } from "react-query";

export const FleetManagerMap = () => {
    const mapLoadNeeded = useSelector(getMapLoadNeeded)
    const dispatch = useAppDispatch()

    const { translate, scale } = useSelector(getCurrentTransition)
    const [zIdentity] = useState<ZoomTransform>(new ZoomTransform(scale, translate.x, translate.y))


    useEffect(() => {
        if (mapLoadNeeded) {
            const fetch = async () => {
                try {
                    const mapData = await getAllMaps()
                    dispatch(setMaps(mapData))
                    const zones = await getAllZones()
                    dispatch(setZones(zones))
                    dispatch(setMapLoadNeeded(false))
                } catch (e) {
                    console.error('Fetching zones failed', e)
                }
            }
            fetch()

        }
    }, [mapLoadNeeded])

    return (
        <MapContainer zIdentity={zIdentity}
            absolute />
    )
}
