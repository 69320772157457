import { WSFormatter } from "../../utils/WSFormatter";

interface ControlElementProps {
      onStart: (x: number, y: number) => () => void
      onEnd: () => void
}

export const ControlElement = (props: ControlElementProps) => {
      const { onStart, onEnd } = props;

      return (
            <>
                  <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 187.34 187.34">
                        <circle cx="93.67" cy="93.67" r="91.67" fill="#f6f6f6" stroke="#fff" strokeMiterlimit="10" strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="128.75" y2="178.36" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="178.36" y2="128.75" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="178.36" y2="58.59" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="128.75" y2="8.98" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="58.59" y2="8.98" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="8.98" y2="58.59" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="8.98" y2="128.75" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <line x1="93.67" y1="93.67" x2="58.59" y2="178.36" fill="none" stroke="#fff" strokeMiterlimit="10"
                              strokeWidth="4" />
                        <circle cx="93.67" cy="93.67" r="59.62" fill="#fff" />
                        <circle cx="94.32" cy="93.7" r="37.81" fill="none" stroke="#ededed" strokeMiterlimit="10" strokeWidth="0.75" />
                        <circle cx="94.32" cy="93.7" r="48.97" fill="none" stroke="#ededed" strokeMiterlimit="10" strokeWidth="0.75" />
                        <path
                              d="M19.92,86.12a7.5,7.5,0,0,0-6,3l-3.11-1.8.22,9L18.91,92,16,90.35a5.14,5.14,0,1,1,3.9,8.47v2.4a7.54,7.54,0,0,0,0-15.07Z"
                              fill="#c7c6c6" />
                        <path
                              d="M38.76,37.91l1.13-3.51L31.2,36.51l5.87,6.79,1-3.1a9.43,9.43,0,0,1,7.47,9.21h2.4A11.85,11.85,0,0,0,38.76,37.91Z"
                              fill="#c7c6c6" />
                        <polygon points="97.78 17.39 93.11 9.72 88.44 17.39 91.91 17.39 91.91 30.4 94.31 30.4 94.31 17.39 97.78 17.39"
                              fill="#c7c6c6" />
                        <path d="M155.34,36.37l-8.72-2.11,1.2,3.66A11.83,11.83,0,0,0,138,49.55h2.4a9.42,9.42,0,0,1,8.13-9.32l.94,2.93Z"
                              fill="#c7c6c6" />
                        <path d="M173.74,89.12a7.54,7.54,0,1,0-6,12.09v-2.4a5.15,5.15,0,1,1,3.9-8.48L168.75,92l7.87,4.3.22-9Z"
                              fill="#c7c6c6" />
                        <path
                              d="M147.74,149.78l-1.13,3.51,8.73-2.12-5.87-6.79-1,3.11a9.43,9.43,0,0,1-7.47-9.21h-2.4A11.84,11.84,0,0,0,147.74,149.78Z"
                              fill="#c7c6c6" />
                        <polygon
                              points="88.44 170.82 93.11 178.48 97.78 170.82 94.31 170.82 94.31 157.8 91.91 157.8 91.91 170.82 88.44 170.82"
                              fill="#c7c6c6" />
                        <path d="M30.88,151.46l8.71,2.11-1.2-3.67a11.83,11.83,0,0,0,9.81-11.62H45.8a9.42,9.42,0,0,1-8.13,9.31l-1-2.93Z"
                              fill="#c7c6c6" />
                        <circle cx="94.32" cy="93.7" r="26.17" fill="#b1d183" stroke="#91c251" strokeMiterlimit="10" strokeWidth="2" />
                        <g id="buttons">
                              <path id="up" onMouseDown={onStart(0, WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M114.53,37.8l11.76-27.72a91.35,91.35,0,0,0-64.92,0L72.81,37.8a55.7,55.7,0,0,1,41.72,0Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="right" onMouseDown={onStart(WSFormatter.speed, 0)} onMouseUp={onEnd}
                                    d="M149.26,114.4,177,126.16a91.35,91.35,0,0,0,0-64.92L149.26,72.68a55.7,55.7,0,0,1,0,41.72Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="left" onMouseDown={onStart(-WSFormatter.speed, 0)} onMouseUp={onEnd}
                                    d="M37.81,73,10.09,61.21a91.35,91.35,0,0,0,0,64.92l27.72-11.44a55.7,55.7,0,0,1,0-41.72Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="down" onMouseDown={onStart(0, -WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M73,149.6,61.21,177.32a91.35,91.35,0,0,0,64.92,0L114.69,149.6a55.7,55.7,0,0,1-41.72,0Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="up-left" onMouseDown={onStart(-WSFormatter.speed, WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M68.85,39.38,57.57,11.47a91.39,91.39,0,0,0-45.91,45.9L39.35,68.89a55.74,55.74,0,0,1,29.5-29.51Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="down-left" onMouseDown={onStart(-WSFormatter.speed, -WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M39.56,118.49,11.64,129.78a91.39,91.39,0,0,0,45.91,45.9L69.06,148a55.74,55.74,0,0,1-29.5-29.51Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="up-right" onMouseDown={onStart(WSFormatter.speed, WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M147.83,68.83l27.91-11.28a91.39,91.39,0,0,0-45.9-45.91L118.32,39.33a55.74,55.74,0,0,1,29.51,29.5Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                              <path id="down-right" onMouseDown={onStart(WSFormatter.speed, -WSFormatter.speed)} onMouseUp={onEnd}
                                    d="M118.44,148l11.28,27.91A91.39,91.39,0,0,0,175.63,130l-27.69-11.51a55.77,55.77,0,0,1-29.5,29.5Z"
                                    fill="#b5dcb9" className="svg-path-button" />
                        </g>
                  </svg>
            </>
      )
}
