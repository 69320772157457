import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "store/store";
export interface ConfigState {
  tld: boolean,
  api?: string,
  ws?: string,
  wsLiveState: boolean,
}

const initialState: ConfigState = {
  tld: false,
  wsLiveState: false
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    switchTLD: (state) => {
      state.tld = !state.tld
    },
    editAPIURL: (state, action: PayloadAction<string>) => {
      state.api = action.payload
    },
    editWSURL: (state, action: PayloadAction<string>) => {
      state.ws = action.payload
    },
    setWSLiveState: (state, action: PayloadAction<boolean>) => {
      state.wsLiveState = action.payload
    }
  },
})

export const {
  switchTLD,
  editAPIURL,
  editWSURL,
  setWSLiveState,
} = configSlice.actions

export const getWSLiveState = (state: RootState) => state.config.wsLiveState

export default configSlice.reducer
