import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRobotState, ErrorState, IRobotPosition } from "model/Amr";
import { IRobotConfig } from "model/RobotConfig";
import { WSPosition } from "../utils/WSFormatter";
import { Coordinates } from "model/Map";
import { RootState } from "store/store";
export interface AmrState {
  amrs: IRobotState[]
  amrNames: string[]
  isPlacingAmr: boolean
  isAmrPlaced: boolean
  placingAmrId: string
  robotConfigs: IRobotConfig[]
  goTo: {
    on: boolean,
    position: Coordinates
  }
}

const initialState: AmrState = {
  amrs: [],
  amrNames: [],
  isPlacingAmr: false,
  isAmrPlaced: false,
  placingAmrId: "",
  robotConfigs: [],
  goTo: {
    on: false,
    position: { x: 0, y: 0 }
  }
}

const withPayload = <T,>(func: (state: AmrState, payload: T) => void) => (state: AmrState, action: PayloadAction<T>) => {
  func(state, action.payload)
}


const moveFunctions = {
  moveAMR: withPayload<IRobotPosition>((s, p) => {
    s.amrs.filter((amr) => amr.id === p.id).forEach((amr) => {
      amr.status.location = { x: p.x, y: p.y }
      amr.status.rotation = p.theta;
    })
  }),
  saveRobotState: withPayload<IRobotState>((s, p) => {
    const index = s.amrs.findIndex(amr => amr.name === p.name)
    if (index === -1) {
      s.amrs.push(p)
    } else {
      s.amrs[index] = p
    }
    if (s.amrNames.length != s.amrs.length) {
      s.amrNames = s.amrs.map(amr => amr.id)
    }
  })
}

const robotConfigFunctions = {
  setRobotConfigs: withPayload<IRobotConfig[]>((s, p) => {
    s.robotConfigs = p
  }),
  saveRobotConfig: withPayload<IRobotConfig>((s, p) => {
    s.robotConfigs = s.robotConfigs.map(config => {
      if (config.id === p.id) {
        return p
      }
      return config
    })
  })
}

const placeAmrFunctions = {
  setIsPlacingAmr: withPayload<boolean>((s, p) => {
    s.isPlacingAmr = p
  }),
  setIsAmrPlaced: withPayload<boolean>((s, p) => {
    s.isAmrPlaced = p
  }),
  setPlacingAmrId: withPayload<string>((s, p) => {
    s.placingAmrId = p
  }),
}

export const amrSlice = createSlice({
  name: 'amr',
  initialState,
  reducers: {
    ...placeAmrFunctions,
    ...robotConfigFunctions,
    ...moveFunctions,
  },
})

// Action creators are generated for each case reducer function
export const {
  moveAMR,
  saveRobotState,
  setRobotConfigs,
  setIsPlacingAmr,
  setIsAmrPlaced,
  setPlacingAmrId,
  saveRobotConfig,
} = amrSlice.actions



export const getIsPlacingAmr = (state: RootState): boolean => state.amrs.isPlacingAmr
export const getIsAmrPlaced = (state: RootState): boolean => state.amrs.isAmrPlaced
export const getPlacingAmrId = (state: RootState): string => state.amrs.placingAmrId
export const getAmrNames = (state: RootState): string[] => state.amrs.amrNames

export const getRobotConfigs = (state: RootState): IRobotConfig[] => state.amrs.robotConfigs
export const getAmrs = (state: RootState) => state.amrs.amrs
export default amrSlice.reducer
