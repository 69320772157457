import { List, ListItemText } from "@mui/material";
import { deleteUndockParam, useUndockParams } from "../../../../api/undocking_params";
import { UndockingParams } from "../../../../model/Order";
import { useNavigate } from "react-router-dom";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { AddButton } from "../../../styled/toolbar/AddButton";
import { ListItemWithCRUD } from "../../../styled/list/ListItemWithCRUD";
import { useMutation, useQueryClient } from "react-query";
import { ROUTES } from "../../../../api/routes";

export const UndockingPanel = () => {
  const { data } = useUndockParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const deleteMutation = useMutation(deleteUndockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.undocking.key)
    },
  })

  const handleItemClick = (item: UndockingParams) => {
    return () => {
      navigate(`${item.id}`)
    }
  }

  const handleItemEdit = (itemID?: string) => () => {
    navigate(`${itemID}/edit`)
  }


  const handleItemDelete = (itemID?: string) => () => {
    itemID && deleteMutation.mutate(itemID)
  }

  return (
    <>
      <ToolbarTitle title="Undocking Params" />
      <AddButton title="+ Add new undocking params" />
      <List>
        {data && data.map((item: UndockingParams) =>
          <ListItemWithCRUD key={item.id} dense sx={{ borderRadius: 1 }} onClick={handleItemClick(item)}
            handleEdit={handleItemEdit(item.id)}
            handleDelete={handleItemDelete(item.id)}>
            <ListItemText>
              {item.name}
            </ListItemText>
          </ListItemWithCRUD>)
        }
      </List>
    </>
  )
}
