import { Box, Button, FormHelperText, getBottomNavigationActionUtilityClass } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { ROUTES } from "../../../../api/routes";
import { addUP } from "../../../../store/undockparamsSlice";
import { UndockingParams } from "../../../../model/Order";
import { createUndockParam } from "../../../../api/undocking_params";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { UndockParamsForm } from "./UndockParamsForm";

export const defaultUP: UndockingParams = {
  name: "New Undock Params",
  first_drive: 0,
  second_drive: 0,
  first_rotate: 0,
  second_rotate: 0
}

export const AddUndockingParams = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { currentUP } = useSelector((state: RootState) => state.ups)
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(addUP())
  }, [])

  const createUPMutation = useMutation(createUndockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.undocking.key)
      navigate(-1)
    },
    onError: () => {
      setError(true)
    }
  })

  const handleSaveUP = () => {
    if (currentUP) {
      createUPMutation.mutate({
        up: currentUP
      })
    }

  }

  useEffect(() => {
    setError(false)
  }, [currentUP])

  return (
    <>
      <ToolbarTitle title={"Add undocking parameters "} back={"undockingParams"} />
      <UndockParamsForm />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save undocking params"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ p: 1.5 }}
          onClick={handleSaveUP}
          disabled={currentUP && !currentUP.name}
        >
          Save undock parameters
        </Button>
      </Box>
    </>
  )
}
