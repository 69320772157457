import {
    FormControl,
    FormControlLabel,
    InputLabel,
    SelectChangeEvent,
    Box,
    Button,
    Select,
    MenuItem,
    Typography,
    Checkbox
} from "@mui/material";
import { Search } from "../map/fleetOverlay/Search";
import { useState, useCallback } from "react";
import { useSelector } from 'react-redux';
import { useWebSocket } from "components/hooks/websocket";
import { LiveJobsTable } from "components/liveJobs/LiveJobsTable"
import { SummaryTable } from "components/liveJobs/summaryTable"
import { IJobStatus, PRIORITY } from "model/Jobs";
import { getJobsBySelectedMap } from "store/jobSlice";
import { getAmrNames } from "store/amrSlice";

export const getPrioFromString = (prio: string): PRIORITY => {
    switch (prio) {
        case PRIORITY.HIGH:
            return PRIORITY.HIGH
        case PRIORITY.LOW:
            return PRIORITY.LOW
        default:
            return PRIORITY.MEDIUM
    }
}

export const LiveJobsPage = () => {


    const { sendJob } = useWebSocket()

    const [selectedJob, setSelectedJob] = useState("")
    const [selectedAmr, setSelectedAmr] = useState("0")
    const [selectedPriority, setSelectedPriority] = useState(PRIORITY.MEDIUM)
    const [repeatJob, setRepeatJob] = useState(false)
    const [isBlocking, setBlocking] = useState(false)
    const jobs = useSelector(getJobsBySelectedMap).map(j => j).sort((a, b) => a.name < b.name ? -1 : 1)
    const availableAmrs = useSelector(getAmrNames)

    const handleSendJob = useCallback(() => {
        if (selectedJob !== "") {
            const jobId = jobs[+selectedJob].id
            const amrName = availableAmrs[+selectedAmr - 1]
            const amr = amrName === "Any" ? undefined : amrName
            //
            sendJob(jobId, selectedPriority, repeatJob, isBlocking, amr)
            setSelectedJob("")
            setSelectedAmr("")
            setRepeatJob(false)
        }
    }, [selectedAmr, selectedJob, availableAmrs, repeatJob, selectedPriority, isBlocking])

    const handleSelectJob = useCallback((event: SelectChangeEvent) => {
        setSelectedJob(event.target.value)
    }, [jobs])

    const handleSelectAmr = useCallback((event: SelectChangeEvent) => {
        setSelectedAmr(event.target.value)
    }, [availableAmrs])

    const handleSelectPriority = useCallback((event: SelectChangeEvent) => {
        let prio = getPrioFromString(event.target.value)
        setSelectedPriority(prio)
    }, []);

    const handleBlockingBoxTick = useCallback(() => {
        setBlocking(blocking => !blocking)
    }, []);

    const handleCheckBoxClick = useCallback(() => {
        setRepeatJob(repeatJob => !repeatJob)
    }, []);

    return (
        <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "space-between",
                mb: 2
            }}>

            </Box>
            <Box sx={{ m: 3, display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 2, mr: 6, mt: 0.5 }}>
                    <Typography variant="h6">
                        Live Jobs
                    </Typography>
                    <Box>
                        <Box sx={{ m: 3, display: "flex", flexDirection: "row" }}>

                            <Box
                                sx={{ display: "flex" }}>

                                <FormControl sx={{ width: "250px", paddingRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-helper-label">Job Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={selectedJob}
                                        label="Job Name"
                                        onChange={handleSelectJob}
                                        sx={{ width: "100%", fontSize: 14 }}
                                    >
                                        {jobs.map((job, index) => <MenuItem key={index} value={index}>{job.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box
                                sx={{ display: "flex" }}>

                                <FormControl sx={{ width: "250px", paddingRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-helper-label2">Amr Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={selectedAmr}
                                        label="Amr Name"
                                        onChange={handleSelectAmr}
                                        sx={{ width: "100%", fontSize: 14 }}
                                    >
                                        {["Any", ...availableAmrs].map((amr, index) => <MenuItem key={index} value={index}>{amr}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{ display: "flex" }}>

                                <FormControl sx={{ width: "250px", paddingRight: "20px" }}>
                                    <InputLabel id="demo-simple-select-helper-label3">Priority</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={selectedPriority}
                                        label="PRIORITY"
                                        onChange={handleSelectPriority}
                                        sx={{ width: "100%", fontSize: 14 }}
                                    >
                                        {[PRIORITY.HIGH, PRIORITY.MEDIUM, PRIORITY.LOW].map((priority, index) => <MenuItem key={index} value={priority}>{priority}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ ml: 2, display: "flex", width: "250px", height: "60px" }}>
                                <FormControlLabel label="Repeat" control={<Checkbox checked={repeatJob} disabled={selectedJob === "" || selectedAmr === ""} onChange={handleCheckBoxClick} />} />
                            </Box>
                            <Box sx={{ ml: 2, display: "flex", width: "250px", height: "60px" }}>
                                <FormControlLabel label="Blocking" control={<Checkbox checked={isBlocking} disabled={selectedJob === "" || selectedAmr === ""} onChange={handleBlockingBoxTick} />} />
                            </Box>
                            <Box sx={{ display: "flex", width: "250px", height: "60px" }}>
                                <Button
                                    variant="contained"
                                    disabled={selectedJob === "" || selectedAmr === ""}
                                    sx={{ p: 1.5, width: "100%" }}
                                    onClick={handleSendJob}
                                >
                                    Send Job
                                </Button>
                            </Box>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", width: "30%" }}>
                            <SummaryTable key={"Summary"} />
                        </Box>

                    </Box>

                    <LiveJobsTable key={IJobStatus.WAITING} header="Pending" jobStatus={IJobStatus.WAITING} />
                    <LiveJobsTable key={IJobStatus.RUNNING} header="Running" jobStatus={IJobStatus.RUNNING} />
                    <LiveJobsTable key={IJobStatus.FINISHED} header="Finished" jobStatus={IJobStatus.FINISHED} />
                    <LiveJobsTable key={IJobStatus.FAILED} header="Failed" jobStatus={IJobStatus.FAILED} />
                    <LiveJobsTable key={IJobStatus.CANCELLED} header="Cancelled" jobStatus={IJobStatus.CANCELLED} />
                </Box>
            </Box>
        </Box >
    )
}
