import { useSelector } from "react-redux";
import { useCallback } from "react";
// import {useNavigate } from "react-router-dom";
import { List, ListItemText, Typography } from "@mui/material";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { AddButton } from "components/styled/toolbar/AddButton";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { setSelectedOrder, removeOrder, initNewOrder, initUpdateOrder, getOrdersBySelectedMap, toggleIsBuildingOrder } from "store/orderSlice";
import { useAppDispatch } from "store/store";
import { IOrder } from "model/Orders"
import { deleteOrder } from "api/orders"
import { clearSelectedNode, getNodes } from "store/nodeSlice";
import { useNavigate } from "react-router-dom";

export const OrdersPanel = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const orders = useSelector(getOrdersBySelectedMap).map(j => j).sort((a, b) => a.name < b.name ? -1 : 1)

  const handleItemClick = (order: IOrder) => {
    dispatch(setSelectedOrder(order.id))
    navigate("view");
  }

  const handleItemDelete = async (id: string) => {
    await deleteOrder(id)
    dispatch(removeOrder(id))
  }

  const onClickBuild = useCallback(() => {
    dispatch(initNewOrder())
    dispatch(clearSelectedNode())
    dispatch(toggleIsBuildingOrder(true))
  }, []);

  const handleItemEdit = (order: IOrder) => {
    dispatch(initUpdateOrder(order))
    dispatch(clearSelectedNode())
    dispatch(toggleIsBuildingOrder(true))

    navigate("edit");

  }

  return (
    <>
      <ToolbarTitle title={"Orders"} />
      <AddButton onClickHandler={onClickBuild} title="Build new order" />
      <List>
        {orders.filter(order => !order.name.includes("auto_generated")).map((order, id: number) =>
          <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
            onClick={() => handleItemClick(order)}
            handleEdit={() => handleItemEdit(order)}
            handleDelete={() => handleItemDelete(order.id)}
          >
            <ListItemText>
              <Typography variant="body2">
                {order.name || "Unnamed Order"}
              </Typography>
            </ListItemText>
          </ListItemWithCRUD>)}
      </List>
    </>
  )
}
