import { ROUTES } from "./routes";
import { useQuery } from "react-query";
import { DockingParams, DockPose, ApproachPose } from "../model/Order";
import { DataFormatter } from "../utils/DataFormatter";
import { axiosInstance } from "./axios";

export interface DockParamsResponse {
  _id: string
  name: string,
  dock_pose: DockPose
  approach_pose: ApproachPose
  alignment_pose_distance: number
  dock_forwards: boolean
}

const getDockParams = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.docking.url}`)
  return data.map((dp: DockParamsResponse) => DataFormatter.parseDockParams(dp));
};

export const getDockParam = async (id: string) => {
  const { data } = await axiosInstance.get(`${ROUTES.docking.url}/${id}`)
  return DataFormatter.parseDockParams(data)
};

export function useDockParams() {
  return useQuery(ROUTES.docking.key, getDockParams);
}

export function useDockParam(id: string) {
  return useQuery([ROUTES.docking.key, { id }], () => getDockParam(id), { retry: false });
}

export const createDockParam = async (data: { dp: DockingParams }) => {
  return axiosInstance.post(`${ROUTES.docking.url}`, DataFormatter.formatDockParams(data.dp))
};

export const patchDockParam = async (data: { dp: DockingParams }) => {
  return axiosInstance.patch(`${ROUTES.docking.url}/${data.dp.id}`, DataFormatter.formatDockParams(data.dp))
};

export const deleteDockParam = async (dockParamID: string) => {
  return axiosInstance.delete(`${ROUTES.docking.url}/${dockParamID}`)
};
