import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapConstants } from "constants/MapConstants";
import { RootState } from "store/store"
import { MapInfo, IMap, Coordinates, MapTransition } from "model/Map"
import { ActionStatus, ActionType } from 'model/Actions';

export interface MapState {
  mapLoadNeeded: boolean
  editing: boolean
  isMapping: boolean
  mappingAmrId?: string
  scan: Coordinates[]
  maps: IMap[],
  selectedMapId?: string,
  currentTransition: MapTransition
  mapping: {
    mapInfo?: MapInfo
    transition: MapTransition
  }
}

const initialState: MapState = {
  mapLoadNeeded: true,
  editing: false,
  isMapping: false,
  mappingAmrId: undefined,
  maps: [],
  selectedMapId: undefined,
  scan: [],
  currentTransition: MapConstants.DEFAULT_TRANSITION,
  mapping: {
    transition: MapConstants.DEFAULT_TRANSITION,
  }
}

const withPayload = <T,>(func: (state: MapState, payload: T) => void) => (state: MapState, action: PayloadAction<T>) => {
  func(state, action.payload)
}

const mapFunctions = {
  setSelectedMapId: withPayload<string>((s, p) => {
    s.selectedMapId = p
  }),
  setMaps: withPayload<IMap[]>((s, p) => {
    s.maps = p
  }),
  setMapLoadNeeded: withPayload<boolean>((s, p) => {
    s.mapLoadNeeded = p
  }),
  setIsMapping: withPayload<boolean>((s, p) => {
    s.isMapping = p
  }),
  setMappingAmrId: withPayload<string>((s, p) => {
    s.mappingAmrId = p
  }),
  saveSelectedMapActiveZone: withPayload<string>((s, p) => {
    s.maps.forEach(map => {
      if (map.id === s.selectedMapId) {
        map.activeZoneId = p
      }
    })
  }),

}



export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    ...mapFunctions,
    toggleEditing: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload
    },
    setScan: (state, action: PayloadAction<Coordinates[]>) => {
      state.scan = action.payload
    },
    updateCurrentTransition: (state, action: PayloadAction<{ translation?: Coordinates, scale?: number }>) => {
      if (action.payload.scale) state.currentTransition.scale = action.payload.scale
      if (action.payload.translation) state.currentTransition.translate = action.payload.translation
    },
    resetTransition: (state, action: PayloadAction<void>) => {
      state.currentTransition = MapConstants.DEFAULT_TRANSITION
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  toggleEditing,
  setScan,
  updateCurrentTransition,
  //
  setMappingAmrId,
  setIsMapping,
  //
  setSelectedMapId,
  setMaps,
  setMapLoadNeeded,
  saveSelectedMapActiveZone,
  resetTransition,
} = mapSlice.actions



export const getMapInfo = (state: RootState) => {
  if (state.map.maps && state.map.selectedMapId) {
    const selectedMap = state.map.maps.find(map => map.id === state.map.selectedMapId)
    if (selectedMap) {
      return selectedMap.info
    }
  }
  return
}

export const getActiveZoneId = (state: RootState) => {
  if (state.map.selectedMapId && state.map.maps) {
    const found = state.map.maps.find(map => map.id === state.map.selectedMapId)
    if (found) {
      return found.activeZoneId
    }
  }
  return
}

export const getSelectedMapId = (state: RootState) => state.map.selectedMapId
export const getMapList = (state: RootState) => state.map.maps
export const getSelectedMap = (state: RootState): IMap | undefined => {
  if (state.map.maps && state.map.selectedMapId) {
    return state.map.maps.find(map => map.id === state.map.selectedMapId)
  }
  return
}
export const getCurrentTransition = (state: RootState) => {
  return state.map.currentTransition
}
export const getMapDimensions = (state: RootState) => {

  if (state.map.maps && state.map.selectedMapId) {
    const selectedMap = state.map.maps.find(map => map.id === state.map.selectedMapId)
    if (selectedMap) {
      return {
        width: selectedMap.info.naturalWidth,
        height: selectedMap.info.naturalHeight
      }
    }
  }

  return {
    width: MapConstants.DEFAULT_NATURAL_WIDTH,
    height: MapConstants.DEFAULT_NATURAL_HEIGHT

  }
}

export const getMapName = (mapId: string) => (state: RootState) => {
  if (!state.map.maps) {
    return
  }
  const selectedMap = state.map.maps.find(map => map.id === mapId)
  if (!selectedMap) {
    return
  }
  return selectedMap.mapName
}

export const getPartialMap = (state: RootState) => {
  if (!state.map.isMapping || !state.map.mappingAmrId) {
    return
  }
  const mappingAmr = state.amrs.amrs.find(amr => amr.id === state.map.mappingAmrId)
  if (!mappingAmr) {
    return
  }
  const mapAction = mappingAmr.actionStates.find(action => action.actionType === ActionType.START_MAPPING && action.actionStatus === ActionStatus.RUNNING)
  return mapAction && mapAction.resultDescription
}

export const getMappingAmr = (state: RootState) => {
  if (!state.map.mappingAmrId) {
    return
  }
  return state.amrs.amrs.find(amr => amr.id === state.map.mappingAmrId)
}

export const getMapLoadNeeded = (state: RootState) => {
  return state.map.mapLoadNeeded
}

export const getIsMapping = (state: RootState): boolean => state.map.isMapping

export default mapSlice.reducer
