import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { ValueRow } from "../../../amrs/ValueRow";
import { LocationIconSmall, RotationIconSmall } from "../../../../assets/icons/SmallSvgIcons";
import { useMutation, useQueryClient } from "react-query";
import { deleteUndockParam, useUndockParam } from "../../../../api/undocking_params";
import { ROUTES } from "../../../../api/routes";
import { useEffect } from "react";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { clearCurrentUP, updateCurrentUP } from "../../../../store/undockparamsSlice";
import { useSelector } from "react-redux";

export const UndockingParamsDetails = () => {
  let params = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { currentUP } = useSelector((state: RootState) => state.ups)
  const { isFetchedAfterMount, data: undocking_params } = useUndockParam(params.undockparamID ?? "")

  const deleteUPMutation = useMutation(deleteUndockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.undocking.key)
    }
  })

  useEffect(() => {
    if (isFetchedAfterMount && undocking_params) {
      dispatch(updateCurrentUP({ undockParams: undocking_params }))
    }
    return function cleanup() {
      dispatch(clearCurrentUP())
    };
  }, [undocking_params, isFetchedAfterMount])

  const handleEditUP = () => {
    navigate("edit")
  }

  const handleDeleteUP = () => {
    currentUP && currentUP.id && deleteUPMutation.mutate(currentUP.id)
    navigate(`/fleet/undockingParams`)
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ToolbarTitle title={"Undocking Parameters"} back={"undockingParams"} />
      {
        currentUP && <Box sx={{ height: "100%", display: "flex", flexDirection: "column", mt: 2 }}>
          <Typography variant="body1" fontWeight="700" sx={{ p: 1, pb: 0 }}>
            {currentUP.name}
          </Typography>
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="First Drive"
            value={`${currentUP.first_drive}`} />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Second Drive"
            value={`${currentUP.second_drive}`} />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="First Rotate"
            value={`${currentUP.first_rotate}`} />
          <ValueRow icon={<RotationIconSmall fontSize="small" />} title="Second Rotate"
            value={`${currentUP.second_rotate}`} />

          <Box sx={{
            pt: 2,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end"
          }}>
            <Button variant="outlined" sx={{ mb: 1, borderRadius: 0.5 }} onClick={handleEditUP}>Edit</Button>
            <Button variant="outlined" color="error" sx={{ borderRadius: 0.5 }} onClick={handleDeleteUP}>Delete</Button>
          </Box>
        </Box>
      }
    </Box>
  )
}
