import { getMapDimensions } from "store/mapSlice";
import { getActiveZone } from "store/zoneSlice";

import { useSelector } from "react-redux";
export const ZoneImageLayer = () => {
  const activeZone = useSelector(getActiveZone)
  const { height, width } = useSelector(getMapDimensions)
  if (!activeZone) {
    return (null)
  }
  return (
      <image
              x="0"
              y="0"
              opacity="0.9"
              width={width}
              height={height}
              href={`data:image/png;base64, ${activeZone && activeZone.zoneMapData}`}
      />

  )
}
