import {Box} from "@mui/material";
import {FleetNavigation} from "../../navigation/FleetNavigation";
import {Scale} from "./Scale";
import {useMatch} from "react-router-dom";
import {FleetToolbar} from "./FleetToolbar";

export const FleetOverlay = () => {
  const match = useMatch({path: "fleet", end: true});

  return (
    <Box sx={{display: "flex", p: 2, height: "100%"}}>
      <Box sx={{display: "flex", flexDirection: "column", flex: 3, height: "100%"}}>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "end",
          float: "right"
        }}>
          <Scale/>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "end",
          alignItems: "center"
        }}>
          <FleetNavigation/>
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        flex: match ? 0 : 1,
        maxWidth: 340,
      }}>
        {/*<Fab size="small" color="primary" aria-label="add">*/}
        {/*  <AddIcon/>*/}
        {/*</Fab>*/}
        {!match && <FleetToolbar/>}
      </Box>
    </Box>
  )
}
