import { Box, Button, FormHelperText } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { ROUTES } from "../../../../api/routes";
import { clearCurrentUP, updateCurrentUP } from "../../../../store/undockparamsSlice";
import { patchUndockParam, useUndockParam } from "../../../../api/undocking_params";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { UndockParamsForm } from "./UndockParamsForm";

export const EditUndockingParas = () => {
  const params = useParams();
  const { isFetchedAfterMount, data: undocking_params } = useUndockParam(params.undockparamID ?? "")

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { currentUP } = useSelector((state: RootState) => state.ups)

  const [error, setError] = useState(false);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearCurrentUP())
    };
  }, [])

  useEffect(() => {
    if (isFetchedAfterMount && undocking_params) {
      dispatch(updateCurrentUP({ undockParams: undocking_params }))
    }
  }, [undocking_params, isFetchedAfterMount])

  const patchUPMutation = useMutation(patchUndockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.undocking.key)
      navigate(-1)
    },
    onError: () => {
      setError(true)
    }
  })

  const handleSaveUP = () => {
    if (currentUP)
      patchUPMutation.mutate({ up: currentUP })
  }

  useEffect(() => {
    setError(false)
  }, [currentUP])

  return (
    <>
      <ToolbarTitle title={"Edit undocking parameters"} back={"undockingParams"} />
      <UndockParamsForm />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could update undock parameters"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ mb: 1, borderRadius: 0.5 }}
          onClick={handleSaveUP}
          disabled={currentUP && !currentUP.name}
        >
          Save changes
        </Button>
        <Button
          variant="outlined"
          sx={{ borderRadius: 0.5 }}
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}
