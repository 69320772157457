import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DockingParams } from "../model/Order";
import { defaultDP } from "../components/map/fleetOverlay/docking/AddDockingParams";
import { SwipeVerticalSharp } from '@mui/icons-material';

export interface DPState {
  dps: DockingParams[]
  currentDP?: DockingParams
}

const initialState: DPState = {
  dps: []
}

export const dockparamsSlice = createSlice({
  name: 'docking_params',
  initialState,
  reducers: {
    addDP: (state) => {
      // only use the default task if the current task is undefined 
      // if thats not the case it means we started editing a task before
      if (state.currentDP === undefined) state.currentDP = defaultDP
    },
    updateCurrentDP: (state, action: PayloadAction<{ dockParams: DockingParams, name?: string, x?: number, y?: number, yaw?: number, direct?: number, lateral?: number, angle?: number, dock_forwards?: string, alignment?: number }>) => {
      state.currentDP = action.payload.dockParams
    },
    updateCurrentDPString: (state, action: PayloadAction<{ key: number, value: string }>) => {
      if (state.currentDP) {
        switch (action.payload.key) {
          case 0:
            state.currentDP.name = action.payload.value
            break
          case 1:
            state.currentDP.dock_forwards = action.payload.value === "true"
            break
        }
      }
    },
    updateCurrentDPNumber: (state, action: PayloadAction<{ key: number, value: number }>) => {
      if (state.currentDP) {
        switch (action.payload.key) {
          case 0:
            state.currentDP.dock_pose.x = action.payload.value
            break
          case 1:
            state.currentDP.dock_pose.y = action.payload.value
            break
          case 2:
            state.currentDP.dock_pose.yaw = action.payload.value
            break
          case 3:
            state.currentDP.approach_pose.direct = action.payload.value
            break
          case 4:
            state.currentDP.approach_pose.lateral = action.payload.value
            break
          case 5:
            state.currentDP.approach_pose.angle_degrees = action.payload.value
            break
          case 6:
            state.currentDP.alignment_pose_distance = action.payload.value
            break
        }
      }
    },
    setCurrentDP: (state, action: PayloadAction<DockingParams | undefined>) => {
      state.currentDP = action.payload
    },
    setDPs: (state, action: PayloadAction<DockingParams[]>) => {
      state.dps = action.payload
    },
    clearCurrentDP: (state) => {
      state.currentDP = undefined
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addDP,
  setDPs,
  updateCurrentDP,
  updateCurrentDPString,
  updateCurrentDPNumber,
  setCurrentDP,
  clearCurrentDP
} = dockparamsSlice.actions

export default dockparamsSlice.reducer
