import { ActivityType } from "../components/amrs/CurrentActivityCard";
import { AlertColor } from "@mui/material";
import { ActionType } from "./Actions";

export interface Order {
  id: string
  task: Task
  requestedBy: string
  requestedAt: Date
  progress?: number
  startedAt?: Date
  finishedAt?: Date
  amrID?: string
  status?: ProgressStatus
}

export interface Task {
  id: string
  name: string
  steps: TaskStep[]
  trajectories: (undefined | string)[]
  createdAt?: Date
  duration?: number
  repeat?: number
  hours?: number
  description?: string
}

export enum ProgressStatus {
  REQUESTED,
  RUNNING,
  FAILED,
  COMPLETE
}

export interface TaskInProgress {
  orderID: string
  taskID: string
  requestedAt: Date
  status: ProgressStatus
  currentStep: number
  currentAction?: string
  completedSteps: number
  feedback: string
  amrID?: string
  description?: string
  poses?: Pose[]
  destination?: string
}

export interface TaskStep {
  poi?: PointOfInterest
  stepRefID?: string
  actionType?: ActionType
}



export interface Pose {
  x: number
  y: number
  yaw: number
}

export interface Trajectory {
  id?: string
  poses: Pose[]
  name: string
  version?: number
  mapID: string
}

export interface PointOfInterest {
  id?: string
  name: string
  location: Location
  type: POIType
  rotation: number
  mapID?: string
}


export enum POIType {
  CHARGING = "charging_station",
  PARKING = "parking",
  TROLLEY = "trolley",
  TRAJECTORY = "trajectory",
}

export interface DockingParams {
  id?: string
  name: string
  dock_pose: DockPose
  approach_pose: ApproachPose
  alignment_pose_distance: number
  dock_forwards: boolean
}

export interface DockPose {
  x: number
  y: number
  yaw: number
}

export interface ApproachPose {
  direct: number
  lateral: number
  angle_degrees: number
}

export interface UndockingParams {
  id?: string
  name: string
  first_drive: number
  second_drive: number
  first_rotate: number
  second_rotate: number
}

export interface Location {
  x: number
  y: number
}

export interface AMRActivity {
  type: ActivityType
  title: string
  description?: string
  order?: Order
}

export interface AMRAlert {
  severity: AlertColor,
  message?: string,
  advice?: string,
}
