export interface IRobotConfig {
  id: string,
  robotId: string,
  allocatedMapId?: string,
  posX?: number,
  posY?: number,
  theta?: number
}

export interface IRobotConfigApi {
  _id: string,
  robot_id: string,
  allocated_map_id?: string,
  pos_x?: number,
  pos_y?: number,
  theta?: number
}

export interface INewRobotConfig {
  robotId: string
}

export const mapRobotConfigToApi = (robotConfig: IRobotConfig): IRobotConfigApi =>
({
  _id: robotConfig.id,
  robot_id: robotConfig.robotId,
  allocated_map_id: robotConfig.allocatedMapId,
  pos_x: 0.0,
  pos_y: 0.0,
  theta: 0.0
})

export const mapRobotConfigFromApi = (robotConfig: IRobotConfigApi): IRobotConfig =>
({
  id: robotConfig._id,
  robotId: robotConfig.robot_id,
  allocatedMapId: robotConfig.allocated_map_id
})

export const mapNewRobotConfigToApi = (newRobotConfig: INewRobotConfig): IRobotConfigApi =>
({
  _id: "generated in DB",
  robot_id: newRobotConfig.robotId
})
