import {Link, LinkProps, useMatch, useResolvedPath} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

export const
  CustomLink = ({children, to, ...props}: LinkProps) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname, end: false});
    const theme = useTheme()

    return (
      <Link
        style={{
          color: match ? theme.palette.primary.main : theme.palette.secondary.main,
          textDecoration: "none"
        }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    );
  }
