import { ROUTES } from "./routes";
import { axiosInstance } from "./axios";
import {
  INewRobotConfig,
  IRobotConfig,
  mapRobotConfigToApi,
  mapNewRobotConfigToApi,
  mapRobotConfigFromApi
} from "model/RobotConfig";

// TODO: add zod and schemas to all API message
// Make sure we always return proper TypeScript types
export const getAllRobotConfigs = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.robotConfigs.url}`)
  return data.map(mapRobotConfigFromApi)
};

export const getRobotConfig = async (configId: string) => {
  const { data } = await axiosInstance.get(`${ROUTES.robotConfigs.url}/${configId}`)
  return mapRobotConfigFromApi(data)
}

export const updateRobotConfig = async (robotConfig: IRobotConfig) => {
  const { data } = await axiosInstance.patch(`${ROUTES.robotConfigs.url}/${robotConfig.id}`, mapRobotConfigToApi(robotConfig))
  return data
}



export const createRobotConfig = async (robotConfig: INewRobotConfig) => {
  const { data } = await axiosInstance.post(`${ROUTES.robotConfigs.url}`, mapNewRobotConfigToApi(robotConfig))
  return data
}
