import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IOrder, IOrderNode, IOrderEdge } from "model/Orders";
import { IActionParameter, createDefaultOrderAction, defaultNewAction, INodeAction } from "model/Actions";
import { RootState } from "store/store"
import { getNodes } from './nodeSlice';

interface OrderState {
  orders: IOrder[]
  newOrder?: IOrder
  newOrderAction?: INodeAction
  initialLoad: boolean
  isBuildingOrder: boolean
  selectedOrder?: string
  amrPathStart?: string
  pathingType: string
}

const initialState: OrderState = {
  orders: [],
  newOrder: undefined,
  newOrderAction: undefined,
  initialLoad: false,
  isBuildingOrder: false,
  selectedOrder: undefined,
  amrPathStart: undefined,
  pathingType: "manual"
}


const withPayload = <T,>(func: (state: OrderState, payload: T) => void) => (state: OrderState, action: PayloadAction<T>) => {
  func(state, action.payload)
}


const orderFunctions = {
  addOrder: withPayload<IOrder>((s, p) => {
    s.orders.push(p)
  }),
  updateOrder: withPayload<IOrder>((s, p) => {
    s.orders = s.orders.map(order => {
      if (order.id === p.id) {
        return p
      }
      return order
    })
  }),
  setOrders: withPayload<IOrder[]>((s, p) => {
    s.orders = p
  }),
  removeOrder: withPayload<string>((s, p) => {
    s.orders = s.orders.filter(order => order.id !== p)
  })
}


const newOrderFunctions = {
  initUpdateOrder: withPayload<IOrder>((s, p) => {
    let order = structuredClone(p);
    order.nodes = order.nodes.map(node => {
      if (node.actions.length > 0) {
        s.newOrderAction = node.actions[0]
      }

      node.actions = []
      return node
    })
    s.newOrder = order
  }),
  initNewOrder: withPayload<void>((s, p) => {
    // TODO: move this to a proper createNewOrder function with sane defaults.
    const new_order: IOrder = {
      'id': String(s.orders.length + 1),
      name: "",
      nodes: [],
      edges: []
    }
    s.newOrder = new_order
  }),
  clearNewOrder: withPayload<void>((s, p) => {
    s.newOrder = undefined
  }),
  clearNewOrderNodes: withPayload<void>((s, p) => {
    if (s.newOrder) {
      s.newOrder.nodes = []
      s.newOrder.edges = []
    }
  }),
  addEdgeToOrder: withPayload<string>((s, p) => {
    const newOrderEdge: IOrderEdge = {
      edge_id: p,
      released: true,
      start_node_id: "",
      end_node_id: "",
      actions: []
    }
    if (s.newOrder) {
      s.newOrder.edges.push(newOrderEdge)
    }
  }),
  updateOrderEdge: withPayload<IOrderEdge>((s, p) => {
    if (!s.newOrder) {
      return
    }
    s.newOrder.edges = s.newOrder.edges.map(edge => {
      if (edge.edge_id === p.edge_id) {
        return p
      }
      return edge
    })
  }),
  clearNewOrderAction: withPayload<void>((s, p) => {
    s.newOrderAction = undefined
  }),
  setSingleOrderNode: withPayload<string>((s, p) => {
    const newOrderNode: IOrderNode = {
      node_id: p,
      released: true,
      actions: []
    }
    if (s.newOrder) {
      s.newOrder.edges = []
      s.newOrder.nodes = [newOrderNode]
    }
  }),
  setPathingType: withPayload<string>((s, p) => {
    s.pathingType = p
  }),
  addNodeToOrder: withPayload<string>((s, p) => {
    const newOrderNode: IOrderNode = {
      node_id: p,
      released: true,
      actions: []
    }

    if (s.newOrder) {
      if (s.newOrder.nodes.length === 0) {
        s.newOrder.nodes = [newOrderNode]
      } else {
        s.newOrder.nodes.push(newOrderNode)
      }
    }
  }),
  setAmrAsPathStart: withPayload<string>((s, p) => {
    s.amrPathStart = p
  }),
  removeEdgesFromIndex: withPayload<number>((s, p) => {
    if (s.newOrder) {
      s.newOrder.edges = s.newOrder.edges.slice(0, p)
    }
  }),
  removeNodesFromIndex: withPayload<number>((s, p) => {
    if (s.newOrder) {
      s.newOrder.nodes = s.newOrder.nodes.slice(0, p)
    }
  }),
  updateNewOrder: withPayload<{ 'name'?: string }>((s, p) => {
    if (p.name !== undefined && s.newOrder) s.newOrder.name = p.name
  }),
  updateNodeOfAction: withPayload<string>((s, p) => {
    if (s.newOrder) {
      const oldNodeWithAction = s.newOrder.nodes.find(node => node.actions.length > 0)
      const actions = oldNodeWithAction ? oldNodeWithAction.actions : [defaultNewAction]
      s.newOrder.nodes.forEach(node => {
        if (node.node_id === p) {
          node.actions = actions
        } else {
          node.actions = []
        }
      })
    }
  }),
  setNewOrderAction: withPayload<string>((s, p) => {
    s.newOrderAction = createDefaultOrderAction(p)
    if (s.newOrder && s.newOrder.nodes.length > 1) {
      s.newOrder.nodes = [s.newOrder.nodes[0]]
    }
  }),
  addActionParameter: withPayload<IActionParameter>((s, p) => {
    if (s.newOrder && s.newOrderAction && s.newOrderAction.action_parameters) {
      s.newOrderAction.action_parameters.push(p)
    }
  }),
  popActionParameter: withPayload<number>((s, p) => {
    // Remove Action Parameter at specified index.
    if (s.newOrder && s.newOrderAction && s.newOrderAction.action_parameters) {
      const withoutP = s.newOrderAction.action_parameters.filter((a, idx) => idx !== p)
      s.newOrderAction.action_parameters = withoutP
    }
  }),
  updateActionParameter: withPayload<{ "key"?: string, "value"?: (boolean | number | number[] | string), "actionIndex": number }>((s, p) => {
    if (s.newOrder && s.newOrderAction && s.newOrderAction.action_parameters) {
      if (p.key) s.newOrderAction.action_parameters[p.actionIndex].key = p.key
      if (p.value !== undefined) s.newOrderAction.action_parameters[p.actionIndex].value = p.value
    }
  })
}


const toggleFunctions = {
  toggleIsBuildingOrder: withPayload<boolean>((s, p) => {
    s.isBuildingOrder = p
  })
}

const selectionFunctions = {
  setSelectedOrder: withPayload<string>((s, p) => {
    s.selectedOrder = p
  }),
  clearSelectedOrder: withPayload<void>((s, p) => {
    s.selectedOrder = undefined
  })
}


export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    ...orderFunctions,
    ...selectionFunctions,
    ...newOrderFunctions,
    ...toggleFunctions,
  },
})

// Action creators are generated for each case reducer function
export const {

  // new order
  initUpdateOrder,
  initNewOrder,
  clearNewOrder,
  addNodeToOrder,
  updateNewOrder,
  setNewOrderAction,
  clearNewOrderAction,
  clearNewOrderNodes,
  popActionParameter,
  addActionParameter,
  updateActionParameter,
  updateNodeOfAction,
  addEdgeToOrder,
  removeNodesFromIndex,
  updateOrderEdge,
  removeEdgesFromIndex,
  setAmrAsPathStart,
  setPathingType,
  //
  removeOrder,
  setSingleOrderNode,
  // toggle functions
  toggleIsBuildingOrder,

  //edges
  addOrder,
  setOrders,
  updateOrder,


  //selected functions
  setSelectedOrder,
  clearSelectedOrder,

} = orderSlice.actions

export const getOrders = (state: RootState): IOrder[] => state.orders.orders
export const getOrdersBySelectedMap = (state: RootState): IOrder[] => {
  const nodes = getNodes(state)
  return state.orders.orders.filter(order => (order.nodes.every(node => nodes.some(n => n.id === node.node_id))))
}
export const getIsBuildingOrder = (state: RootState): boolean => state.orders.isBuildingOrder
export const getNewOrder = (state: RootState): (IOrder | undefined) => state.orders.newOrder
export const getNewOrderAction = (state: RootState): (INodeAction | undefined) => state.orders.newOrderAction

/*
{
  if (!state.orders.newOrder) {
    return undefined
  }

  const nodeWithAction = state.orders.newOrder.nodes.find(node => node.actions.length > 0)
  if (nodeWithAction) {
    return {
      node_id: nodeWithAction.node_id,
      action: nodeWithAction.actions[0]
    }
  }
  return undefined
}
*/
export const getNewOrderNodes = (state: RootState): IOrderNode[] => {
  if (!state.orders.newOrder) {
    return []
  }
  return state.orders.newOrder.nodes
}

export const getSelectedOrder = (state: RootState): IOrder | undefined => {
  const found = state.orders.orders.find(order => order.id === state.orders.selectedOrder)
  if (found) {
    return found
  }
  return undefined
}

export const getAmrAsPathStart = (state: RootState) => {
  const amrName = state.orders.amrPathStart
  const amr = state.amrs.amrs.find(amr => amr.name === amrName)
  return amr
}

export const getPathingType = (state: RootState) => state.orders.pathingType

export default orderSlice.reducer
