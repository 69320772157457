import { Box } from "@mui/material";
import * as d3 from "d3";
import { ZoomTransform } from "d3";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "store/store";
import { useSelector } from "react-redux";
import { getMapDimensions, updateCurrentTransition, getCurrentTransition } from "store/mapSlice";
import { MapConstants } from "constants/MapConstants";
import { MapContentLayer } from "components/map/MapContentLayer";
import { getIsAddingZone } from "store/zoneSlice";


interface MapContainerProps {
  zIdentity: ZoomTransform
  absolute?: boolean,
}

export function MapContainer(props: MapContainerProps) {
  const {
    absolute,
    zIdentity,
  } = props;
  const dispatch = useAppDispatch()
  const { width, height } = useSelector(getMapDimensions)
  const { translate, scale } = useSelector(getCurrentTransition)
  const isAddingZone = useSelector(getIsAddingZone)

  const onZoom = (event: { transform: ZoomTransform }) => {
    dispatch(updateCurrentTransition({
      translation: { x: event.transform.x, y: event.transform.y },
      scale: event.transform.k
    }))
  }

  useEffect(() => {
    zoom.transform(d3.select("#svg-container"), zIdentity)
  }, [zIdentity]);

  useEffect(() => {
    if (!isAddingZone) {//@ts-expect-error
      d3.select("#svg-container").call(zoom)
    }
    else {
      //@ts-expect-error
      d3.select("#svg-container").call(remove_zoom)
    }
  }, [isAddingZone]);



  useEffect(() => {
    d3.select("#map-svg")
      .attr("transform", `translate(${translate.x},${translate.y}) scale(${scale})`)
  }, [translate, scale]);

  const zoom = d3.zoom()
    //.scaleExtent([MapConstants.MIN_SCALE, MapConstants.MAX_SCALE])
    //.translateExtent([[0, 0], [(width + 1) / MapConstants.MIN_SCALE, (height +1) / MapConstants.MIN_SCALE ]])
    // where 3336 is the svg width and 4648 is the svg height.
    // .extent([[0, 0],[3336, 4648]])
    .on("zoom", function (event: { transform: ZoomTransform }) {
      onZoom && onZoom(event)
    })

  const remove_zoom = d3.zoom().on("zoom", null)

  const contentLayer = useMemo(() => {
    return <MapContentLayer />
  }, [])

  return (
    <Box
      id={"svg-container"}
      sx={{
        position: absolute ? "absolute" : "relative",
        zIndex: 1,
        transformBox: "fill-box"
      }}
    >
      <svg
        width={width}
        height={height}
        id={"map-svg"}
        transform-origin={"top left"}
        style={{
          transformBox: "fill-box",
          overflow: "visible",
          transformOrigin: "top left",
          boxShadow: "rgba(0, 0, 0, 0.1) 10px 10px 100px 10px"
        }}
      >
        {contentLayer}
      </svg>
    </Box>
  )
}
