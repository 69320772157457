import axios, { AxiosError } from "axios";
import { BASE_URL } from "./routes";
import { store } from "../store/store";
import { showNotification } from "../store/notificationSlice";

function handleRequestError(error: AxiosError) {
  const axiosError = error as AxiosError
  store.dispatch(showNotification({
    text: "An error occured with the backend." as string,
    data: `${axiosError.message} for ${axiosError?.config?.baseURL?.replace(/\/$/, "")}${axiosError?.config?.url}`
   }));
}

export const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/`,
  headers: {
    'Content-Type': 'application/json',
  },
})
axiosInstance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response
  },
  (error) => handleRequestError(error),
)
