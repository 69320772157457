import { Box, Button, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { ROUTES } from "../../../../api/routes";
import { addDP } from "../../../../store/dockparamsSlice";
import { DockingParams } from "../../../../model/Order";
import { createDockParam } from "../../../../api/docking_params";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { DockParamsForm } from "./DockParamsForm";

export const defaultDP: DockingParams = {
  name: "New Dock Params",
  dock_pose: { x: 0, y: 0, yaw: 0.0 },
  approach_pose: {
    lateral: 0.0,
    direct: 0.0,
    angle_degrees: 0.0
  },
  alignment_pose_distance: 0.0,
  dock_forwards: true
}

export const AddDockingParams = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { currentDP } = useSelector((state: RootState) => state.dps)
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(addDP())
  }, [])

  const createDPMutation = useMutation(createDockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.docking.key)
      navigate(-1)
    },
    onError: () => {
      setError(true)
    }
  })

  const handleSaveDP = () => {
    if (currentDP) {
      createDPMutation.mutate({
        dp: currentDP
      })
    }

  }

  useEffect(() => {
    setError(false)
  }, [currentDP])

  return (
    <>
      <ToolbarTitle title={"Add docking parameters "} back={"dockingParams"} />
      <DockParamsForm />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save docking params"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ p: 1.5 }}
          onClick={handleSaveDP}
          disabled={currentDP && !currentDP.name}
        >
          Save dock parameters
        </Button>
      </Box>
    </>
  )
}
