import { Task, TaskInProgress } from "../../model/Order";
import { Column, SimpleStickyTable } from "../styled/table/SimpleStickyTable";
import { SoftShadowCard } from "../styled/SoftShadowCard";
import { useSelector } from "react-redux";
import { getLiveJobsFull } from "store/jobSlice";
import { IJobStatus, ILiveJob, ILiveJobFull } from "model/Jobs";
import { Typography } from "@mui/material";


const jobColumns: Column<any>[] = [
  { id: "status", label: "" },
  { id: "rate", label: "" },
]

export interface Rate {
  status: string,
  rate: string
}

export function SummaryTable() {
  const job_finished = useSelector(getLiveJobsFull(IJobStatus.FINISHED))
  const job_cancelled = useSelector(getLiveJobsFull(IJobStatus.CANCELLED))
  const job_failed = useSelector(getLiveJobsFull(IJobStatus.FAILED))

  const getJobRates = (): Rate[] => {

    const total_orders = job_finished.length + job_failed.length + job_cancelled.length

    if (total_orders === 0)
      return []

    const suc_rate = job_finished.length * 100 / total_orders
    const fail_rate = (job_failed.length + job_cancelled.length) * 100 / total_orders

    const success_rate: Rate = { status: "Sucess rate:", rate: (Number.isInteger(suc_rate) ? suc_rate : suc_rate.toFixed(2)) + "%" }
    const failed_rate: Rate = { status: "Failed rate:", rate: (Number.isInteger(fail_rate) ? fail_rate : fail_rate.toFixed(2)) + "%" }

    const values = [success_rate, failed_rate]
    return values

  }


  return (
    <>
      <Typography sx={{ mt: 4, mb: 1 }} variant="body2" fontWeight="bold">
        KPIs
      </Typography>
      <SoftShadowCard>
        <SimpleStickyTable columns={jobColumns} rows={getJobRates()} noDataLabel={`No current info`} />
      </SoftShadowCard>
    </>
  )
}
