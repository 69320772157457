import {ROUTES} from "./routes";
import {useQuery} from "react-query";
import { axiosInstance } from "./axios";
import { INode, mapNodeFromApi, mapNodeToApi } from "model/Nodes"

export interface NodeResponse {
  _id: string
  x: number,
  y: number,
  name?: string,
  map_id?: string,
  rotation: number
}

export const getAllNodes = async () => {
  const {data} = await axiosInstance.get(`${ROUTES.nodes.url}`)

  return data.map(mapNodeFromApi)
};

export const deleteNode = async (id: string) => {
  try {
    const { data } = await axiosInstance.delete(`${ROUTES.nodes.url}/${id}`)
    return data
  } catch {
    console.error('Deleting of node failed', id)
    return
  }
}

export const getNode = async (id: string) => {
  const {data} = await axiosInstance.get(`${ROUTES.nodes.url}/${id}`)
  return mapNodeFromApi(data)
};

export const createNode = async (node: INode) => {
  // TODO make sure to fetch map id map

  const { data } = await axiosInstance.post(`${ROUTES.nodes.url}`, mapNodeToApi(node))
  return data
};

export const useFetchAllNodes = () => {
  return useQuery(ROUTES.nodes.key, getAllNodes);
}

export const useFetchNode = (id: string) =>  {
  return useQuery([ROUTES.nodes.key, { id }], () => getNode(id), { retry: false });
}

export const updateNode = async(node: INode) =>  {
  const outNode = {
    ...node,
    location:  {
          x: Math.round(node.location.x * 1000) / 1000,
          y: Math.round(node.location.y * 1000) / 1000
        }

  }
  const {data} = await axiosInstance.patch(`${ROUTES.nodes.url}/${node.id}`, mapNodeToApi(outNode))
  return data
}
