import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INode, IEdge, INodeUpdate } from "model/Nodes";
import { RootState } from "store/store"

interface NodeState {
  editing: boolean
  nodes: INode[]
  edges: IEdge[]
  isAddingEdge: boolean
  isAddingNode: boolean
  isPlacingNewNode: boolean
  isNodesLoaded: boolean
  isRemovingEdge: boolean
  newEdges?: IEdge[]
  edgesToRemove?: string[]
  selectedNode?: INode
  newNode?: INode
}


const initialState: NodeState = {
  editing: false,
  nodes: [],
  edges: [],
  isAddingNode: false,
  isAddingEdge: false,
  isPlacingNewNode: false,
  isRemovingEdge: false,
  selectedNode: undefined,
  newNode: undefined,
  edgesToRemove: undefined,
  newEdges: undefined,
  isNodesLoaded: false
}


const withPayload = <T,>(func: (state: NodeState, payload: T) => void) => (state: NodeState, action: PayloadAction<T>) => {
  func(state, action.payload)
}

const edgeFunctions = {
  setEdges: withPayload<IEdge[]>((s, p) => {
    s.edges = p
  }),
  addEdge: withPayload<INode[]>((s, p) => {
    const edgeObj = { "id": String(s.edges.length + 1), node_ids: p.map(node => node.id) }
    if (!s.newEdges) {
      s.newEdges = [edgeObj]
    } else {
      s.newEdges.push(edgeObj)
    }
  }),
  addEdgesToRemove: withPayload<string>((s, p) => {
    if (s.edgesToRemove) {
      s.edgesToRemove.push(p)
    } else {
      s.edgesToRemove = [p]
    }
  }),
  clearNewEdges: withPayload<void>((s, p) => {
    s.newEdges = undefined
  }),
  addEdges: withPayload<IEdge[]>((s, p) => {
    s.edges = [...s.edges, ...p]
  }),
  removeEdges: withPayload<string[]>((s, p) => {
    s.edges = s.edges.filter(edge => !p.includes(edge.id))
  }),
  clearEdgesToRemove: withPayload<void>((s, p) => {
    s.edgesToRemove = undefined
  })

}

const newNodeFunctions = {
  setNewNode: withPayload<INode>((s, p) => {
    s.newNode = p
  }),
  updateNewNode: withPayload<{ rot?: number, x?: number, y?: number, name?: string }>((s, p) => {
    if (s.newNode) {
      //@ts-expect-error
      if ("x" in p) s.newNode.location.x = p.x
      //@ts-expect-error
      if ("y" in p) s.newNode.location.y = p.y
      //@ts-expect-error
      if ("rot" in p) s.newNode.rotation = p.rot
      if ("name" in p) s.newNode.name = p.name
    }
  }),
  addNode: withPayload<INode>((s, p) => {
    s.nodes.push(p)
  }),
  clearNewNode: withPayload<void>((s, p) => {
    s.newNode = undefined
  }),


}

const selectedNodeFunctions = {
  updateSelectedNode: withPayload<INodeUpdate>((s, p) => {
    if (s.selectedNode) {
      if (p.x) s.selectedNode.location.x = p.x
      if (p.y) s.selectedNode.location.y = p.y
      if (p.name) s.selectedNode.name = p.name
    }
  }),
  clearSelectedNode: withPayload<void>((s, p) => {
    s.selectedNode = undefined
  }),
  setNodes: withPayload<INode[]>((s, p) => {
    s.nodes = p
  }),
  setSelectedNode: withPayload<INode | undefined>((s, p) => {
    s.selectedNode = p
  }),
  saveSelectedNode: withPayload<INode>((s, p) => {
    s.nodes = s.nodes.map(node => {
      if (node.id === p.id) {
        return p;
      }
      return node
    })
  }),
  updateNode: withPayload<INode>((s, p) => {
    s.nodes = s.nodes.map(node => node.id === p.id ? p : node)
  }),
  removeNode: withPayload<string>((s, p) => {
    s.nodes = s.nodes.filter(node => node.id !== p)
  })
}


const toggleFunctions = {
  toggleAddingEdge: withPayload<boolean>((s, p) => {
    s.isAddingEdge = p
  }),
  toggleIsAddingNode: withPayload<boolean>((s, p) => {
    s.isAddingNode = p
  }),
  toggleNodeEditing: withPayload<boolean>((s, p) => {
    s.editing = p
  }),
  toggleIsPlacingNewNode: withPayload<boolean>((s, p) => {
    s.isPlacingNewNode = p
  }),
  toggleIsRemovingEdge: withPayload<boolean>((s, p) => {
    s.isRemovingEdge = p
  })
}

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    ...edgeFunctions,
    ...newNodeFunctions,
    ...selectedNodeFunctions,
    ...toggleFunctions,
  },
})

// Action creators are generated for each case reducer function
export const {

  //edges
  addEdge,
  addEdges,
  setEdges,
  clearNewEdges,
  addEdgesToRemove,
  removeEdges,
  clearEdgesToRemove,

  //newnode
  setNewNode,
  updateNewNode,
  addNode,
  clearNewNode,

  //selected node
  setSelectedNode,
  setNodes,
  updateSelectedNode,
  saveSelectedNode,
  clearSelectedNode,
  updateNode,
  removeNode,

  // toggle functions
  toggleNodeEditing,
  toggleIsPlacingNewNode,
  toggleIsAddingNode,
  toggleAddingEdge,
  toggleIsRemovingEdge,

} = nodesSlice.actions

export const getNodes = (state: RootState): INode[] => {
  return state.nodes.nodes.filter(node => node.mapId === state.map.selectedMapId)
}
export const getNewNode = (state: RootState) => state.nodes.newNode
export const getSelectedNode = (state: RootState) => state.nodes.selectedNode
export const getIsEditingNode = (state: RootState) => state.nodes.editing
export const getEdges = (state: RootState) => state.nodes.edges
export const getNumOfNodeEdges = (node: INode | undefined) => (state: RootState) => node && state.nodes.edges.filter(edge => edge.node_ids.includes(node.id)).length
export const getIsAddingEdge = (state: RootState) => state.nodes.isAddingEdge
export const getIsAddingNode = (state: RootState) => state.nodes.isAddingNode
export const getIsPlacingNewNode = (state: RootState) => state.nodes.isPlacingNewNode
export const getIsNodesLoaded = (state: RootState) => state.nodes.isNodesLoaded
export const getIsRemovingEdge = (state: RootState) => state.nodes.isRemovingEdge
export const getEdgesToRemove = (state: RootState) => state.nodes.edgesToRemove


export const getNewEdges = (state: RootState) => {
  if (!state.nodes.newEdges) {
    return
  }

  let nodes = state.nodes.nodes
  let newNode = state.nodes.newNode
  if (state.nodes.isAddingNode && newNode) {
    nodes = [...nodes, newNode]
  }

  return state.nodes.newEdges.map(edge => {
    return {
      'id': edge.id,
      'nodes': nodes.filter(node => edge.node_ids.includes(node.id))
    }
  })
}

export const getEdgeNodes = (state: RootState) => {
  let nodes = state.nodes.nodes.filter(node => node.mapId === state.map.selectedMapId)
  if (state.nodes.selectedNode) {
    let found = state.nodes.nodes.findIndex(node => node['id'] === state.nodes.selectedNode?.id);
    nodes = found ? [...nodes, state.nodes.selectedNode] : nodes

  }
  return state.nodes.edges.map(edge => {
    return {
      'id': edge.id,
      'nodes': nodes.filter(node => edge.node_ids.includes(node.id))
    }
  }).filter(edge => edge.nodes.length > 1)
}


export default nodesSlice.reducer
