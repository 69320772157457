import {z} from "zod";

export const zMapResponse = z.object({
  _id: z.string(),
  map: z.string(),
  map_name: z.string(),
  active_zone_id: z.string().optional(),
  info: z.object({
    width: z.number(),
    height: z.number(),
    origin_x: z.number(),
    origin_y: z.number(),
    resolution: z.number(),
  })
})

export interface IMap {
    id: string,
    map: string,
    mapName: string,
    activeZoneId: string,
    info: MapInfo,
}

export type IMapApi = z.infer<typeof zMapResponse>

export const mapMapToApi = (map: IMap): IMapApi =>
({
    _id: map.id,
    map: map.map,
    map_name: map.mapName,
    active_zone_id: map.activeZoneId,
    info: {
      width: map.info.naturalWidth,
      height: map.info.naturalHeight,
      resolution: map.info.resolution,
      origin_x: map.info.origin.x,
      origin_y: map.info.origin.y,
    }
})

export const mapMapFromApi = (map: IMapApi): IMap =>
({
    id: map._id,
    map: map.map,
    mapName: map.map_name,
    activeZoneId: map.active_zone_id || "",
    info: {
      naturalWidth: map.info.width,
      naturalHeight: map.info.height,
      resolution: map.info.resolution,
      origin: {
        x: map.info.origin_x,
        y: map.info.origin_y
      }
    }
})


export interface Coordinates {
  x: number,
  y: number
}


export interface MapInfo {
  naturalWidth: number,
  naturalHeight: number,
  resolution: number,
  origin: Coordinates
}


export interface MapTransition {
  translate: Coordinates
  scale: number
}
/*

SVG coordinate frame

 ___________ x
|
|
|

y


Map image coordinate frame


y
|
|
|___________ x


mapConfig origin is a vector from the center of the map frame to the bottom
left pixel on the svg.

so a map image with origin shown below would have
origin.x of -3, and origin.y of -1,
______________
|             |
|             |
|             |
|             |
|             |
|      |_     |
_______________




For Rotation, from the robot perspective
+x is 0, and positive rotation is anticlockwise, and in radians

|
|
___  x

On the frontend,
facing upwards is 0
and positive rotation is clockwise
and in degrees

y
|
|
|_____


*/


//  ---
//   /
//  /
// -2.6 -> 90 + 156 -> 246

export const mapOrientationFromRobot = (radians: number) => {
  return (90) - (radians * 180 / Math.PI)
}

export const mapOrientationToRobot = (degrees: number) => {
  return (Math.PI / 2) - (degrees * Math.PI / 180)
}


export const mapMLocationToPixel = (mapConfig: any, location: any) => {
  if (!mapConfig || !mapConfig.resolution || !mapConfig.origin) {
    return {x: 0, y: 0}
  }

  return {
    x: mapMToPixel(mapConfig, location['x'], 'x'),
    y: mapMToPixel(mapConfig, location['y'], 'y')
  }

}

export const mapPixelLocationToM = (mapConfig: any, location: any) => {
  if (!mapConfig || !mapConfig.resolution || !mapConfig.origin) {
    return {x: 0, y: 0}
  }

  return {
    x: mapPixelToM(mapConfig, location['x'], 'x'),
    y: mapPixelToM(mapConfig, location['y'], 'y')
  }


}


export const mapPixelToM = (mapConfig: any, pixelValue: number, axis: string) => {
  if (!mapConfig) {
    return 0.0
  }
  // A pixel value of (0,0) would be a M position:
  const moveToOrigin = (axis: string) => {
    if (axis === 'x') {
      return mapConfig.origin.x
    } else {
      return mapConfig.naturalHeight * mapConfig.resolution + mapConfig.origin.y
    }

  }

  if (axis === 'x') {
    return moveToOrigin(axis) + pixelValue * mapConfig.resolution
  } else {
    return moveToOrigin(axis) - pixelValue * mapConfig.resolution
  }

}

export const mapMToPixel = (mapConfig: any, meterMalue: number, axis: string) => {
  if (!mapConfig) {
    return 0.0
  }
  // A Meter value of (0,0) would be at pixel position:
  const moveToOrigin = (axis: string) => {
    if (axis === 'x') {
      return - mapConfig.origin.x / mapConfig.resolution
    } else {
      return mapConfig.naturalHeight + mapConfig.origin.y / mapConfig.resolution
    }
  }


  if (axis === 'x') {
    return moveToOrigin(axis) + meterMalue / mapConfig.resolution
  } else {
    return moveToOrigin(axis) - meterMalue / mapConfig.resolution
  }
}
