import {Coordinates, IMapApi} from "model/Map";
import {Pose} from "model/Order";
import {MapConstants} from "constants/MapConstants";
import {DataFormatter} from "./DataFormatter";

export const MapFormatter = {
  inverseWidth: (x: number, width: number) => {
    return width - x
  },
  inverseWidthForLocation: (coordinates: Coordinates, width: number) => {
    return {x: MapFormatter.inverseWidth(coordinates.x, width), y: coordinates.y}
  },


  addNodeSVGOffsetX: (value: number) => {
    return value + MapConstants.NODE_SVG_CENTER_X
  },
  removeNodeSVGOffsetX: (value: number) => {
    return value - MapConstants.NODE_SVG_CENTER_X
  },
  addNodeSVGOffsetY: (value: number) => {
    return value + MapConstants.NODE_SVG_CENTER_Y
  },
  removeNodeSVGOffsetY: (value: number) => {
    return value - MapConstants.NODE_SVG_CENTER_Y
  },
  xNodeOffset: MapConstants.NODE_CENTER_OFFSET_X - MapConstants.NODE_SVG_CENTER_X,
  yNodeOffset: MapConstants.NODE_CENTER_OFFSET_Y + MapConstants.NODE_SVG_CENTER_Y,
  getNodeCoordinatesForT: (c1: Coordinates | Pose, c2: Coordinates | Pose) => {
    return {
      x1: (c1.x ?? 0) + MapFormatter.xNodeOffset,
      y1: (c1.y ?? 0) + MapFormatter.yNodeOffset,
      x2: (c2.x ?? 0) + MapFormatter.xNodeOffset,
      y2: (c2.y ?? 0) + MapFormatter.yNodeOffset
    }
  },
  getNodeCoordinateForTX: (x: number, nWidth: number) => {
    return nWidth - ((x ?? 0) + MapFormatter.xNodeOffset)
  },
  getNodeCoordinateForTXEditable: (x: number, nWidth: number) => {
    return nWidth - ((x ?? 0) + MapFormatter.xNodeOffset)
  },
  getNodeCoordinateForTY: (y: number) => {
    return (y ?? 0) + MapFormatter.yNodeOffset
  },



  addPoiSVGOffsetX: (value: number) => {
    return value + MapConstants.POI_SVG_CENTER_X
  },
  removePoiSVGOffsetX: (value: number) => {
    return value - MapConstants.POI_SVG_CENTER_X
  },
  addPoiSVGOffsetY: (value: number) => {
    return value + MapConstants.POI_SVG_CENTER_Y
  },
  removePoiSVGOffsetY: (value: number) => {
    return value - MapConstants.POI_SVG_CENTER_Y
  },
  xPoiOffset: MapConstants.POI_CENTER_OFFSET_X - MapConstants.POI_SVG_CENTER_X,
  yPoiOffset: MapConstants.POI_CENTER_OFFSET_Y + MapConstants.POI_SVG_CENTER_Y,
  getPoiCoordinatesForT: (c1: Coordinates | Pose, c2: Coordinates | Pose) => {
    return {
      x1: (c1.x ?? 0) + MapFormatter.xPoiOffset,
      y1: (c1.y ?? 0) + MapFormatter.yPoiOffset,
      x2: (c2.x ?? 0) + MapFormatter.xPoiOffset,
      y2: (c2.y ?? 0) + MapFormatter.yPoiOffset
    }
  },
  getPoiCoordinateForTX: (x: number, nWidth: number) => {
    return nWidth - ((x ?? 0) + MapFormatter.xPoiOffset)
  },
  getPoiCoordinateForTXEditable: (x: number, nWidth: number) => {
    return nWidth - ((x ?? 0) + MapFormatter.xPoiOffset)
  },
  getPoiCoordinateForTY: (y: number) => {
    return (y ?? 0) + MapFormatter.yPoiOffset
  },
  convertRotation: (angle: number) => {
    return -angle >= 0 ? 0 - angle : 360 - angle
  },
  convertRotationToDisplay: (angle: number) => {
    return angle > 180 ? (360 - angle) : 0 - angle
  },
  getMapCenterOnScreen: (dimensions: { width: number, height: number }, currentTransition: { translate: { x: number, y: number }, scale: number }) => {
    return {
      position: {
        x: (dimensions.width / 2.4 - currentTransition.translate.x) / currentTransition.scale,
        y: (dimensions.height / 2 - currentTransition.translate.y) / currentTransition.scale,
      }
    }
  },
  getScaleInRange: (value: number) => {
    if (value < MapConstants.MIN_SCALE) return MapConstants.MIN_SCALE
    if (value > MapConstants.MAX_SCALE) return MapConstants.MAX_SCALE
    return value
  },
  calcAngleDeg: (p1: Coordinates, p2: Coordinates) => {
    return (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
  },
  getTranslation: (from: string) => {
    if (from == null) {
      return [0, 0]
    }
    let transformPair = from.replace(")", "").split('(');
    if (transformPair.length > 1) {
      transformPair = transformPair[1].split(',');
    }
    return transformPair
  }
}
