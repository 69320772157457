export const MapConstants = {
  ROBOT_SVG_CENTER_X: -25,
  ROBOT_SVG_CENTER_Y: -25,
  POI_R: 100,
  NODE_RADIUS: 50,
  NODE_SVG_CENTER_X: -75,
  NODE_SVG_CENTER_Y: -72,
  NODE_CENTER_OFFSET_X: -65,
  NODE_CENTER_OFFSET_Y: 88,
  POI_SVG_CENTER_X: -75,
  POI_SVG_CENTER_Y: -72,
  POI_CENTER_OFFSET_X: -65,
  POI_CENTER_OFFSET_Y: 88,
  MIN_SCALE: 0.2,
  MAX_SCALE: 5,
  DEFAULT_NATURAL_WIDTH: 5000,
  DEFAULT_NATURAL_HEIGHT: 5000,
  DEFAULT_TRANSITION: {
    translate: {
      x: 0,
      y: 0,
    },
    scale: 0.2
  },
}
