import { IconButtonGroup } from "../styled/SimpleButtonGroup";
import { AMRsIcon, TasksIcon, ZoneIcon } from "../../assets/icons/SvgIcons";
import { Button } from "@mui/material";
import { APP_ROUTES } from "constants/appRoutes";
import { CustomLink } from "./CustomLink";
import MapIcon from '@mui/icons-material/Map';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import { useAppDispatch } from "store/store"
import { clearNewOrder, toggleIsBuildingOrder } from "store/orderSlice"
import {
  clearSelectedNode,
  toggleIsAddingNode,
  toggleIsRemovingEdge,
  clearEdgesToRemove,
  toggleIsPlacingNewNode,
  clearNewEdges
} from "store/nodeSlice"
export const FleetNavigation = () => {
  const dispatch = useAppDispatch()


  const clearOrders = () => {
    dispatch(clearNewOrder())
    dispatch(toggleIsBuildingOrder(false))
  }

  const clearNodeUpdate = () => {
    return 
    dispatch(clearSelectedNode())
    dispatch(toggleIsAddingNode(false))
    dispatch(toggleIsPlacingNewNode(false))
    dispatch(toggleIsRemovingEdge(false))
    dispatch(clearEdgesToRemove())
    dispatch(clearNewEdges())
  }


  return (
    <IconButtonGroup
      variant="text"
      color="secondary"
      aria-label="fleet navigation button group"
    >
      <CustomLink onClick={clearOrders} to={APP_ROUTES.nodes}>
        <Button>
          <ZoneIcon />
          Nodes
        </Button>
      </CustomLink>
      <CustomLink onClick={clearNodeUpdate} to={APP_ROUTES.orders}>
        <Button>
          <TasksIcon />
          Orders
        </Button>
      </CustomLink>
      <CustomLink to={APP_ROUTES.jobs}>
        <Button>
          <PlaylistAddCheckIcon />
          Jobs
        </Button>
      </CustomLink>
      <CustomLink to={APP_ROUTES.amrs}>
        <Button>
          <AMRsIcon />
          AMRs
        </Button>
      </CustomLink>
      <CustomLink to={APP_ROUTES.maps}>
        <Button>
          <MapIcon />
          Maps
        </Button>
      </CustomLink>
      <CustomLink to={APP_ROUTES.zones}>
        <Button>
          <PictureInPictureAltIcon />
          Zones
        </Button>
      </CustomLink>
    </IconButtonGroup>
  )
}
