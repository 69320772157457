import { Task, TaskInProgress } from "../../model/Order";
import { Column, SimpleStickyTable } from "../styled/table/SimpleStickyTable";
import { SoftShadowCard } from "../styled/SoftShadowCard";
import { useSelector } from "react-redux";
import { getLiveJobsFull } from "store/jobSlice";
import { useMemo } from "react";
import { IJobStatus, ILiveJobFull } from "model/Jobs";
import { Typography } from "@mui/material";

const jobColumns: Column<any>[] = [
    { id: "name", label: "Name" },
    { id: "assignedAmr", label: "Assigned AMR" },
    //{ id: "jobStatus", label: "Job Status" },
    { id: "priority", label: "Priority" },
    { id: "isBlocking", label: "Is Blocking" },
    { id: "isRepeating", label: "Is Repeating" },
    { id: "currentOrderName", label: "Current Order" },
    { id: "totalOrders", label: "Total Orders" },
    { id: "currentOrderIndex", label: "Order Index" },
    { id: "currentOrderStatus", label: "Order Status" },
    { id: "buttonAction", label: "Job Action" },
    //{ id: "liveJobId", label: "Live Job Id" }

]

export interface FullTaskInfo {
    task: Task,
    progress: TaskInProgress
}

interface LiveJobsTableProps {
    header: string,
    jobStatus: IJobStatus,
}

export function LiveJobsTable(props: LiveJobsTableProps) {
    const jobs = useSelector(getLiveJobsFull(props.jobStatus))
    const mapStatusToButton = (status: IJobStatus) => {
        switch (status) {
            case IJobStatus.WAITING:
                return "Cancel"
            case IJobStatus.RUNNING:
                return "Abort"
            default:
                return null
        }
    }

    const buttonAction = mapStatusToButton(props.jobStatus)

    const mappedJobs = useMemo(() => {
        return jobs.map((job: ILiveJobFull) => {
            const basePayload = {
                name: job.name,
                assignedAmr: job.assignedAmr || "", // might also be none
                jobStatus: job.jobStatus,
                priority: job.priority,
                isBlocking: job.isBlocking ? "Yes" : "No",
                isRepeating: job.repeatJob ? "Yes" : "No",
                totalOrders: job.orders.length,
                buttonAction: buttonAction,
                liveJobId: job.liveJobId,
                currentOrderName: undefined,
                currentOrderIndex: undefined,
                currentOrderStatus: undefined
            }

            if (job.activeOrderId && job.orders.length > 0) {
                const currentOrderIndex = job.activeOrderIndex
                const currentOrder = job.orders[currentOrderIndex]

                return {
                    ...basePayload,
                    currentOrderName: currentOrder?.name,
                    currentOrderIndex: currentOrderIndex +1,
                    currentOrderStatus: job.activeOrderStatus,
                }
            }

            return basePayload
        })
    }, [jobs])

    return (
        <>
            <Typography sx={{ mt: 4, mb: 1 }} variant="body2" fontWeight="bold">
                {props.header}
            </Typography>
            <SoftShadowCard>
                <SimpleStickyTable columns={jobColumns} rows={mappedJobs} noDataLabel={`No ${props.header} tasks`} />
            </SoftShadowCard>
        </>
    )
}
