import { Coordinates } from "model/Map";
import { Pose } from "../model/Order";

interface WSActivityUpdate {
  robot_id: string,
  charging: boolean
  active_job_id?: string
  active_order_id?: string
}

export interface WSPosition {
  robot_id: string,
  x: number,
  y: number,
  theta: number,
  map_id: string,
}



interface WSBatteryUpdate {
  robot_id: string,
  battery_state_in_percent: number
}

interface WSErrorUpdate {
  robot_id: string,
  msg: string,
  type: string
}

export const WSFormatter = {
  speed: 0.2,
  logError: (e: any) => {
    console.log(e)
  },
  /*
  parseMap: (payload: any, onSuccess?: (update: MapConfig) => void) => {
    try {
      const update = JSON.parse(payload)
      const mapUpdate = {
        mapData: update.map,
        naturalWidth: update.info.width,
        naturalHeight: update.info.height,
        resolution: update.info.resolution
      } as MapConfig
      onSuccess && onSuccess(mapUpdate)
      return mapUpdate
    } catch (e) {
      WSFormatter.logError(e)
      return null
    }
  },
  */
  parsePosition: (payload: any, onSuccess?: (update: WSPosition) => void) => {
    try {
      const update = JSON.parse(payload) as WSPosition
      onSuccess && onSuccess(update)
      return update
    } catch (e) {
      WSFormatter.logError(e)
      return null
    }
  },
  parseActivity: (payload: any, onSuccess?: (update: WSActivityUpdate) => void) => {
    try {
      const update = JSON.parse(JSON.stringify(payload)) as WSActivityUpdate
      onSuccess && onSuccess(update)
      return update
    } catch (e) {
      WSFormatter.logError(e)
      return null
    }
  },
  parseError: (payload: any, onSuccess?: (update: WSActivityUpdate) => void) => {
    try {
      const update = JSON.parse(JSON.stringify(payload)) as WSActivityUpdate
      onSuccess && onSuccess(update)
      return update
    } catch (e) {
      WSFormatter.logError(e)
      return null
    }
  },
  // TODO: reenable this again
  // parseScan: (payload: any, onSuccess?: (update: Coordinates[]) => void) => {
  //   try {
  //     const scanUpdate = JSON.parse(payload).map((point: Coordinates) => {
  //       return { x: point.x, y: point.y }
  //     }) as Coordinates[]
  //     onSuccess && onSuccess(scanUpdate)
  //     return JSON.parse(payload).map((point: Coordinates) => {
  //       return { x: point.x, y: point.y }
  //     }) as Coordinates[]
  //   } catch (e) {
  //     WSFormatter.logError(e)
  //     return null
  //   }
  // },
  parseBattery: (payload: any, onSuccess?: (update: WSBatteryUpdate) => void) => {
    try {
      const update = JSON.parse(JSON.stringify(payload)) as WSBatteryUpdate
      onSuccess && onSuccess(update)
      return update
    } catch (e) {
      WSFormatter.logError(e)
      return null
    }
  },
}
