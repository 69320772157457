import { AMRCard } from "./AMRCard";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAmrs, getRobotConfigs } from "store/amrSlice";
import { getSelectedMapId } from "store/mapSlice";
import { IRobotState } from "model/Amr";
import { IRobotConfig } from "model/RobotConfig";
export const AMRsPanel = () => {
  const amrs = useSelector(getAmrs)
  const robotConfigs = useSelector(getRobotConfigs)
  const selectedMapId = useSelector(getSelectedMapId)
  const configAmrMap = useMemo(() => {
    return amrs.reduce((out: { [index: string]: IRobotConfig }, amr: IRobotState) => {
      const config = robotConfigs.find(config => config.robotId === amr.id)
      if (config) {
        out[amr.id] = config
      }
      return out
    }
      , {})
  }, [amrs, robotConfigs])

  return (
    <>
      <ToolbarTitle title="AMRs" />
      {amrs.map((amr: IRobotState, idx: number) => <AMRCard
        key={idx}
        robotConfig={configAmrMap[amr.id]}
        amr={amr}
        selectedMapId={selectedMapId}
      />
      )}
    </>
  )
}
