import {alpha, Box, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText,} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import { useSelector } from "react-redux";
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import {
  AdministrationIcon,
  DashboardIcon,
  FleetIcon,
  NotificationsIcon,
  TasksIcon
} from "../../assets/icons/SvgIcons";
import {useLocation} from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {CustomLink} from "./CustomLink";
import { getWSLiveState } from "store/configSlice";
const drawerWidth = 208;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: 'initial',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'initial',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },

});

const navItems = [
  {
    label: 'Fleet management',
    icon: <FleetIcon/>,
    route: '/fleet',
  },
  /*
      {
        label: 'AMRs',
        icon: <AMRsIcon/>,
        route: '/amrs',
      },
  */
  {
    label: 'Mapping',
    icon: <DashboardIcon/>,
    route: '/mapping',
  },
  {
    label: 'Live Jobs',
    icon: <TasksIcon/>,
    route: '/jobs',
  },
  {
    label: 'Notifications',
    icon: <NotificationsIcon/>,
    route: '/notifications',
  },
  {
    label: "Administration",
    icon: <AdministrationIcon/>,
    route: '/admin',
  },
]

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    borderRight: "none",
    sm: {
      display: "none"
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    paper: {
      borderRadius: "0px 10px 10px 0px",
      overflow: "initial",
      borderRight: "none"
    },
    docked: {
      overflow: "initial"
    }
  }),
);

export const NavDrawer = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [activeItem, setActiveItem] = useState(0);
  const wsLiveState = useSelector(getWSLiveState);
  const [timeNow, setTimeNow] = useState(new Date())


  let location = useLocation();

  useEffect(() => {
    navItems.forEach((item, index) => {
      if (item.route === location.pathname) {
        setActiveItem(index)
      }
    })
  })

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const hideDrawer = useMemo(() => {
    return location.pathname.includes("/tasks/worker")
  }, [location])

  return (
    <Box sx={{display: hideDrawer ? "none" : "block"}}>
      <Box sx={{display: "flex", alignItems: "start", bgcolor: {xs: 'primary.main'}}}>
        <IconButton
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            minWidth: theme.spacing(3),
            p: 1,
            mt: 3,
            m: 1,
            color: "primary.contrastText",
            display: {
              xs: "block",
              sm: "none"
            }
          }}>
          <MoreVertIcon/>
        </IconButton>
      </Box>

      <Drawer variant="permanent" open={open} elevation={8} sx={{
        display: {
          xs: open ? "block" : "none",
          sm: "block"
        }
      }}
      >
        <ListItem
          sx={{
            mx: 1,
            mt: 2,
            mb: 0,
            p: 1,
            width: "auto",
            cursor: "pointer",
            height: 70,
          }}
        >
          <ListItemIcon sx={{
            minWidth: theme.spacing(3),
            p: 1,
            color: "secondary.main",
          }}>
          </ListItemIcon>
          <Collapse orientation="horizontal" timeout={100} in={open} sx={{p: 0, pt: 2, ml: 0}}>
            {wsLiveState ? <PowerIcon /> : <PowerOffIcon/>} {theme.logo && <img alt="theme-logo" src={theme.logo}/>}
          </Collapse>
        </ListItem>
        <List>
          {navItems.map((item, index) => (
            <CustomLink
              key={item.route + index}
              to={item.route}
            >
              <ListItem sx={{
                mx: 1,
                my: 1,
                p: 1,
                color: index === activeItem ? "primary.main" : "secondary.main",
                bgcolor: index === activeItem ? alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity) : "transparent",
                borderRadius: 1,
                width: "auto",
                cursor: "pointer",
                '&:hover': {
                  color: 'primary.main',
                },
              }}
                        key={item.label}
              >
                <ListItemIcon sx={{
                  minWidth: theme.spacing(3),
                  p: 1,
                  color: index === activeItem ? "primary.main" : "inherit",
                }}>
                  {item.icon}
                </ListItemIcon>
                <Collapse orientation="horizontal" in={open}>
                  <ListItemText primary={item.label} sx={{color: "text.primary", fontSize: 14, ml: 0.25}}
                                disableTypography/>
                </Collapse>
              </ListItem>
            </CustomLink>
          ))}
        </List>
        <IconButton aria-label='toggle sidebar'
                    edge='start'
                    sx={{
                      position: "absolute",
                      top: 35,
                      right: -16,
                      bgcolor: "primary.main",
                      color: "primary.contrastText",
                      '&:hover': {
                        bgcolor: "primary.light",
                      },
                    }}
                    onClick={handleDrawerToggle}
        >
          {!open ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
        </IconButton>
      </Drawer>
    </Box>
  )
}
