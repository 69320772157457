import {Box, Button, IconButton} from "@mui/material";
import {Outlet, useNavigate} from 'react-router-dom'
import {ReactChildren} from "react";
import {SimpleButtonGroup} from "../../styled/SimpleButtonGroup";
import {CloseIcon, HidePanelIcon} from "../../../assets/icons/SvgIcons";
import {SoftShadowCard} from "../../styled/SoftShadowCard";

export const FleetToolbar = (props: { children?: ReactChildren }) => {
  const navigate = useNavigate()

  return (
    <Box sx={{display: "flex", height: "100%", alignItems: "flex-end", zIndex: 10}}>
      <Box sx={{ml: -6, mr: 2}}>
        <SimpleButtonGroup variant="text" color={"secondary"}>
          <Button onClick={() => navigate("")}>
            <HidePanelIcon/>
          </Button>
        </SimpleButtonGroup>
      </Box>
      <SoftShadowCard
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          p: 2,
          overflow: "auto"
        }}>
        <IconButton
          onClick={() => navigate("")}
          sx={{position: "absolute", top: 22, right: 18}} size="small"
        >
          <CloseIcon fontSize="small"/>
        </IconButton>
        <Outlet/>
      </SoftShadowCard>
    </Box>
  )
}
