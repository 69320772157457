import { useSelector} from "react-redux";
import { useEffect, useCallback } from "react";
import { getEdgeNodes, getNewEdges, getEdgesToRemove } from "store/nodeSlice";
import { useTheme } from "@mui/material/styles";
import { getNewOrder } from "store/orderSlice";
import { getMapInfo } from "store/mapSlice";
import { mapMToPixel } from "model/Map";


import * as d3 from "d3";

export const EdgesLayer = () => {
  const edges = useSelector(getEdgeNodes)
  const newEdges = useSelector(getNewEdges)
  const newOrder = useSelector(getNewOrder)
  const edgesToRemove = useSelector(getEdgesToRemove)
  const theme = useTheme()
  const mapInfo = useSelector(getMapInfo)


  const placeX = useCallback((x: number) => {
    return mapMToPixel(mapInfo, x, 'x')
  }, [mapInfo])

  const placeY = useCallback((y: number) => {
    return mapMToPixel(mapInfo, y, 'y')
  }, [mapInfo])

  const setColor = useCallback((d: any) => {
    if (newEdges && newEdges.find(edge => edge.id === d.id)) {
      return theme.palette.success.main
    }


    if (newOrder && newOrder.edges.find(edge => edge.edge_id === d.id )) {
      return theme.palette.error.light
    }

    if (edgesToRemove && edgesToRemove.find(edge => edge === d.id)) {
      return theme.palette.error.main
    }

    return theme.palette.info.main

  }, [edgesToRemove, newOrder, newEdges, edges])


  useEffect( () => {
     d3.select("#edge-layer")
        .selectAll('line')
        .data(edges, (d: any) => d.id)
        .join(enter => enter.append('line')
                            .style("stroke", setColor)
                             .style("stroke-width", 3)
                             .attr('x1', d => placeX(d['nodes'][0]['location']['x']))
                             .attr("y1", d => placeY(d['nodes'][0]['location']['y']))
                             .attr("x2", d => placeX(d['nodes'][1]['location']['x']))
                             .attr("y2", d => placeY(d['nodes'][1]['location']['y'])),
                        update => update.transition().duration(100)
                              .style("stroke", setColor)
                              .attr('x1', d => placeX(d['nodes'][0]['location']['x']))
                              .attr("y1", d => placeY(d['nodes'][0]['location']['y']))
                              .attr("x2", d => placeX(d['nodes'][1]['location']['x']))
                              .attr("y2", d => placeY(d['nodes'][1]['location']['y'])),
                        exit => exit.remove()
              )
  }, [mapInfo, edgesToRemove, newOrder, edges])

  useEffect(() => {
    if (!newEdges) {
      d3.select("#new-edge-layer").selectAll('line').remove()

    } else {

    d3.select("#new-edge-layer")
      .selectAll('line')
      .data(newEdges)
      .join(enter => enter.append('line')
                    .style("stroke", setColor)
                    .style("stroke-width", 3)
                    .attr('x1', d => placeX(d['nodes'][0]['location']['x']))
                    .attr("y1", d => placeY(d['nodes'][0]['location']['y']))
                    .attr("x2", d => placeX(d['nodes'][1]['location']['x']))
                    .attr("y2", d => placeY(d['nodes'][1]['location']['y'])),

             update => update.transition().duration(100)
                     .style("stroke", setColor)
                     .attr('x1', d => placeX(d['nodes'][0]['location']['x']))
                     .attr("y1", d => placeY(d['nodes'][0]['location']['y']))
                     .attr("x2", d => placeX(d['nodes'][1]['location']['x']))
                     .attr("y2", d => placeY(d['nodes'][1]['location']['y'])),
            exit => exit.remove()
      )
     }
  }, [mapInfo, newEdges])


  return (
    <>
      <g id="edge-layer">
      </g>
      <g id="new-edge-layer">
      </g>
    </>
  )
}
