import {useEffect, useRef} from 'react';

export function useInterval(callback: Function, delay: number | null) {
  const savedCallback = useRef<Function>();
  const id = useRef<NodeJS.Timeout>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }

    if (delay !== null) {
      id.current = setInterval(tick, delay);
      return () => id.current && clearInterval(id.current);
    } else {
      // Clear interval
      id.current && clearInterval(id.current);
    }
  }, [delay]);
}
