import {
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { INodeAction } from "model/Actions";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import {
  getSelectedOrder,
  clearSelectedOrder,
} from "store/orderSlice"

export const ViewOrder = () => {
  const selectedOrder = useSelector(getSelectedOrder)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!selectedOrder) {
      navigate('/fleet/orders')
    }
  }, [selectedOrder])


  const onClickHandler = () => {
    dispatch(clearSelectedOrder())
  }

  useEffect(() => {
    return () => {
      dispatch(clearSelectedOrder())
    };
  }, []);


  const orderAction: (INodeAction | null) = useMemo(() => {
    if (!selectedOrder) {
      return null
    }
    const actionNode = selectedOrder.nodes.find(node => node.actions.length > 0)
    if (actionNode) {
      return actionNode.actions[0]
    }
    return null
  }, [selectedOrder])


  const renderActionParamters = (orderAction: any) => {
    if (!orderAction || orderAction.action_parameters === undefined) {
      return
    }
    switch (orderAction.action_type) {
      case 'dock':
        return (
          <>
            {orderAction.action_parameters.map((action_param: any, idx: number) => {
              switch (action_param.key) {
                case "approach_pose_direct_lateral_degree":
                  return (
                    <div key={idx}>
                      Approach Pose
                      <br />
                      <span> Direct {" "}:{" "}  Lateral {" "}:{" "}  Degree</span>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {action_param.value[0]}{" : "}
                        {action_param.value[1]}{" : "}
                        {action_param.value[2]}
                      </Box>
                    </div>
                  )

                case "alignment_distance":
                  return (
                    <div key={idx}>
                      Alignment Distance
                      <br />
                      {action_param.value}
                    </div>
                  )

                case "docking_direction":
                  return (
                    <div key={idx}>
                      Docking Direction{": "}
                      {action_param.value.toLowerCase()}
                    </div>
                  )


                default:
                  return ""
              }
            }
            )
            }
          </>
        )

      case 'undock':
        return (
          <>
            {orderAction.action_parameters.map((action_param: any, idx: number) => (
              <div key={idx}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {action_param.key}{": "}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {action_param.value}
                  </Box>
                </Box>
              </div>
            )
            )}
          </>
        )
      default:
        return
    }
  }

  const renderActionForm = (orderAction: any) => {
    if (!orderAction) {
      return (null)
    }
    return (
      <>
        Action Type{": "} {orderAction.action_type.toLowerCase()}
        {renderActionParamters(orderAction)}
      </>

    )
  }




  return (
    <>
      {selectedOrder && <>
        <ToolbarTitle title={"Order"} onClick={onClickHandler} back="order" />
        <Typography sx={{ mb: 2, mt: 3 }} variant={"body1"}>
          <span>Name {" : "} {selectedOrder.name}{"\n"}</span>
          <br />
          <span> Nodes: {" "} {selectedOrder.nodes.length}</span>
        </Typography>
        {renderActionForm(orderAction)}
      </>
      }
    </>
  )
}
