import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { List, ListItemText, Typography } from "@mui/material";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { AddButton } from "components/styled/toolbar/AddButton";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { useMutation } from "react-query";
import { deleteNode } from "api/nodes";
import { deleteEdges } from "api/edges";
import {
  removeNode,
  getNodes,
  getEdges,
  setSelectedNode,
  setNewNode,
  toggleIsAddingNode,
  clearSelectedNode
} from "store/nodeSlice";
import { useAppDispatch } from "store/store";
import { createNewNode, INode } from "model/Nodes"
import { getSelectedMapId } from "store/mapSlice"

export const NodesPanel = () => {

  const dispatch = useAppDispatch()

  const navigate = useNavigate();
  const nodes = useSelector(getNodes)
  const edges = useSelector(getEdges)
  const { mutateAsync: asyncDeleteNode } = useMutation(deleteNode)
  const { mutateAsync: asyncDeleteEdges } = useMutation(deleteEdges)
  const currentMapId = useSelector(getSelectedMapId)
  const handleItemClick = (node: INode) => {
    navigate("edit");
    dispatch(setSelectedNode(node))
  }

  const handleItemDelete = async (id: string) => {
    await asyncDeleteNode(id)
    dispatch(removeNode(id))
    const edge_ids = edges.filter(edge => edge.node_ids.includes(id))
      .map(e => e.id)
    // This really should be happening somewhere else.
    await asyncDeleteEdges(edge_ids)
    dispatch(clearSelectedNode())
  }


  const handleMouseEnter = (node: INode) => {
    dispatch(setSelectedNode(node))
  }

  const handleMouseExit = (id: string) => {
    dispatch(clearSelectedNode())
  }


  return (
    <>
      <ToolbarTitle title={"Nodes"} />
      <AddButton title="Add new node" />
      <List>
        {nodes.map((node, id) =>
          <ListItemWithCRUD key={node.id} sx={{ pl: 4, borderRadius: 1 }}
            onMouseEnter={() => handleMouseEnter(node)}
            onMouseLeave={() => handleMouseExit(node.id)}
            onClick={() => handleItemClick(node)}
            handleDelete={() => handleItemDelete(node.id)}
          >
            <ListItemText>
              <Typography variant="body2">
                <span style={{ width: "60px", display: "inline-block" }}>{id}{": "} </span>
                <span style={{ width: "60px", display: "inline-block" }}>{Math.round(node.location.x * 10) / 10}{"x"} </span>
                <span style={{ width: "60px", display: "inline-block" }}>{Math.round(node.location.y * 10)/10}{"y"}</span>

              </Typography>
            </ListItemText>
          </ListItemWithCRUD>)}
      </List>
    </>
  )
}
