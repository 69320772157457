import { ROUTES } from "./routes";
import { useQuery } from "react-query";
import { axiosInstance } from "./axios";
import { IJobWithOrder, mapJobWithOrderToApi, mapJobFromApi } from "model/Jobs";

// TODO: add zod and schemas to all API message
// Make sure we always return proper TypeScript types
export const getAllJobs = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.jobs.url}`)
  return data.map(mapJobFromApi)
};

export const getJob = async (id: string) => {
  const { data } = await axiosInstance.get(`${ROUTES.jobs.url}/${id}`)
  return mapJobFromApi(data)
};

export const createJob = async (job: IJobWithOrder) => {
  const { data } = await axiosInstance.post(`${ROUTES.jobs.url}`, mapJobWithOrderToApi(job))
  return data
};

export const updateJob = async (job: IJobWithOrder) => {
  const { data } = await axiosInstance.patch(`${ROUTES.jobs.url}/${job.id}`, mapJobWithOrderToApi(job))
  return data
};

export const useFetchAllJobs = () => {
  return useQuery(ROUTES.jobs.key, getAllJobs);
}

export const useFetchJob = (id: string) => {
  return useQuery([ROUTES.jobs.key, { id }], () => getJob(id), { retry: false });
}

export const deleteJob = async (id: string) => {
  return axiosInstance.delete(`${ROUTES.jobs.url}/${id}`)
};
