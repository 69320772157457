import { v4 as uuidv4 } from 'uuid';

export interface IActionParameter {
  key: string
  value: boolean | string | number | number[] | string[]
}

export interface IActionState {
  actionId: string
  actionType: ActionType
  resultDescription?: string
  actionStatus: ActionStatus
}

export enum ActionStatus {
  WAITING = "WAITING",
  INITIALIZING = "INITIALIZING",
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  FAILED = "FAILED",
}

export enum ActionType {
  PICK = "pick",
  DROP = "drop",
  DOCK = "dock",
  HALT = "halt",
  MANUAL_DRIVE = "manualDrive",
  SET_LIFT_UP = "setLiftUp",
  SET_LIFT_DOWN = "setLiftDown",
  ENABLE_SLS = "enableSls",
  DISABLE_SLS = "disableSls",
  CANCEL_ORDER = "cancelOrder",
  UNDOCK = "undock",
  START_CHARGING = "startCharging",
  STOP_CHARGING = "stopCharging",
  INIT_POSITION = "initPosition",
  SET_MAP = "setMap",

  START_MAPPING = "startMapping",
  STOP_MAPPING = "stopMapping",
}


export interface INodeAction {
  action_id: string
  action_type: string
  blocking_type?: string
  action_parameters?: IActionParameter[]
}

const getDefaultParameters = (actionType: string) => {
  switch(actionType) {
    case 'dock':
      return defaultDockParameters
    case 'undock':
      return defaultUndockParameters
    default:
      return []
  }
}

export const defaultNewAction = {
  "action_type": "Action Type",
  "action_id": uuidv4(),
  "blocking_type": "NONE",
  "action_parameters": []
}

export const createDefaultOrderAction = (actionType: string): INodeAction =>  {
  return {
        "action_id": uuidv4(),
        "blocking_type": "NONE",
        "action_type": actionType,
        "action_parameters": getDefaultParameters(actionType)
   }
}


export const defaultUndockStage = { "key": "drive", "value": '' }
export const defaultUndockParameters = [defaultUndockStage]

// TODO: https://motius.atlassian.net/browse/MVS-3237
export const defaultDockParameters = [
  {
    "key": "approach_pose_direct_lateral_degree",
    "value": ['', '', '']
  },
  {
    "key": "alignment_distance",
    "value": ''
  },
  {
    "key": "dock_angle",
    "value": 0.0
  }
]
