import { Joystick } from "react-joystick-component";
import { Box } from "@mui/material";
import { IJoystickUpdateEvent } from "react-joystick-component/build/lib/Joystick";
import { ControlElement } from "./ControlElement";
import { useState } from "react";
import { useInterval } from "../hooks/useInterval";
import { useWebSocket } from "components/hooks/websocket";
import { useTheme } from "@mui/material/styles";

interface Position {
  x: number,
  y: number
}

const JOYSTICK_SIZE = 70
const MESSAGE_INTERVAL = 200 //ms

export const ManualControl = (props: { robotId: string }) => {
  let { robotId } = props;
  // maintain the last position of joystick to keep continuous stream of messages even if position stays the same
  const [currentPosition, setCurrentPosition] = useState<Position | null>()
  // if interval is null, the timer stops and clears
  const [interval, setInterval] = useState<number | null>(null)
  const { sendManualDriving } = useWebSocket()
  const theme = useTheme()

  const convertIndex = (index: number | null) => {
    return index ? Number((index * 10 / (JOYSTICK_SIZE / 2)).toFixed(3)) : 0
  }

  const handleMove = (event: IJoystickUpdateEvent) => {
    setCurrentPosition({ x: convertIndex(event.x), y: convertIndex(event.y) })
    handleSendCommand(convertIndex(event.x), convertIndex(event.y))
  }

  const handleStart = (event: IJoystickUpdateEvent) => {
    handleStartInterval(convertIndex(event.x), convertIndex(event.y))()
  }

  const handleStartInterval = (x: number, y: number) => () => {
    setInterval(MESSAGE_INTERVAL)
    setCurrentPosition({ x: x, y: y })
    handleSendCommand(x, y)
  }

  const handleStop = () => {
    setInterval(null)
    setCurrentPosition(null)
    handleSendCommand(0, 0)
  }

  const handleSendCommand = (x: number, y: number) => {
    sendManualDriving(-x, y, robotId)
  }

  useInterval(() => {
    if (currentPosition) {
      handleSendCommand(currentPosition.x, currentPosition.y)
    }
  }, interval)

  return (
    <>
      <Box sx={{
        display: "flex",
        height: JOYSTICK_SIZE * 3,
        width: JOYSTICK_SIZE * 3,
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Box sx={{
          position: "absolute", height: JOYSTICK_SIZE * 3,
          width: JOYSTICK_SIZE * 3,
        }}>
          <ControlElement onStart={handleStartInterval} onEnd={handleStop} />
        </Box>
        <Box sx={{ transform: "scale(1)" }}>
          <Joystick
            baseColor={"#FFF"}
            stickColor={theme.palette.primary.main}
            throttle={MESSAGE_INTERVAL / 2}
            move={handleMove}
            stop={handleStop}
            start={handleStart}
            size={JOYSTICK_SIZE}
          />
        </Box>
      </Box>
    </>
  )
}
