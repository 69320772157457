import {Box, List, ListItemText, Typography} from "@mui/material";
import {IOSSwitch} from "../styled/input/Switch";
import {ListItemWithCRUD} from "../styled/list/ListItemWithCRUD";
import {SoftShadowCard} from "../styled/SoftShadowCard";
import {NotificationConfig} from "../pages/NotificationsPage";
import {deleteSubscriber, patchSubscriber, useSettings, useSubscribers} from "../../api/subscription";
import {ROUTES} from "../../api/routes";
import {useMutation, useQueryClient} from "react-query";

export const EmailList = (props: { handleListItemEdit: (item: NotificationConfig) => () => void }) => {
  let {handleListItemEdit} = props;
  const {data: emails, isFetchedAfterMount} = useSubscribers()
  const {data: global} = useSettings()
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries(ROUTES.subscriber.key)
  }

  const patchSubscriptionMutation = useMutation(patchSubscriber, {
    onSuccess: onSuccess
  })

  const deleteSubscriptionMutation = useMutation(deleteSubscriber, {
    onSuccess: onSuccess
  })

  const handleListItemSwitch = (item: NotificationConfig) => (event: React.ChangeEvent<HTMLInputElement>) => {
    patchSubscriptionMutation.mutate({...item, enabled: event.target.checked})
  }

  const handleListItemDelete = (emailToDelete: string) => () => {
    deleteSubscriptionMutation.mutate(emailToDelete)
  }

  return (
    <SoftShadowCard sx={{p: 2, mx: 6, display: "flex"}}>
      {isFetchedAfterMount && <List sx={{width: "100%"}}>
        {emails.length === 0 &&
          <Typography color="secondary" variant="body2" sx={{textAlign: "center"}}>No emails on the list</Typography>}
        {emails.map((item: NotificationConfig) =>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IOSSwitch sx={{mx: 1}} checked={item.enabled} onChange={handleListItemSwitch(item)} disabled={!global}/>
            <ListItemWithCRUD onClick={handleListItemEdit(item)}
                              handleEdit={handleListItemEdit(item)}
                              handleDelete={handleListItemDelete(item.id)}
            >
              <ListItemText primary={item.email}/>
            </ListItemWithCRUD>
          </Box>
        )}
      </List>}
    </SoftShadowCard>
  )
}
