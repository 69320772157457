import {TableCell, TableRow} from "@mui/material";
import {AnyTableData, Column} from "./SimpleStickyTable";
import {Button} from "@mui/material";
import { useWebSocket } from "components/hooks/websocket";

interface SimpleTableRowProps<T extends AnyTableData> {
  columns: Column<T>[]
  data: T
  progress?: boolean
}

export function SimpleTableRow<T extends AnyTableData>(props: SimpleTableRowProps<T>) {
  const { cancelJob } = useWebSocket()
  let {columns, data} = props;
  const handleButtonClicked = () => {
    cancelJob(data.liveJobId)
  }
  return (
    <TableRow hover
              sx={{
                position: "relative",
              }}
              key={data.toString()}
    >
      {columns.map((column) => {
        const value = column.format ? column.format(data) : data[column.id]
        if (column.id === "buttonAction") {
          if (!value) {
            return null
          }
          return (
            <TableCell key={column.id.toString()} align={"left"} >
              <Button variant="contained" color={'warning'} sx={{  mb: 1, borderRadius: 0.5 }} onClick={handleButtonClicked}>{value}</Button>
            </TableCell>
          )
        }

        return (
          <TableCell key={column.id.toString()} align={column.align}>
            {value}
          </TableCell>
        );
      })}
      {


      }


    </TableRow>
  )
}
