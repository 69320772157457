import {ButtonGroup} from "@mui/material";
import {styled} from "@mui/material/styles";

export const SimpleButtonGroup = styled(ButtonGroup)(({theme}) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1.25),
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  zIndex: 10,
  "& .MuiButtonGroup-grouped": {
    padding: theme.spacing(1.5),
    borderRight: "none !important",
    flexDirection: "column",
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
}));

export const IconButtonGroup = styled(SimpleButtonGroup)(({theme}) => ({
  "& .MuiButtonGroup-grouped": {
    zIndex: 10,
    padding: theme.spacing(1.25),
    minWidth: theme.spacing(11),
    borderRight: "none !important",
    flexDirection: "column",
    color: "inherit",
    '&:hover': {
      color: theme.palette.primary.main

    }
  },
}));
