import React, {useEffect} from 'react';
import './App.css';
import {QueryClient, QueryClientProvider} from "react-query";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {defaultTheme, tldTheme} from "./theme/theme";
import {Root} from "./components/navigation/Root";
import {Provider, useSelector} from 'react-redux';
import {persistor, RootState, store} from "./store/store";
import {WebSocketProvider} from "./components/ws/WebSocketContext";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {PersistGate} from "redux-persist/integration/react";
import {axiosInstance} from "./api/axios";

const queryClient = new QueryClient()

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <AppContent/>
          </QueryClientProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
}

function AppContent() {
  const {tld, api} = useSelector((state: RootState) => state.config)

  useEffect(() => {
    if (api) {
      localStorage.setItem('fe-api-url', api)
      axiosInstance.defaults.baseURL = api
    }
  }, [api])

  return (
    <ThemeProvider theme={tld ? tldTheme : defaultTheme}>
      <CssBaseline/>
      <WebSocketProvider>
        <Root/>
      </WebSocketProvider>
    </ThemeProvider>
  );
}

export default App;
