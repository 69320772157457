import {
  Box,
  Button,
  List,
  ListItem,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOrdersBySelectedMap } from "store/orderSlice";
import { getSelectedJob, setEditingJob, toggleIsEditingJob } from "store/jobSlice";
import { IJobWithOrder } from "model/Jobs";
import { useAppDispatch } from "store/store";

import { useSelector } from "react-redux";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";

export const SelectedJob = () => {
  const selectedJob = useSelector(getSelectedJob)
  const orders = useSelector(getOrdersBySelectedMap)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()


  useEffect(() => {
    if (!selectedJob) {
      navigate('/fleet/jobs')
    }
  }, [selectedJob])

  const onBackClickHandler = () => {
    // TODO clear new job. Implement this function
    //dispatch(clearEdgesToRemove())
    //dispatch(clearSelectedNode())
    //dispatch(clearNewEdges())
  }

  const getOrderName = (order: String) => {
    const order_db = orders.filter(order_from_db => order_from_db.id === order)
    if (order_db.length === 0) return ""
    return order_db[0].name
  }

  const handleEditJob = () => {
    if (selectedJob) {
      const ordersInJob = selectedJob.orders.flatMap(selectedOrder => {
        const foundOrder = orders.find(order => order.id == selectedOrder)
        if (!foundOrder) {
          return []
        }
        return [foundOrder]
      })

      const jobToEdit: IJobWithOrder = { id: selectedJob.id, name: selectedJob.name, orders: ordersInJob }
      navigate('/fleet/jobs/edit')
      dispatch(setEditingJob(jobToEdit))
      dispatch(toggleIsEditingJob(true))
    }

  }

  const handleWorkerView = () => {
    if (selectedJob) {
      navigate('/jobs/worker/' + selectedJob.id)
    }

  }

  return (
    <> {selectedJob && <>
      <ToolbarTitle title={"Selected Job"} onClick={onBackClickHandler} back="jobs" />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Name: {selectedJob.name}
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Id: {selectedJob.id}
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Orders
        <br />
        <List>
          {selectedJob.orders && selectedJob.orders.map((order, idx) => <ListItem key={idx}> {getOrderName(order)} {"\n"}</ListItem>)}
        </List>
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <Button
          variant="outlined"
          sx={{ p: 1.5 }}
          onClick={handleEditJob}
        >
          Edit Job
        </Button>
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        </Typography>
        <Button
          variant="contained"
          sx={{ p: 1.5 }}
          onClick={handleWorkerView}
        >
          Worker View
        </Button>
      </Box>


    </>
    }
    </>
  )
}
