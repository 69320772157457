import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import {
  clearNewNode,
  clearNewEdges,
  toggleNodeEditing,
  toggleAddingEdge,
  updateNewNode,
  getNewNode,
  addNode,
  addEdges,
  getNumOfNodeEdges,
  toggleIsAddingNode,
  toggleIsPlacingNewNode,
  getIsAddingNode,
  getIsAddingEdge,
  getIsPlacingNewNode,
  setNewNode,
  getNewEdges,
} from "store/nodeSlice";
import { useMutation } from "react-query";
import { IRenderedEdge, IEdge, createNewNode } from "model/Nodes";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { createNode, getNode } from "api/nodes"
import { createEdges, getEdges } from "api/edges"
import { getAmrs } from "store/amrSlice";
import { getSelectedMapId } from "store/mapSlice"
import { mapOrientationToRobot, mapOrientationFromRobot } from "model/Map";
import { mapPixelLocationToM, mapMToPixel } from "model/Map";


import Divider from '@mui/material/Divider';

export const AddNode = () => {
  const [error] = useState(false);

  const amrs = useSelector(getAmrs)
  const [selectedAMR, setSelectedAMR] = useState("");

  const newEdges = useSelector(getNewEdges)
  const newNode = useSelector(getNewNode)
  const isPlacingNewNode = useSelector(getIsPlacingNewNode)

  const currentMapId = useSelector(getSelectedMapId)

  const edgeCount = useSelector(getNumOfNodeEdges(newNode))
  const isAddingEdge = useSelector(getIsAddingEdge)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { mutateAsync: asyncCreateNode } = useMutation(createNode)
  const { mutateAsync: asyncGetNode } = useMutation(getNode)
  const { mutateAsync: asyncCreateEdges } = useMutation(createEdges)
  const { mutateAsync: asyncGetEdges } = useMutation(getEdges)


  useEffect(() => {
    dispatch(setNewNode(createNewNode(currentMapId)))
    dispatch(toggleIsAddingNode(true))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(toggleIsAddingNode(false))
      dispatch(toggleIsPlacingNewNode(false))
      dispatch(clearNewNode())
    };
  }, []);

  const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(updateNewNode({ name: event.target.value }))
  };


  const handleChangeLocation = (type: ('x' | 'y' | 'rot')) => (event: any) => {
    const val = event.target.value;
    if (type === 'rot') {
        dispatch(updateNewNode({ 'rot': val ? mapOrientationToRobot(val) : undefined}))
    }
    else if (newNode) {
      dispatch(updateNewNode({ [type]: val ? val : undefined}))
    }
  }

  const handleAddEdge = useCallback(() => {
    if (!isPlacingNewNode) {
      dispatch(toggleAddingEdge(true))
    }
  }, [isPlacingNewNode])

  const handlePlaceNode = () => {
    dispatch(toggleIsPlacingNewNode(!isPlacingNewNode))
    setSelectedAMR("")
  }

  const handleSaveNode = async () => {
    let createdNodeId: string = ""
    if (newNode) {
      try {
        createdNodeId = await asyncCreateNode(newNode)
      } catch (error) {
        console.error('Node Creation Failed', error)
      }
    }

    if (newNode && newEdges && createdNodeId) {
      // edges were created with temp node id, needs to be remapped.

      try {
        const edgesWithId = newEdges.map((edge: IRenderedEdge): IEdge =>
        ({
          id: edge.id,
          node_ids: edge.nodes.map(node => node.id === newNode.id ? createdNodeId : node.id)
        })
        )

        const edgeIds = await asyncCreateEdges(edgesWithId)
        const fetchedEdges = await asyncGetEdges(edgeIds)
        dispatch(addEdges(fetchedEdges))
      } catch (error) {
        console.error('Edge Creation failed', error)
      }
    }
    const fetchedNode = await asyncGetNode(createdNodeId)
    dispatch(clearNewEdges())
    dispatch(addNode(fetchedNode))
    dispatch(clearNewNode())
    dispatch(toggleNodeEditing(false));
    dispatch(toggleIsPlacingNewNode(false))
    navigate(-1)
  }

  const onClickHandler = () => {
    dispatch(clearNewNode())
    dispatch(clearNewEdges())
    dispatch(toggleIsAddingNode(false))
    dispatch(toggleIsPlacingNewNode(false))
  }

  const handleSelectAmr = (event: SelectChangeEvent) => {
    setSelectedAMR(event.target.value)
    const amr_pose = getRobotPosition(event.target.value)
    const new_node = createNewNode(currentMapId, amr_pose.x, amr_pose.y, mapOrientationToRobot(amr_pose.rotation))
    dispatch(setNewNode(new_node))
  }

  const getRobotPosition = (robotId: string) => {
    const amr = amrs.filter(amr => amr.id === robotId)
    return { x: amr[0].status.location.x, y: amr[0].status.location.y, rotation: amr[0].status.rotation }
  }

  const renderLocation = useCallback((param: string) => {
    if (!newNode) {
      return undefined
    }
    if (param == 'x' || param == 'y') {
      if (newNode.location[param] === undefined) {
        return undefined
      }

      return Math.round(newNode.location[param] * 100) / 100

    }

    if (param == 'rot') {
      if (newNode.rotation === undefined) {
        return undefined
      }
      return Math.round(mapOrientationFromRobot(newNode.rotation) * 10000 ) / 10000
    }

  },[newNode])
  return (
    <>
      <ToolbarTitle title={"Add node"} onClick={onClickHandler} back="nodes" />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Location
      </Typography>
      X: <FormControl fullWidth error={error}>
        <TextField type="number" value={renderLocation('x')} onChange={handleChangeLocation('x')} />
      </FormControl>
      Y: <FormControl fullWidth error={error}>
        <TextField type="number" value={renderLocation('y')} onChange={handleChangeLocation('y')} />
      </FormControl>
      Rotation:
      <FormControl fullWidth error={error}>
        <TextField type="number" value={renderLocation('rot')} onChange={handleChangeLocation('rot')} />
      </FormControl>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
      </Typography>
      <Button
        variant={isPlacingNewNode ? "contained" : "outlined"}
        sx={{ p: 1.5 }}
        onClick={handlePlaceNode}
      >
        {isPlacingNewNode ? "Click on map" : "Place by Click"}
      </Button>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
      <Divider>
        OR
      </Divider>
      </Typography>
      <Box>

        <FormControl fullWidth sx={{ paddingBottom: "10px" }} >
          <InputLabel id="demo-simple-select-helper-label2">Place from AMR</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper3"
            value={selectedAMR && selectedAMR || ""}
            label="AMR"
            onChange={handleSelectAmr}
            sx={{ fontSize: 14 }}
          >
            {amrs.map((amr) => <MenuItem key={amr.id} value={amr.id}>{amr.id}</MenuItem>)}
          </Select>
        </FormControl>
      </Box>
      <Box>

        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
          Edges
        </Typography>
        {edgeCount}
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end", gap: "10px" }}>
          <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save POI"}</FormHelperText>
          <Button
            variant={isAddingEdge ? "contained" : "outlined"}
            sx={{ p: 1.5 }}
            onClick={handleAddEdge}
          >
            {isAddingEdge ? "Click other node" : "Add Edge"}
          </Button>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
          <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save POI"}</FormHelperText>
          <Button
            variant={isAddingEdge ? "outlined" : "contained"}
            sx={{ p: 1.5 }}
            onClick={handleSaveNode}
          >
            Save Node
          </Button>
        </Box>
      </Box>
    </>





  )
}
