import { WSRobotPosition, WSRobotState, WSActionState } from "model/Websocket";
import { AlertColor } from "@mui/material";
import { mapOrientationFromRobot } from "model/Map";
import { ActionStatus, IActionState } from "model/Actions";
import { ActionType } from "./Actions";


const getRobotColor = (robotId: string) => {
  if (robotId == "bernd") {
    return "blue"
  }
  if (robotId == "gabie") {
    return "pink"
  }
  return "green"
}

export const nullAmr = {
  status:
  {
    rotation: 0,
    location: {
      x: 0,
      y: 0,
    },
  }
}

export enum OperatingMode {
  AUTOMATIC = "AUTOMATIC",
  SEMIAUTOMATIC = "SEMIAUTOMATIC",
  MANUAL = "MANUAL",
  SERVICE = "SERVICE",
  TEACHING = "TEACHING",
  NONE = "NONE",
}

export enum ErrorLevel {
  FATAL = "FATAL",
  WARNING = "WARNING"
}

export interface Error {
  type: string
  error_level: ErrorLevel
}

export interface ErrorState {
  robot_id: string
  error: Error
}

export interface IRobotState {
  id: string,
  name: string,
  serialNumber: string
  status: {
    location: { x: number, y: number },
    rotation: number,
    operationMode: OperatingMode,
    positionInitialized?: boolean,
    localizationScore?: number,
    batteryStateInPercent?: number,
    errors: Error[]
  },
  actionStates: IActionState[],
  lastMessageTimestamp: number,
  robotColor: string,
}

export interface IRobotPosition {
  id: string,
  x: number,
  y: number,
  theta: number,
  mapId: string,
  localizationScore: string,
  positionInitialized: boolean
}

export const mapRobotPositionFromWS = (position: WSRobotPosition, robot_id: string): IRobotPosition =>
({
  id: robot_id,
  x: position.x,
  y: position.y,
  theta: mapOrientationFromRobot(position.theta),
  mapId: position.map_id,
  localizationScore: position.localization_score,
  positionInitialized: position.position_initialized
})

export const mapActionStateFromWS = (wsActionState: WSActionState): IActionState =>
({
  actionId: wsActionState.action_id,
  actionType: wsActionState.action_type as ActionType,
  actionStatus: wsActionState.action_status as ActionStatus,
  resultDescription: wsActionState.result_description
})

export const mapRobotStateFromWS = (robotState: WSRobotState): IRobotState => {
  let errors = robotState.error
  if (robotState.battery_state_in_percent < 10.0) //TODO: (javier) define battery thershold properly
  {
    const battery_error: Error = { error_level: ErrorLevel.FATAL, type: "BATTERY_LEVEL_IS_TOO_LOW" }
    errors.push(battery_error)
  }

  const failed_actions = robotState.action_states.filter(action => action.action_status === ActionStatus.FAILED)

  failed_actions.map(action => {
    const action_error: Error = { error_level: ErrorLevel.WARNING, type: action.action_type + "_ACTION_FAILED" }
    errors.push(action_error)
  })

  return {
    id: robotState.robot_id,
    name: robotState.robot_id,
    serialNumber: robotState.robot_id,
    status: {
      location: { x: robotState.position.x, y: robotState.position.y },
      rotation: mapOrientationFromRobot(robotState.position.theta),
      positionInitialized: robotState.position.position_initialized,
      localizationScore: robotState.position.localization_score,
      operationMode: robotState.operation_mode,
      batteryStateInPercent: robotState.battery_state_in_percent,
      errors: errors
    },
    actionStates: robotState.action_states.map(mapActionStateFromWS),
    lastMessageTimestamp: (new Date()).valueOf(),
    robotColor: getRobotColor(robotState.robot_id)
  }
}

export const getErrorState = (robot_id: string, error: Error): ErrorState => {
  return {
    robot_id: robot_id,
    error: error
  }
}
