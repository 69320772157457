import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const AddButton = (props: { title: string, onClickHandler?: () => void }) => {
  const navigate = useNavigate()


  const onClick = () => {
    if (props.onClickHandler) {
      props.onClickHandler();
    }
    navigate("add");
  }


  return (
    <Box sx={{ m: 1, mt: 2, display: "flex", flexDirection: "column" }}>
      <Button onClick={onClick}
        variant="outlined"
        sx={{ borderRadius: 0.5 }}
      >
        {props.title}
      </Button>
    </Box>
  )
}
