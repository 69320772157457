import { getSelectedMap, getMapDimensions } from "store/mapSlice";
import { useSelector } from "react-redux";
export const MapImageLayer = () => {
  const selectedMap = useSelector(getSelectedMap)
  const { height, width } = useSelector(getMapDimensions)
  if (!selectedMap) {
    return (null)
  }
  return (
      <image
              x="0"
              y="0"
              width={width}
              height={height}
              href={`data:image/png;base64, ${selectedMap && selectedMap.map}`}
      />

  )
}
