import { Box, Card, FormGroup, IconButton, Typography, LinearProgress } from "@mui/material";
import {
    ChargingIconSmall,
    LocationIcon,
    ManualControlIcon,
} from "assets/icons/SvgIcons";
import { useState, useMemo, useCallback, useEffect } from "react";
import HeightIcon from '@mui/icons-material/Height';
import { ManualControl } from "components/manualControl/ManualControl";
import { BigIconButton } from "components/styled/buttons/BigIconButton";
import { ErrorLevel, IRobotState, OperatingMode } from "model/Amr";
import { useMutation } from "react-query";
import { IRobotConfig } from "model/RobotConfig";
import { updateRobotConfig, createRobotConfig, getAllRobotConfigs } from "api/robotConfig";
import { setIsPlacingAmr, saveRobotConfig, setPlacingAmrId, setRobotConfigs } from "store/amrSlice";
import { useAppDispatch } from "store/store";
import { getRunningJobByRobot } from "store/jobSlice";
import ButtonGroup from '@mui/material/ButtonGroup';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AddLocationIcon from '@mui/icons-material/Map';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MapIcon from '@mui/icons-material/Map';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { getMapName } from "store/mapSlice"
import { useSelector } from "react-redux";
import { IOSSwitch } from "components/styled/input/Switch";
import { useWebSocket } from "components/hooks/websocket";
import { DataFormatter } from "utils/DataFormatter"
import { toTitleCase } from "../../../utils/text";
import DirectionsRailwayFilledIcon from '@mui/icons-material/DirectionsRailwayFilled';
import { ActionStatus } from "model/Actions";
import AttractionsIcon from '@mui/icons-material/Attractions';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EvStationIcon from '@mui/icons-material/EvStation';

interface CardProps {
    amr: IRobotState,
    robotConfig?: IRobotConfig,
    selectedMapId?: string,
}

export const AMRCard = (props: CardProps) => {
    let { amr, robotConfig, selectedMapId } = props;
    const dispatch = useAppDispatch()
    const mapName = useSelector(getMapName(robotConfig && robotConfig.allocatedMapId ? robotConfig.allocatedMapId : ""))
    const newMapName = useSelector(getMapName(selectedMapId ? selectedMapId : ""))
    const [manualControl, setManualControl] = useState(false)
    const [liveStateColor, setLiveStateColor] = useState("yellow")
    const [secondsSinceUpdate, setSecondsSinceUpdate] = useState(-1)
    const [sls, setSls] = useState(false)
    const [lift, setLift] = useState(false)
    const [homed, homeLift] = useState(false)
    const [timeCallback, setTimeCallback] = useState<Date>(new Date());
    const { sendOperateLift, sendHomeLift, sendOperateSafety, sendSetMap, sendRobotToCharge } = useWebSocket()
    const [showErrors, setShowErrors] = useState(false)
    const [showOrders, setShowOrders] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const { mutateAsync: asyncUpdateRobotConfig } = useMutation(updateRobotConfig)
    const { mutateAsync: asyncCreateRobotConfig } = useMutation(createRobotConfig)
    const runningJob = useSelector(getRunningJobByRobot(amr.id))

    useEffect(() => {
        const diff = Math.abs((timeCallback.valueOf() - amr.lastMessageTimestamp) / 1000)
        setSecondsSinceUpdate(diff)
        if (diff < 3.0 && liveStateColor !== "green") {
            setLiveStateColor("green")
        }

        if (diff > 10.0 && liveStateColor !== "red") {
            setLiveStateColor("red")
        } else if (diff > 3.0 && diff < 10.0 && liveStateColor !== "yellow") {
            setLiveStateColor("yellow")
        }
    }, [timeCallback, amr])

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeCallback(new Date())
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleToggleManualControl = () => {
        setManualControl(!manualControl)
    }

    const handleToggleShowErrors = () => {
        setShowErrors(!showErrors)
    }

    const handleToggleShowOrders = () => {
        setShowOrders(!showOrders)
    }

    const handleToggleShowActions = () => {
        setShowActions(!showActions)
    }


    const getAllocatedMapStatus = useCallback((robotConfig: (IRobotConfig | undefined)) => {
        if (!robotConfig) {
            return "No robot config"
        }

        if (!robotConfig.allocatedMapId) {
            return "No map assigned"
        }
        return (
            <>
                {mapName}
            </>
        )
    }, [robotConfig, mapName])


    const renderBatteryState = (amr: IRobotState) => {
        if (!amr.status.batteryStateInPercent) {
            return "-%"
        }
        return `${Math.round(amr.status.batteryStateInPercent * 100) / 100}%`
    }
    const handleSetCurrentMap = useCallback(() => {
        const doUpdate = async () => {
            if (selectedMapId && robotConfig) {
                const configID = await asyncUpdateRobotConfig(
                    {
                        id: robotConfig.id,
                        robotId: amr.id,
                        allocatedMapId: selectedMapId,
                    })
                dispatch(saveRobotConfig(configID))
                sendSetMap(amr.id, newMapName ? newMapName : "")

            } else {
                console.error(`Failed setting map id: ${selectedMapId} to robot`)
            }

        }
        doUpdate()
    }, [robotConfig, selectedMapId])


    const handleHomeLift = async (id: string) => {
      sendHomeLift(id)
    }


    const createConfiguration = async (id: string) => {

        await asyncCreateRobotConfig(
            { robotId: id, })

        const robotConfigs = await getAllRobotConfigs()
        dispatch(setRobotConfigs(robotConfigs))
    }

    const handleSetLocalize = (id: string) => {
        dispatch(setIsPlacingAmr(true))
        dispatch(setPlacingAmrId(id))
    }

    const renderLocalizationState = (amr: IRobotState) => {
        const localizationScore = amr && amr.status && amr.status.localizationScore && amr.status.localizationScore
        const hasSetmap = amr.actionStates.find(action => action.actionType === "setMap")
        const hasInitPosition = amr.actionStates.find(action => action.actionType === "initPosition")

        if (hasSetmap && hasSetmap.actionStatus === ActionStatus.RUNNING) {
            return "Setting Map... "
        }
        if (localizationScore === -1.0) {
            return "Map not set"
        }
        if (hasInitPosition && hasInitPosition.actionStatus === ActionStatus.RUNNING) {
            return "Localizing..."
        }
        //if (hasInitPosition && hasInitPosition.actionStatus == ActionStatus.FAILED) {
        //  return "Failed to Localize"
        //}

        if (amr.status.localizationScore !== 1.0 || !amr.status.positionInitialized) {
            return "Not Localized"
        }
        return `${Math.round(amr.status.location.x)}x ${Math.round(amr.status.location.y)}y ${Math.round(amr.status.rotation)}t`
    }

    const handleSLSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSls(event.target.checked);
        sendOperateSafety(event.target.checked, amr.id)
    };

    const handleLiftChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLift(event.target.checked);
        sendOperateLift(event.target.checked, amr.id)

    };

    const running_actions = useMemo(() => {
        return amr.actionStates.filter(action => action.actionStatus === ActionStatus.RUNNING)
    }, [amr])

    const getRunningActions = useCallback((amr: IRobotState) => {
        return <Box>{running_actions.map(action =>
            <Box sx={{ pl: 2, display: "flex", justifyContent: "start", alignItems: "center" }}>
                <Typography variant="body2" sx={{ p: 1 }}>
                    {action.actionType}
                </Typography>
            </Box>
        )}</Box>
    }, [running_actions])

    const getErrorLevel = () => {
        if (amr.status.errors) {
            if (amr.status.errors.length === 0)
                return "OK"
            else {
                let errorLevel = amr.status.errors.find(error => error.error_level === ErrorLevel.FATAL)
                if (errorLevel) return 'Fatal'
                else return 'Warning'
            }
        }
        else
            return 'OK'
    }

    const getIconColor = () => {
        return "black"
        /*
        const errorLevel = getErrorLevel()
        if (errorLevel === "Fatal") {
          return "red"
        }
        if (errorLevel === "Warning") {
          return "yellow"
        }

        return "green"
        */
    }

    const renderRobotColor = useCallback(() => {
        return <DirectionsRailwayFilledIcon style={{ color: amr.robotColor }} />
    }, [amr])

    const renderLiveState = useCallback(() => {
        if (secondsSinceUpdate < 2.0) {
            return "Less than two seconds ago"
        } else {
            return `${secondsSinceUpdate} seconds ago`
        }

    }, [liveStateColor, secondsSinceUpdate])


    const renderOrderToggle = useCallback(() => {
        if (runningJob) {
            return (
                <IconButton>
                    {showOrders ? <ExpandLessIcon onClick={handleToggleShowOrders} /> : <ExpandMoreIcon onClick={handleToggleShowOrders} />}
                </IconButton>
            )
        }
        return
    }, [runningJob, showOrders])


    const renderActionToggle = useCallback(() => {
        if (running_actions.length > 0) {
            return (
                <IconButton>
                    {showActions ? <ExpandLessIcon onClick={handleToggleShowActions} /> : <ExpandMoreIcon onClick={handleToggleShowActions} />}
                </IconButton>)
        }
        return
    }, [running_actions, showActions])

    const handleSendToCharge = useCallback(() => {
        sendRobotToCharge(amr.id);
    }, [amr.id])

    return (
        <Card sx={{
            p: 2,
            m: 1,
            display: "flex",
            flexDirection: "column",
            height: "auto",
            overflow: "visible",
            justifyContent: "space-between",
            border: "1px solid #eee"
        }}
            elevation={0}
        >
            <Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', alignItems: "center", mb: 1 }}>
                        {renderRobotColor()}
                        {" "}
                        <Typography sx={{ pr: 1, pl: 1 }} fontWeight="bold" component={"span"}>{amr.id}</Typography>
                        <ChargingIconSmall fontSize="small" />
                        <Typography variant="body2" sx={{ p: 1 }}>
                            {renderBatteryState(amr)}
                        </Typography>

                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <MonitorHeartIcon fontSize="small" />
                    <Typography variant="body2" sx={{ p: 1 }}>
                        {renderLiveState()}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <LocationIcon fontSize="small" />
                    <Typography variant="body2" sx={{ p: 1 }}>
                        {renderLocalizationState(amr)}
                    </Typography>
                </Box>

                {amr.status.errors &&
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", tooltip: "Exapnd" }} >
                            <ErrorOutlineIcon fontSize='small' style={{ color: getIconColor() }} />
                            {amr.status.errors.length === 0 ?
                                <Typography variant="body2" sx={{ p: 1 }} >
                                    {getErrorLevel()}
                                </Typography> :
                                <>
                                    <Typography variant="body2" sx={{ p: 1 }} >
                                        {getErrorLevel()}
                                    </Typography>
                                    <IconButton>
                                        {!showErrors ? <ExpandMoreIcon onClick={handleToggleShowErrors} /> : <ExpandLessIcon onClick={handleToggleShowErrors} />}
                                    </IconButton>
                                </>
                            }
                        </Box>

                        {showErrors && amr.status.errors.map(error => <Box sx={{ pl: 2, display: "flex", justifyContent: "start", alignItems: "center" }}>
                            {error.error_level === ErrorLevel.WARNING ? <WarningIcon style={{ fontSize: 'x-small', color: 'yellow' }} /> :
                                <ErrorIcon style={{ fontSize: 'x-small', color: 'red' }} />}
                            <Typography variant="body2" sx={{ p: 1 }}>
                                {DataFormatter.formatErrorString(error.type)}
                            </Typography>
                        </Box>)}
                    </Box>
                }
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AssignmentIcon sx={{ width: 14, height: 14 }} />
                        <Typography variant="body2" sx={{ p: 1 }}>{runningJob ? "Executing Task" : "Idle"}</Typography>
                        {renderOrderToggle()}
                    </Box>
                    {runningJob && showOrders &&
                        <><LinearProgress
                            variant="determinate"
                            value={(runningJob.orders.findIndex(order => order.id === runningJob.activeOrderId) + 1) * 100 / runningJob.orders.length}
                            sx={{ m: 2, mt: 1, height: 8, borderRadius: 0.25 }}
                        />
                            {runningJob?.orders.map(order =>
                                <Box sx={{ pl: 2, display: "flex", justifyContent: "start", alignItems: "center" }}>
                                    <Typography variant="body2" fontWeight={order.id === runningJob.activeOrderId ? "bold" : "normal "} sx={{ p: 1 }}>
                                        {order.name}
                                    </Typography>
                                </Box>
                            )}</>
                    }
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AttractionsIcon sx={{ width: 14, height: 14 }} />
                    <Typography variant="body2" sx={{ p: 1 }}>{running_actions.length > 0 ? "Executing Action" : "No actions running"}</Typography>
                    {renderActionToggle()}

                </Box>
                {showActions && running_actions.length > 0 && getRunningActions(amr)}
                <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <MapIcon fontSize="small" />
                    <Typography variant="body2" sx={{ p: 1 }}>
                        {getAllocatedMapStatus(robotConfig)}
                    </Typography>
                </Box>
                {amr.status.operationMode && <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                    <AutoModeIcon fontSize="small" />
                    <Typography variant="body2" sx={{ p: 1 }}>
                        In {toTitleCase(amr.status.operationMode)} mode
                    </Typography>
                </Box>}
            </Box>
            <ButtonGroup sx={{ width: '100%', display: "flex", justifyContent: "space-around" }}>
                {!robotConfig &&
                    <BigIconButton active={false} sx={{ px: '10px', width: '40px' }} tooltip={"Init Robot Config"} onClick={() => createConfiguration(amr.id)}>
                        <AddLocationIcon />
                    </BigIconButton>
                }
                <BigIconButton active={false} sx={{ px: '10px', width: '40px' }} tooltip={"Home Lift"} onClick={() => handleHomeLift(amr.id)}>
                <HeightIcon />
                </BigIconButton>

                {robotConfig &&
                    <BigIconButton active={false} sx={{ px: '10px', width: '40px' }} tooltip={"Set Current Map"} onClick={handleSetCurrentMap}>
                        <AddLocationIcon />
                    </BigIconButton>
                }
                <BigIconButton disabled={!!(amr && amr.status && amr.status.localizationScore && amr.status.localizationScore < 0.0)} active={false} sx={{ px: '10px', width: '40px' }} tooltip={"Localize"} onClick={() => handleSetLocalize(amr.id)}>
                    <PinDropIcon />
                </BigIconButton>
                <BigIconButton active={false} sx={{ mr: 0 }} tooltip={"Send to Charge"}
                    onClick={handleSendToCharge}>
                    <EvStationIcon />
                </BigIconButton>
                <BigIconButton sx={{ mr: 0 }} tooltip={"Manual control"} active={manualControl} disabled={amr.status.operationMode !== OperatingMode.MANUAL}
                    onClick={handleToggleManualControl}>
                    <ManualControlIcon />
                </BigIconButton>
            </ButtonGroup>
            {
                manualControl && amr.status.operationMode === OperatingMode.MANUAL &&
                <Box>
                    <Box sx={{ mt: 2, ml: 1, py: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box>
                            <Typography variant="body2">
                                Set SLS
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {sls ? "On" : "Off"}
                            </Typography>
                        </Box>
                        <FormGroup>
                            <IOSSwitch checked={sls} onChange={handleSLSChange} />
                        </FormGroup>
                    </Box>
                    <Box sx={{ ml: 1, py: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box>
                            <Typography variant="body2">
                                Set Lift
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {lift ? "Up" : "Down"}
                            </Typography>
                        </Box>
                        <FormGroup>
                            <IOSSwitch color="primary" checked={lift}
                                onChange={handleLiftChange} />
                        </FormGroup>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <ManualControl robotId={amr.id} />
                    </Box>
                </Box>
            }
        </Card >
    )
}
