import {useWebSocket} from "../hooks/websocket";
import {Box, Button, Divider, FormGroup, TextField, Typography} from "@mui/material";
import {SoftShadowCard} from "../styled/SoftShadowCard";
import ContactlessIcon from '@mui/icons-material/Contactless';
import HttpIcon from '@mui/icons-material/Http';
import {useEffect, useMemo, useState} from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {ValueRow} from "../amrs/ValueRow";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {editAPIURL, editWSURL, switchTLD} from "../../store/configSlice";
import {IOSSwitch} from "../styled/input/Switch";
import {WS_URL} from "../../constants/wsTopics";

// @ts-ignore
const DEFAULT_URL = window._env?.REACT_APP_API_URL ?? "https://fleet-api.apps.moti.us"

export const AdminPage = () => {
  const {socket, lastUpdate: wsUpdate, reconnect} = useWebSocket()
  const {tld, api, ws} = useSelector((state: RootState) => state.config)
  const [lastUpdate, setLastUpdate] = useState(new Date())
  const dispatch = useAppDispatch()

  const [apiTextFieldValue, setApiTextFieldValue] = useState("")
  const [wsTextFieldValue, setWsTextFieldValue] = useState("")

  const handleApiValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiTextFieldValue(event.target.value);
  };

  const handleWSValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWsTextFieldValue(event.target.value);
  };

  const handleUpdateApiUrl = () => {
    dispatch(editAPIURL(apiTextFieldValue))
  }

  const handleUpdateWSUrl = () => {
    dispatch(editWSURL(wsTextFieldValue))
  }

  const handleResetApiUrl = () => {
    dispatch(editAPIURL(DEFAULT_URL))
    setApiTextFieldValue(DEFAULT_URL)
  }

  const handleResetWSUrl = () => {
    dispatch(editWSURL(WS_URL))
    setWsTextFieldValue(WS_URL)
  }

  useEffect(() => {
    setLastUpdate(new Date())
    setApiTextFieldValue(api ?? DEFAULT_URL)
    setWsTextFieldValue(ws ?? WS_URL)
  }, [])


  const socketStateLabel = useMemo(() => {
    switch (socket.current?.readyState) {
      case socket.current?.CONNECTING:
        return "Connecting";
      case socket.current?.OPEN:
        return "Open";
      case socket.current?.CLOSING:
        return "Closing";
      case socket.current?.CLOSED:
        return "Closed";
      default:
        return "Unknown"
    }
  }, [socket.current?.readyState, wsUpdate])

  const socketStateUrl = useMemo(() => {
    return socket.current?.url
  }, [socket.current?.url, wsUpdate])

  return (
    <Box sx={{m: 4, display: "flex", flexDirection: "column"}}>
      <SoftShadowCard sx={{p: 2, m: 2, width: "fit-content"}}>
        <Typography variant="h6" sx={{p: 1, pt: 0}}>
          Web socket state
        </Typography>
        <ValueRow icon={<ContactlessIcon
          color={socket.current?.readyState === socket.current?.OPEN ? "success" : "secondary"}/>}
                  title={"Connection status"}
                  value={socketStateLabel}
                  sx={{minWidth: 500}}
        />
        <ValueRow icon={<HttpIcon/>} title={"URL"} value={socketStateUrl ?? "no data"}/>
        <ValueRow icon={<AccessTimeIcon/>} title={"Last update"}
                  value={`${lastUpdate.toLocaleTimeString()} ${lastUpdate.toLocaleDateString()}`}/>
        <Box sx={{display: "flex", flexDirection: "row-reverse", mt: 2}}>
          <Button variant="outlined" color="secondary" onClick={reconnect}>
            Reconnect
          </Button>
        </Box>
      </SoftShadowCard>
      <SoftShadowCard sx={{p: 2, m: 2, mt: 0, width: "fit-content"}}>
        <Typography variant="h6" sx={{px: 1, pt: 0, pb: 2}}>
          Connection settings
        </Typography>
        <Box sx={{display: "flex", alignItems: "end"}}>
          <TextField label={"API URL"} value={apiTextFieldValue} onChange={handleApiValueChange}/>
          <Button variant="outlined" color="primary" sx={{ml: 2}} onClick={handleUpdateApiUrl}
                  disabled={apiTextFieldValue === api}>
            Update
          </Button>
          <Button variant="outlined" color="secondary" sx={{ml: 1}} onClick={handleResetApiUrl}
                  disabled={apiTextFieldValue === DEFAULT_URL}>
            Reset
          </Button>
        </Box>
        <Divider sx={{m: 2, mb: 3, opacity: 0.5}}/>
        <Box sx={{display: "flex", alignItems: "end"}}>
          <TextField label={"WS URL"} value={wsTextFieldValue} onChange={handleWSValueChange}/>
          <Button variant="outlined" color="primary" sx={{ml: 2}} onClick={handleUpdateWSUrl}
                  disabled={wsTextFieldValue === ws}>
            Update
          </Button>
          <Button variant="outlined" color="secondary" sx={{ml: 1}} onClick={handleResetWSUrl}
                  disabled={wsTextFieldValue === WS_URL}>
            Reset
          </Button>
        </Box>
      </SoftShadowCard>
      <SoftShadowCard sx={{py: 1, px: 2, m: 2, mt: 0, display: "flex", width: "25%"}}>
        <Box sx={{ml: 1, py: 2, display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
          <Box>
            <Typography variant="body1">
              TLD
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {tld ? "On" : "Off"}
            </Typography>
          </Box>
          <FormGroup>
            <IOSSwitch checked={tld} onChange={() => {
              dispatch(switchTLD())
            }}/>
          </FormGroup>
        </Box>
      </SoftShadowCard>
    </Box>
  )
}
