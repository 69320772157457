export const APP_ROUTES: Record<string, string> = {
  fleet: "fleet",
  nodes: "/fleet/nodes",
  orders: "/fleet/orders",
  jobs: "/fleet/jobs",
  tasks: "tasks",
  amrs: "/fleet/amrs",
  docking: "dockingParams",
  undocking: "undockingParams",
  add: "add",
  edit: "edit",
  maps: "/fleet/maps",
  zones: "/fleet/zones",
}
