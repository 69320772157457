import {Box, OutlinedInput, OutlinedInputProps, Typography} from "@mui/material";

export function InputWithTitle(props: OutlinedInputProps) {
  return (
    <Box sx={props.sx}>
      <Typography color="textSecondary" variant="body2" sx={{mb: 1}}>
        {props.title}
      </Typography>
      <OutlinedInput
        {...props}
        sx={{...props.sx, fontSize: 14}}
      />
    </Box>
  )
}
