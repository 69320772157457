import { Box } from "@mui/material";
import * as d3 from "d3";
import { ZoomTransform } from "d3";
import { useEffect, useMemo, useState } from "react";
import { useD3 } from "../hooks/d3";
import { useAppDispatch } from "store/store";
import { useSelector } from "react-redux";
import { getPartialMap } from "store/mapSlice";
import { MapConstants } from "constants/MapConstants";
import { MapContentLayer } from "components/map/MapContentLayer";
import { MappingToolbar } from "components/map/MappingToolbar";

export const MappingMapContainer = () => {
  const partialMap = useSelector(getPartialMap)
  const [imageDimensions, setImageDimensions] = useState({ 'width': 0, 'height': 0 })
  const height = 900
  useEffect(() => {
    const img = new Image()
    img.src = `data:image/png;base64, ${partialMap}`
    img.onload = () => {
      const scale = Math.round(img.height / height);
      setImageDimensions({ 'width': img.width / scale, 'height': img.height / scale })
    }

  }, [partialMap])

  return (
    <Box sx={{
      p: { xs: 1, sm: 2 },
      display: "flex",
      flexDirection: {
        xs: "column",
        sm: "row"
      },
      position: "absolute",
      height: "100%",
      width: "100%",
      backgroundColor: "background.default"
    }}>
      <Box
        id={"mapping-svg-container"}
        sx={{
          position: "absolute",
          zIndex: 1,
          transformBox: "fill-box",
        }}
      >
        {partialMap &&
          <img
            width={imageDimensions.width}
            height={imageDimensions.height}
            src={`data:image/png;base64, ${partialMap}`}
          />
        }
      </Box>
      <MappingToolbar />
    </Box>
  )
}
