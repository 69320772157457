import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorLevel, Error } from 'model/Amr'

interface errorState {
  robot_id: string
  error_level: ErrorLevel
  error_type: string
  show: boolean
}

interface errorStates {
  errorStates: errorState[]
}

const initialState: errorStates = { errorStates: [] }

export const errorstateSlice = createSlice({
  name: 'errorstate',
  initialState,
  reducers: {
    hideErrorState: (state, action: PayloadAction<{ index: number }>) => {
      state.errorStates[action.payload.index].show = false
    },
    updateErrors: (state, action: PayloadAction<{ robot_id: string, errors: Error[] }>) => {
      let new_errors_by_robot: errorState[] = []
      action.payload.errors.map(new_err => {
        const error_to_add: errorState = { robot_id: action.payload.robot_id, error_level: new_err.error_level, error_type: new_err.type, show: true }
        const index = state.errorStates.findIndex(err => err.robot_id === error_to_add.robot_id && err.error_type == error_to_add.error_type)
        if (index === -1)
          new_errors_by_robot.push(error_to_add)
        else
          new_errors_by_robot.push(state.errorStates[index])
      })
      let temp_errors_robot = state.errorStates.filter(old_error => old_error.robot_id !== action.payload.robot_id)
      state.errorStates = temp_errors_robot.concat(new_errors_by_robot)
    },

  },
})

export const {
  hideErrorState,
  updateErrors,
} = errorstateSlice.actions

export default errorstateSlice.reducer
