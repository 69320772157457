import {Box, Button, Snackbar, Typography} from "@mui/material";
import {useSelector} from 'react-redux'
import {CloseIcon, NotificationsIcon} from "../../assets/icons/SvgIcons";
import {RootState, useAppDispatch} from "../../store/store";
import { setNotificationShow } from 'store/notificationSlice';

export const NotificationSnackbar = () => {
  const dispatch = useAppDispatch()

  const {text, show, data} = useSelector(
    (state: RootState) => state.notifications
  )

  const handleSnackbarClose = (
    event: any,
    reason: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(setNotificationShow(false))
  }

  const handleSnackbarCloseClick= () => {
    dispatch(setNotificationShow(false))
  }

  return (
    <Snackbar
      open={show}
      onClose={handleSnackbarClose}
      autoHideDuration={10000}
      message={
        <Box>
          <NotificationsIcon/>
          <Typography color="primary">
            {text}
          </Typography>
          <Box>
            {data}
          </Box>
        </Box>
      }
      action={
        < Button
          size='medium'
          onClick={() => handleSnackbarCloseClick()}
        >
          <CloseIcon/>
        </Button>
      }
    />
  )
}

