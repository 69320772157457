import {ROUTES} from "./routes";
import { axiosInstance } from "./axios";
import { IOrder, IOrderAPIResponse, mapOrderFromApi, mapOrderToApi } from "model/Orders"

// TODO: add zod and schemas to all API message
// Make sure we always return proper TypeScript types
export const getAllOrders = async () => {
  const {data} = await axiosInstance.get(`${ROUTES.orders.url}`)
  return data.map( (order: IOrderAPIResponse) => mapOrderFromApi(order))
};

export const getOrder = async (id: string) => {
  const {data} = await axiosInstance.get(`${ROUTES.orders.url}/${id}`)
  return mapOrderFromApi(data)
};

export const createOrder = async (order: IOrder) => {
  const { data } = await axiosInstance.post(`${ROUTES.orders.url}`, mapOrderToApi(order))
  return data
};

export const updateOrder = async (order: IOrder) => {
  const { data } = await axiosInstance.patch(`${ROUTES.orders.url}/${order.id}`, mapOrderToApi(order))
  return data
}

export const deleteOrder = async (id: string) => {
  return axiosInstance.delete(`${ROUTES.orders.url}/${id}`)
};
