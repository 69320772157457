export interface IZone {
  id: string,
  correspondingMapName: string,
  zoneMapData: string,
  zoneName: string,
  resolution: number,
  originX: number,
  originY: number
}


export interface IZoneCircle {
  location: { x: number, y: number }
}

export const defaultZoneBrush: IZoneBrush = {
  radius: 20,
  color: "WHITE"
}

export interface IZoneBrush {
  radius: number,
  color: string,
}

export interface IZoneApi {
  _id: string,
  corresponding_map_id: string,
  zone_map_data: string,
  zone_name: string,
  origin_x: number,
  origin_y: number,
  resolution: number,
}

export const mapZoneToApi = (zone: IZone): IZoneApi =>
({
  _id: zone.id,
  corresponding_map_id: zone.correspondingMapName,
  zone_map_data: zone.zoneMapData,
  zone_name: zone.zoneName,
  origin_x: zone.originX,
  origin_y: zone.originY,
  resolution: zone.resolution
})


export const mapZoneFromApi = (zone: IZoneApi): IZone =>
({
  id: zone._id,
  correspondingMapName: zone.corresponding_map_id,
  zoneMapData: zone.zone_map_data,
  zoneName: zone.zone_name,
  originX: zone.origin_x,
  originY: zone.origin_y,
  resolution: zone.resolution,
})
