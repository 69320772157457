import {SOFT_SHADOW, SoftShadowCard} from "../styled/SoftShadowCard";
import {Box, Button, IconButton, TextField, TextFieldProps, Typography} from "@mui/material";
import {InputWithTitle} from "../styled/input/InputWithTitle";
import {useMemo, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {createSubscriber, patchSubscriber, useSubscribers} from "../../api/subscription";
import {useMutation, useQueryClient} from "react-query";
import {ROUTES} from "../../api/routes";
import {TimePicker} from "@mui/x-date-pickers";
import {softShadow} from "../../theme/theme";
import {setsAreEqual} from "../../utils/calc";
import {GlobalNotificationSwitch} from "../notifications/GlobalNotificationSwitch";
import {EmailList} from "../notifications/EmailList";

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const MIN_TIME = new Date(0, 0, 0, 6, 0, 0)
const MAX_TIME = new Date(0, 0, 0, 23, 0, 0)

export interface NotificationConfig {
  id: string
  email: string,
  enabled: boolean
  weekdays: Set<number>
  from: Date | null
  to: Date | null
}

const defaultEmail: NotificationConfig = {
  id: "",
  email: "",
  enabled: true,
  weekdays: new Set<number>(),
  from: new Date(0, 0, 0, 9, 0, 0),
  to: new Date(0, 0, 0, 17, 0, 0),
}

export const NotificationsPage = () => {
  const [currentEmail, setCurrentEmail] = useState(defaultEmail)
  const theme = useTheme()
  const {data: emails, isFetchedAfterMount, dataUpdatedAt} = useSubscribers()

  const queryClient = useQueryClient()

  const createSubscriptionMutation = useMutation(createSubscriber, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.subscriber.key)
      setCurrentEmail(defaultEmail)
    }
  })

  const patchSubscriptionMutation = useMutation(patchSubscriber, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.subscriber.key)
    }
  })

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail({...currentEmail, email: event.target.value});
  }

  const handleToggleWeekday = (index: number) => () => {
    const newSet = new Set(currentEmail.weekdays)
    if (currentEmail.weekdays.has(index)) {
      newSet.delete(index)
    } else {
      newSet.add(index)
    }
    setCurrentEmail({...currentEmail, weekdays: newSet})
  }

  const handleAddEmail = () => {
    createSubscriptionMutation.mutate(currentEmail)
  }

  const handleUpdateEmail = () => {
    patchSubscriptionMutation.mutate(currentEmail)
  }

  const handleListItemEdit = (item: NotificationConfig) => () => {
    setCurrentEmail(item)
  }

  const subscribed = useMemo(() => {
    return isFetchedAfterMount && emails.filter((el: NotificationConfig) => el.email === currentEmail.email).length > 0
  }, [currentEmail.email])

  const filterEmail = useMemo(() => {
    if (isFetchedAfterMount) {
      const match = emails.filter((el: NotificationConfig) => el.id === currentEmail.id)
      if (match.length > 0) {
        return match[0]
      }
    }
    return null
  }, [currentEmail.id, dataUpdatedAt])

  const invalidConfig = useMemo(() => {
    if (filterEmail) {
      // check if there were no changes made to existing email notification config
      return filterEmail.email === currentEmail.email && filterEmail.from.toString() === currentEmail.from?.toString() && filterEmail.to.toString() === currentEmail.to?.toString() && setsAreEqual(filterEmail.weekdays, currentEmail.weekdays)
    }
    // check if there are empty fields
    return !currentEmail.email || !currentEmail.from || !currentEmail.to
  }, [currentEmail, dataUpdatedAt])

  const timePickerInput = (params: TextFieldProps) => <TextField {...params} sx={{...params.sx, width: 168}}/>

  return (
    <Box sx={{display: "flex", flexDirection: "column", width: "fit-content"}}>
      <GlobalNotificationSwitch/>
      <SoftShadowCard sx={{p: 3, mx: 6, my: 2, width: "fit-content"}}>
        <Typography sx={{pb: 1}} fontWeight="bold">{subscribed ? "Update " : "Add "} Email to send AMR notifications
          to</Typography>
        <InputWithTitle sx={{width: "90%"}} placeholder="Enter Email" value={currentEmail.email}
                        onChange={handleChangeEmail}/>
        <Typography sx={{pb: 1, pt: 3}}>Select weekdays</Typography>
        <Box sx={{p: 2, px: 0}}>
          {weekdays.map((day, index) => <IconButton
            onClick={handleToggleWeekday(index)}
            sx={{
              width: 44,
              height: 44,
              bgcolor: currentEmail.weekdays.has(index) ? theme.palette.primary.main : "white",
              color: currentEmail.weekdays.has(index) ? "white" : "textPrimary",
              mr: 1,
              p: 1,
              filter: SOFT_SHADOW,
              "&:hover": {
                bgcolor: currentEmail.weekdays.has(index) ? theme.palette.primary.dark : theme.palette.secondary.light,
              }
            }}>
            <Typography variant="body2">{day}</Typography>
          </IconButton>)}
        </Box>
        <Typography sx={{pb: 3, pt: 2}}>Select timeframe</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <TimePicker
              renderInput={timePickerInput}
              value={currentEmail.from}
              label="From"
              onChange={(newValue) => {
                setCurrentEmail({...currentEmail, from: newValue})
              }}
              minTime={MIN_TIME}
              maxTime={currentEmail.to}
              PaperProps={{elevation: 0, sx: {...softShadow, mt: 1}}}
            />
            <Box sx={{mx: 2}}>-</Box>
            <TimePicker
              renderInput={timePickerInput}
              value={currentEmail.to}
              label="To"
              onChange={(newValue) => {
                setCurrentEmail({...currentEmail, to: newValue})
              }}
              minTime={currentEmail.from}
              maxTime={MAX_TIME}
              PaperProps={{elevation: 0, sx: {...softShadow, mt: 1}}}
            />
          </Box>
          <Button disabled={invalidConfig} variant="contained" color="primary"
                  sx={{mt: 1, ml: 8, p: 1.5, width: 160}}
                  onClick={subscribed ? handleUpdateEmail : handleAddEmail}>
            {subscribed ? "Update settings" : "Add Email"}
          </Button>
        </Box>
      </SoftShadowCard>
      <EmailList handleListItemEdit={handleListItemEdit}/>
    </Box>
  )
}
