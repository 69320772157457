import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { AddButton } from "components/styled/toolbar/AddButton";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { useMutation, useQueryClient } from "react-query";
import { setSelectedJob, initEditingJob, setEditingJob, getJobsBySelectedMap, toggleIsBuildingJob, toggleIsEditingJob, removeJob } from "store/jobSlice";
import { getOrdersBySelectedMap } from "store/orderSlice"
import { deleteJob } from "api/jobs"
import { ROUTES } from "api/routes";
import { useAppDispatch } from "store/store";
import { IJob, IJobWithOrder } from "model/Jobs"

export const JobsPanel = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const jobs = useSelector(getJobsBySelectedMap).map(j => j).sort((a, b) => a.name < b.name ? -1 : 1)
  const orders = useSelector(getOrdersBySelectedMap)


  const handleItemClick = (job: IJob) => {
    navigate("selected");
    dispatch(setSelectedJob(job))
  }

  const handleItemDelete = (id: string) => {
    deleteJob(id)
    dispatch(removeJob(id))
  }

  const handleItemEdit = (job: IJob) => {
    const ordersInJob = job.orders.flatMap(selectedOrder => {
      const foundOrder = orders.find(order => order.id == selectedOrder)
      if (!foundOrder) {
        return []
      }
      return [foundOrder]
    })

    const job_to_edit: IJobWithOrder = { id: job.id, name: job.name, orders: ordersInJob }
    navigate("edit");
    dispatch(setEditingJob(job_to_edit))
    dispatch(toggleIsEditingJob(true))

  }

  const onClickBuild = useCallback(() => {
    dispatch(initEditingJob())
    dispatch(toggleIsBuildingJob(true))
  }, [])

  return (
    <>
      <ToolbarTitle title={"Jobs"} />
      <AddButton onClickHandler={onClickBuild} title="Build new Job" />
      <List>
        {jobs.map((job, id) =>
          <ListItemWithCRUD key={job.id} sx={{ pl: 4, borderRadius: 1 }}
            onClick={() => handleItemClick(job)}
            handleDelete={() => handleItemDelete(job.id)}
            handleEdit={() => handleItemEdit(job)}>
            <ListItemText>
              <Typography variant="body2">
                {job.name}
              </Typography>
            </ListItemText>
          </ListItemWithCRUD>)}
      </List>
    </>
  )
}
