import {
  Box,
  Button,
  List,
  ListItemText,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "store/store";
import { useMutation, useQueryClient } from "react-query";
import { defaultNode, INode } from "model/Nodes";
import { useSelector } from "react-redux";
import { ListItemWithCRUD } from "components/styled/list/ListItemWithCRUD";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import { MapFormatter } from "utils/MapFormatter";
import { updateJob, getJob } from "api/jobs"
import { IOrder } from "model/Orders";
import {
  updateJobState,
  addOrderToJob,
  updateEditingJobName,
  toggleIsEditingJob,
  removeOrderFromJob,
  getEditingJob,
  clearEditingJob
} from "store/jobSlice"

import { getOrdersBySelectedMap } from "store/orderSlice"


export const EditJob = () => {
  const [error, setError] = useState(false);
  const editingJob = useSelector(getEditingJob)
  const orders = useSelector(getOrdersBySelectedMap)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const { mutateAsync: asyncJob } = useMutation(updateJob)
  const { mutateAsync: asyncGetJob } = useMutation(getJob)

  useEffect(() => {
    if (!editingJob) {
      navigate('/fleet/jobs')
    }
  }, [editingJob])


  const handleChangeName = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(updateEditingJobName({ name: event.target.value }))
  };

  const handleSaveJob = async () => {
    if (editingJob) {
      try {
        const jobId = await asyncJob(editingJob)
        const fetchedJob = await asyncGetJob(jobId)
        dispatch(updateJobState(fetchedJob))
        dispatch(toggleIsEditingJob(false));
        dispatch(clearEditingJob());
        navigate('/fleet/orders')

      } catch (error) {
        console.error('Update of job failed', error)
      }

    }

  }

  const onClickHandler = () => {
    //dispatch(clearNewJob())
    //dispatch(clearNewEdges())
    dispatch(toggleIsEditingJob(false))
  }

  const handleOrderClicked = (order: IOrder) => () => {
    dispatch(addOrderToJob(order))
  }

  const handleOrderDelete = (orderId: string) => () => {
    dispatch(removeOrderFromJob(orderId))
  }

  return (
    <>{editingJob && <>
      <ToolbarTitle title={"Edit Job"} onClick={onClickHandler} back="order" />
      <FormControl fullWidth error={error}>
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
          Job Name
        </Typography>
        <OutlinedInput placeholder="Please enter text" value={editingJob.name} onChange={handleChangeName} />
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body1"}>
          Selected Orders
        </Typography>
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
          {editingJob.orders.map(order =>
            <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
              handleDelete={handleOrderDelete(order.id)}
            >
              <ListItemText>
                <Typography variant="body2">
                  {order.name}
                </Typography>
              </ListItemText>
            </ListItemWithCRUD>)}
        </Typography>
      </FormControl>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body1"}>
        Orders Choices
      </Typography>
      {orders.map(order =>
        <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
          onClick={handleOrderClicked(order)}
        >
          <ListItemText>
            <Typography variant="body2">
              {order.name}
            </Typography>
          </ListItemText>
        </ListItemWithCRUD>
      )}
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        {orders.map(order =>
          <ListItemWithCRUD key={order.id} sx={{ pl: 4, borderRadius: 1 }}
            onClick={handleOrderClicked(order)}
          >
            <ListItemText>
              <Typography variant="body2">
                {order.name}
              </Typography>
            </ListItemText>
          </ListItemWithCRUD>
        )}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save POI"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ p: 1.5 }}
          onClick={handleSaveJob}
          disabled={!(editingJob.name && editingJob.orders.length > 0)}
        >
          Save Job
        </Button>
      </Box>
    </>}
    </>
  )
}
