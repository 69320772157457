import {
  FormControl,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useSelector } from "react-redux";
import { updateCurrentDPNumber, updateCurrentDPString } from "../../../../store/dockparamsSlice";

export const DockParamsForm = () => {
  const dispatch = useAppDispatch()
  const { currentDP } = useSelector((state: RootState) => state.dps)
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCurrentDPString({ key: 0, value: event.target.value }))
  }

  const handleChangeX = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 0, value: parseFloat(event.target.value) }))
  }

  const handleChangeY = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 1, value: parseFloat(event.target.value) }))
  }

  const handleChangeYaw = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 2, value: parseFloat(event.target.value) }))
  }

  const handleChangeDirect = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 3, value: parseFloat(event.target.value) }))
  }

  const handleChangeLateral = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 4, value: parseFloat(event.target.value) }))
  }

  const handleChangeAngle = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 5, value: parseFloat(event.target.value) }))
  }

  const handleChangeAlignmentPose = (event: React.ChangeEvent<HTMLInputElement>,) => {
    dispatch(updateCurrentDPNumber({ key: 6, value: parseFloat(event.target.value) }))
  }

  const handleDockForwardsChange = (event: SelectChangeEvent) => {
    dispatch(updateCurrentDPString({ key: 1, value: event.target.value }))

  }


  return (
    <>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Name
      </Typography>
      <OutlinedInput placeholder="Write name here" value={currentDP?.name ?? ""} onChange={handleChangeName} />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Dock Pose
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        X
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeX}
        value={currentDP?.dock_pose.x ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        Y
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeY}
        value={currentDP?.dock_pose.y ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        Yaw
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeYaw}
        value={currentDP?.dock_pose.yaw ?? 0.0}
      />

      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Approach Pose
      </Typography>
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        Direct
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeDirect}
        value={currentDP?.approach_pose.direct ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        Lateral
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeLateral}
        value={currentDP?.approach_pose.lateral ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"button"}>
        Angle Degrees
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeAngle}
        value={currentDP?.approach_pose.angle_degrees ?? 0.0}
      />
      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Alignment Pose Distance
      </Typography>
      <TextField
        type="number"
        onChange={handleChangeAlignmentPose}
        value={currentDP?.alignment_pose_distance ?? 0.0}
      />

      <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        Dock Forwards
      </Typography>
      <FormControl sx={{ width: "100%" }}>
        <Select
          sx={{ color: "secondary.main", mb: 1 }}
          value={currentDP?.dock_forwards ? "true" : "false"}
          IconComponent={ExpandMoreRoundedIcon}
          onChange={handleDockForwardsChange}
          displayEmpty
        >
          <MenuItem disabled value={""}> Select action </MenuItem>
          <MenuItem value={"true"}
          > True </MenuItem>
          <MenuItem value={"false"}
          > False </MenuItem>

        </Select>
      </FormControl>
    </>
  )

}
