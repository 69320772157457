import { Box, Button, FormHelperText } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../../store/store";
import { useMutation, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { ROUTES } from "../../../../api/routes";
import { clearCurrentDP, updateCurrentDP } from "../../../../store/dockparamsSlice";
import { patchDockParam, useDockParam } from "../../../../api/docking_params";
import { useSelector } from "react-redux";
import { ToolbarTitle } from "../../../styled/toolbar/ToolbarTitle";
import { DockParamsForm } from "./DockParamsForm";

export const EditDockingParas = () => {
  const params = useParams();
  const { isFetchedAfterMount, data: docking_params } = useDockParam(params.dockparamID ?? "")

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { currentDP } = useSelector((state: RootState) => state.dps)

  const [error, setError] = useState(false);

  useEffect(() => {
    return function cleanup() {
      dispatch(clearCurrentDP())
    };
  }, [])

  useEffect(() => {
    if (isFetchedAfterMount && docking_params) {
      dispatch(updateCurrentDP({ dockParams: docking_params }))
    }
  }, [docking_params, isFetchedAfterMount])

  const patchDPMutation = useMutation(patchDockParam, {
    onSuccess: () => {
      queryClient.invalidateQueries(ROUTES.docking.key)
      navigate(-1)
    },
    onError: () => {
      setError(true)
    }
  })

  const handleSaveDP = () => {
    if (currentDP)
      patchDPMutation.mutate({ dp: currentDP })
  }

  useEffect(() => {
    setError(false)
  }, [currentDP])

  return (
    <>
      <ToolbarTitle title={"Edit docking parameters"} back={"dockingParams"} />
      <DockParamsForm />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could update task"}</FormHelperText>
        <Button
          variant="contained"
          sx={{ mb: 1, borderRadius: 0.5 }}
          onClick={handleSaveDP}
          disabled={currentDP && !currentDP.name}
        >
          Save changes
        </Button>
        <Button
          variant="outlined"
          sx={{ borderRadius: 0.5 }}
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </Button>
      </Box>
    </>
  )
}
