import {IconButton, ListItemButton, ListItemButtonProps} from "@mui/material";
import {DeleteIcon, EditIcon} from "../../../assets/icons/SvgIcons";
import {useState} from "react";

export interface ListItemWithCRUDProps extends ListItemButtonProps {
  handleEdit?: () => void
  handleDelete?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const ListItemWithCRUD = (props: ListItemWithCRUDProps) => {
  const [hover, setHover] = useState(false)

  const { onMouseEnter: onMouseEnterCallback,
        onMouseLeave: onMouseLeaveCallback,
        handleEdit,
        handleDelete,
        sx,
        children,
        ...other
      } = props


  const handleMouseEnter = () => {
    if (onMouseEnterCallback) {
      onMouseEnterCallback()
    }
    setHover(true)
  }

  const handleMouseExit = () => {
    if (onMouseLeaveCallback) {
      onMouseLeaveCallback()
    }
    setHover(false)
  }



  return (<ListItemButton {...other} sx={{...sx, height: 44, borderRadius: 1}} onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseExit}
  >
    {children}
    {hover && handleEdit &&<IconButton onClick={(e: any) => {
      e.stopPropagation()
      handleEdit()
    }}
    >
      <EditIcon sx={{color: "secondary.main", width: 18, height: 18}}/>
    </IconButton>}
    {hover && handleDelete && 
      <IconButton onClick={(e: any) => {
        e.stopPropagation()
        handleDelete()
      }}>
        <DeleteIcon sx={{color: "secondary.main", width: 18, height: 18}}/>
      </IconButton>
    }
  </ListItemButton>)
}
