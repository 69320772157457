import {
  Box,
  Button,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
  Select,
  MenuItem,
  IconButton,
  ListItemText,
  List,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { useTheme } from "@mui/material/styles";
import { Fragment, useEffect, useState, useCallback } from "react";
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useAppDispatch } from "store/store";
import {
  getIsAddingEdge, getNodes
} from "store/nodeSlice";
import RouteIcon from '@mui/icons-material/Route';
import { useMutation } from "react-query";
import { IOrder } from "model/Orders";
import { mapOrientationFromRobot } from "model/Map";
import { defaultUndockStage, INodeAction } from "model/Actions";
import { useSelector } from "react-redux";
import { ROBOT_SVG } from "constants/robots";
import { ToolbarTitle } from "components/styled/toolbar/ToolbarTitle";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { createOrder, getOrder } from "api/orders";
import { getAmrNames } from "store/amrSlice";
import * as d3 from "d3";
import {
  addOrder,
  clearNewOrder,
  clearNewOrderAction,
  updateNewOrder,
  getNewOrder,
  getNewOrderNodes,
  toggleIsBuildingOrder,
  getNewOrderAction,
  setNewOrderAction,
  popActionParameter,
  addActionParameter,
  updateActionParameter,
  setAmrAsPathStart,
  setPathingType,
  getPathingType,
  clearNewOrderNodes,
  updateOrderEdge,
} from "store/orderSlice";


const INFO_GRAPH_WIDTH = 200
const INFO_GRAPH_HEIGHT = 250

const ORDER_TYPES = [
  "Path Following",
  "Charge",
  "Lift",
  "Drop",
  "Dock",
  "Undock"
]

const mapActionFromOrderType = (orderType: string) => {
  switch(orderType) {
    case "Charge":
      return "startCharging"
    case "Dock":
      return "dock"
    case "Lift":
      return "pick"
    case "Drop":
      return "drop"
   case "Undock":
      return "undock"
    default:
      return
  }
}


export const AddOrder = () => {
  const [error] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState("Path Following")
  const [selectedAmr, setSelectedAMR] = useState("")
  const newOrder = useSelector(getNewOrder)
  const isAddingEdge = useSelector(getIsAddingEdge)
  const newOrderNodes = useSelector(getNewOrderNodes)
  const newOrderAction = useSelector(getNewOrderAction)
  const nodesFromDB = useSelector(getNodes)
  const availableAmrNames = useSelector(getAmrNames)
  const pathingType = useSelector(getPathingType)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const theme = useTheme()


  const { mutateAsync: asyncOrder } = useMutation(createOrder)
  const { mutateAsync: asyncGetOrder } = useMutation(getOrder)

  useEffect(() => {
    if (!newOrder) {
      navigate('/fleet/orders')
    }
  }, [newOrder])


  const handleSetRobotAsStart = useCallback((selectedAmr: string) => () => {
    dispatch(setAmrAsPathStart(selectedAmr))
  }, [])

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(updateNewOrder({ name: event.target.value }))
  }, [])

  const handleSelectAmr = useCallback((event: SelectChangeEvent) => {
    setSelectedAMR(event.target.value)
  }, [availableAmrNames])

  const handleSaveOrder = useCallback((orderAction: INodeAction | undefined, newOrderOG: IOrder) => {

    const saveOrder = async() => {
      const newOrder = { ...newOrderOG }
      if (newOrder) {
        try {
          if (orderAction) {
            newOrder.nodes = newOrder.nodes.map((node, idx) => {
              if (idx === 0) {
                return { ...node, actions: [orderAction] }
              }
              return node
            })
          }
          const orderId = await asyncOrder(newOrder)
          const fetchedOrder = await asyncGetOrder(orderId)
          dispatch(addOrder(fetchedOrder))
          dispatch(toggleIsBuildingOrder(false));
          dispatch(clearNewOrder());
          dispatch(clearNewOrderAction());
          navigate('/fleet/orders')

        } catch (error) {
          console.error('Creation of order failed', error)
        }
      }
    }
    saveOrder()

  }, [])


  const onClickHandler = () => {
    dispatch(clearNewOrder())
    //dispatch(clearNewEdges())
    dispatch(toggleIsBuildingOrder(false))
  }

  const setActionFromOrderType = (orderType: string) => {
    const actionType = mapActionFromOrderType(orderType)
    if (!actionType) {
      dispatch(clearNewOrderAction())
      return
    }
    dispatch(setNewOrderAction(actionType))
  }

  const addUndockStage = () => {
    dispatch(addActionParameter(defaultUndockStage))
  }

  const removeUndockStage = (idx: number) => () => {
    dispatch(popActionParameter(idx))
  }

  const handleActionParameterChange = (idx: number, updateType: string) => (event: any) => {
    const update_val = event.target.value
    if (updateType === "key") {
      dispatch(updateActionParameter(({ "actionIndex": idx, "key": update_val })))
    }
    if (updateType === "value") {
      dispatch(updateActionParameter(({ "actionIndex": idx, "value": update_val })))
    }
  }

  const buildBox = (actionParameterIndex: number,
    actionParam: any,
    actionValueIndex: number,
    onChange: any,
    typeString: string,
    options?: (string[] | undefined),
    placeholder?: string) => {
    if (typeString === "select") {
      let displayedValued = actionParam.value
      if (typeof displayedValued == "boolean") {
        displayedValued = displayedValued ? "TRUE" : "FALSE"
      }
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl>
            <Select
              sx={{ color: "secondary.main", mb: 1 }}
              value={displayedValued}
              IconComponent={ExpandMoreRoundedIcon}
              onChange={onChange(actionParameterIndex, actionParam, actionValueIndex)}
              displayEmpty
            >
              {options && options.map((option => <MenuItem key={option} value={option}> {option} </MenuItem>))}
            </Select>
          </FormControl>
        </Box>
      )
    }


    return (
      <Box sx={{ pr: 2, display: "flex", alignItems: "center" }}>
        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
        </Typography>
        <TextField
          type={typeString}
          placeholder={placeholder}
          onChange={onChange(actionParameterIndex, actionParam, actionValueIndex)}
          value={typeof actionParam.value === "object" ? actionParam.value[actionValueIndex] : actionParam.value}
        />
      </Box>
    )

  }

  const handleDockParamChange = (actionParameterIndex: number, actionParam: any, actionValueIndex: number) => (event: any) => {
    const updatedValue = event.target.value
    let update: { key: string, actionIndex: number, value?: number | number[] | string } = {
      "key": actionParam.key,
      "actionIndex": actionParameterIndex,
    }
    switch (typeof actionParam.value) {
      case 'string':
        update.value = updatedValue
        break;
      case 'number':
        update.value = updatedValue
        break;
      case 'object':
        update.value = actionParam.value.map((x: number, id: number) => id === actionValueIndex ? updatedValue : x as number)
        break;
    }

    dispatch(updateActionParameter(update))

  }

  const getNodeSavedRotation = (idx: number) => {

    let dock_angle = 0;
    if (newOrder && newOrderAction && newOrder.nodes.length > 0) {
      const node_idx = nodesFromDB.findIndex((node) => newOrder.nodes[0].node_id === node.id)
      if (node_idx !== -1) dock_angle = nodesFromDB[node_idx].rotation
    }

    const radians = dock_angle
    const dockUpdate = {
      "key": "dock_angle",
      "actionIndex": idx,
      "value": radians
    }
    dispatch(updateActionParameter(dockUpdate))

    return mapOrientationFromRobot(radians)
  }


  const renderActionParamters = useCallback((orderType: string) => {
    const actionType = mapActionFromOrderType(orderType)
    if (!newOrderAction?.action_parameters) {
      return
    }
    switch (actionType) {
      case 'dock':
        return (
          <>
            {newOrderAction.action_parameters.map((action_param: {'key': string, 'value': any}, idx: number) => {
              switch (action_param.key) {
                case "approach_pose_direct_lateral_degree":
                  return (
                    <div key={idx}>
                      Approach Pose
                      <Box sx={{ pt: 1, pb: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {buildBox(idx, action_param, 0, handleDockParamChange, "number", [], "Direct")}
                        {buildBox(idx, action_param, 1, handleDockParamChange, "number", [], "Lateral")}
                        {buildBox(idx, action_param, 2, handleDockParamChange, "number", [], "Angle")}
                      </Box>

                    </div>
                  )

                case "alignment_distance":
                  return (
                    <div key={idx}>
                      Alignment Distance
                      <br />
                      {buildBox(idx, action_param, 0, handleDockParamChange, "number", [], "Direct")}
                    </div>
                  )
                case "dock_angle":
                  return (
                    <div key={idx}>
                      Dock Angle
                      <br />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
                        </Typography>
                        <TextField
                          type={"number"}
                          disabled={true}

                          value={Math.round(getNodeSavedRotation(idx) * 10000) / 10000}
                        />
                      </Box>
                    </div>
                  )
                default:
                  return ""
              }
            }
            )
            }
          </>
        )

      case 'undock':
        return (
          <>
            {newOrderAction.action_parameters.map((action_param: {'key': string, 'value': any}, idx: number) => (
              <div key={idx}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FormControl>
                      <Select
                        sx={{ color: "secondary.main", mb: 1 }}
                        value={action_param.key}
                        IconComponent={ExpandMoreRoundedIcon}
                        onChange={handleActionParameterChange(idx, "key")}
                        displayEmpty
                      >
                        <MenuItem disabled value={""}> Select action </MenuItem>
                        <MenuItem value={"drive"}
                        > Drive </MenuItem>
                        <MenuItem value={"turn"}
                        > Turn </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
                    </Typography>
                    <TextField
                      type="number"
                      onChange={handleActionParameterChange(idx, "value")}
                      value={action_param.value}
                    />
                  </Box>
                </Box>
              </div>
            )
            )}
            <Button color="primary" onClick={addUndockStage}>
              Add Stage
            </Button>
            {(newOrderAction?.action_parameters && newOrderAction.action_parameters.length > 0) &&
              (<Button color="primary" onClick={removeUndockStage(newOrderAction.action_parameters.length - 1)}>
                Remove Stage
              </Button>
              )
            }
          </>
        )
      default:
        return
    }
  }, [newOrder, newOrderAction])

  const  handleOrderTypeClick = (orderType: string) => {
    setSelectedOrderType(orderType)
    setActionFromOrderType(orderType)
  }


  const placeGraphItemCircle = useCallback((direction: string) => (d: any) => {
    if (direction === "x") {
      return INFO_GRAPH_WIDTH / 2
    }
    return  (d['value'] * 50) + 20
  }, [])


  const translateRobot = (d: any) => {
    //const location = mapMLocationToPixel(mapInfo, amr.status.location)
    //const rotation = amr.status.rotation
    if (d['key'] === "approach_pose_direct_lateral_degree") {
      const dy = d['value'][0] * 50 + 30
      const dx = -d['value'][1] * 50 + INFO_GRAPH_WIDTH / 2
      const theta = -d['value'][2]
      return `translate(${dx},${dy})  rotate(${theta}) scale(${0.03})`
    }
  }

  const makeCircleColor = (d: any) => {
    if (d['key'] === "goal") {
      return "blue"
    }
    return "green"
  }

  const clearCurrentPath = () => {
    dispatch(clearNewOrderNodes())
  }

  const getPathTypeButtonColor = useCallback((type: string) => {
    return type === pathingType ? "primary" : "secondary"
  }, [pathingType])

  const togglePathType = useCallback((type: string) => {
    dispatch(setPathingType(type))
  }, [])


  useEffect(() => {
    if (!newOrderAction || !newOrderAction.action_parameters || newOrderAction.action_type !== "dock") {
      d3.select("#order-info-graph")
         .selectAll('circle')
         .remove()

      d3.select("#order-info-graph")
        .selectAll('path')
        .remove()

        d3.select("#order-info-graph")
            .attr("style", "none")

      return
    }

    d3.select("#order-info-graph")
        .attr("style", "outline: thin solid lightgrey; margin-top: 10px")



    if (newOrderAction.action_type === "dock" ) {
      const approach = newOrderAction.action_parameters.filter(param => param['key'] === "approach_pose_direct_lateral_degree")
      const align = newOrderAction.action_parameters.filter(param => param['key'] === "alignment_distance")
      const alignPair =  [ {key: "goal", value: 0},  ...align]

          d3.select("#order-info-graph")
            .selectAll('path')
            .data(approach)
            .join(enter => enter.append('path')
              .style("stroke-width", 20)
              .style("stroke", "black")
              .style("fill", theme.palette.primary.main)
              .attr("d", ROBOT_SVG)
              // @ts-expect-error
              .attr('transform', translateRobot),
              update => update.transition().duration(1)
              // @ts-expect-error
                .attr('transform', translateRobot),
              exit => exit.remove()
            )

          d3.select("#order-info-graph")
            .selectAll('circle')
            .data(alignPair, (d: any) => d['key'])
              .join(enter => enter.append('circle')
                .attr('cx', placeGraphItemCircle("x"))
                .attr('cy', placeGraphItemCircle("y"))
                .attr('r', 3)
                .style("fill", makeCircleColor),
                update => update.transition()
                  .duration(50)
                  .attr('cx', placeGraphItemCircle("x"))
                  .attr('cy', placeGraphItemCircle("y"))
                  .style("fill", makeCircleColor),
                 exit => exit.remove()
              )
      }

  }, [newOrderAction])

  const isSendDisabled = useCallback(() => {

    if (!newOrder || newOrder.nodes.length === 0) {
      return true
    }
    let should_disable = false
    if (newOrderAction && newOrderAction.action_type === "dock" && newOrderAction.action_parameters) {
      newOrderAction.action_parameters.forEach((param: any) => {
        console.log('param', param)
        if (Array.isArray(param.value)) {
          param.value.forEach((paramEntry:any) => {
            if (paramEntry === "") {
              should_disable = true
            }
          })
        }
        if (param.value === "") {
          should_disable =  true
        }
      })
    }
    if (newOrder.name === "") {
      should_disable = true
    }
    return should_disable
  }, [newOrder, newOrderAction])

  return (
    <>{newOrder && <>

      <ToolbarTitle title={"Build Order"} onClick={onClickHandler} back="order" />
      <FormControl sx={{  }} fullWidth error={error}>
        <OutlinedInput placeholder="New Order Name" value={newOrder.name} onChange={handleChangeName} />
      </FormControl>
      <List>
      {ORDER_TYPES.map((orderType,id ) =>
        <Fragment key={id}>
        <ListItemButton
          key={id}
          sx={{ p: 0, borderRadius: 1 }}
          onClick={() => handleOrderTypeClick(orderType)}
        >
          {orderType === selectedOrderType ?
            <IconButton>
              <RadioButtonCheckedIcon sx={{ p: 0, color: "secondary.main", width: 18 }} />
            </IconButton>
            :
            <IconButton>
              <RadioButtonUncheckedIcon sx={{ p: 0, color: "secondary.main", width: 18}} />
            </IconButton>
          }

          <ListItemText>
            <Typography variant="body2">
              {orderType}
            </Typography>
          </ListItemText>

        </ListItemButton>
      </Fragment>
      )}
       </List>
       {!newOrderAction && (
         <>

         <FormControl fullWidth sx={{ mt: 3, paddingBottom: "10px" }} >
           <InputLabel id="demo-simple-select-helper-label3">Amr</InputLabel>
           <Select
             labelId="demo-simple-select-helper-label"
             id="demo-simple-select-helper3"
             value={selectedAmr ? selectedAmr : ""}
             label="AMR"
             onChange={handleSelectAmr}
             sx={{ fontSize: 14 }}
           >
             {availableAmrNames.map((amr, index) => <MenuItem key={amr} value={amr}>{amr}</MenuItem>)}
           </Select>
           <Button
             variant={"outlined"}
             sx={{ mb: 2, mt: 1 }}
             onClick={handleSetRobotAsStart(selectedAmr)}
           >
             Use Robot As Start
           </Button>
           <Divider  />

           <Button variant="outlined"  sx={{ mt: 2 }} onClick={clearCurrentPath}
                   >
             Clear Path
           </Button>
         </FormControl>
         <Box sx={{mt: 1, display: "flex", alignItems: "spaceBetween"}}>
           <Button variant="outlined" color={getPathTypeButtonColor("manual")} sx={{mr: 1 }} onClick={() => togglePathType("manual")}
                   >
             Manual
           </Button>
           <Button variant="outlined" color={getPathTypeButtonColor("auto")} sx={{ }} onClick={() => togglePathType("auto")}
                   >
             Auto
           </Button>
         </Box>
         <Typography sx={{ mb: 1, mt: 2 }} variant={"body2"}>
           Nodes: {" "} {newOrderNodes.length} <RouteIcon />
         </Typography>
          {
            newOrder.edges.map((edge, index) => {
              return <FormControl fullWidth error={error}>
                Max Speed (m/s) on edge: {index}:
                <TextField type={"number"} value={edge.max_speed} onChange={(e: any) => {
                  const newEdge = { ...edge, max_speed: e.target.value }
                  dispatch(updateOrderEdge(newEdge))
                }} />
              </FormControl>
            })
          }
         </>
       )}
       {renderActionParamters(selectedOrderType)}
       <svg id='order-info-graph' width={INFO_GRAPH_WIDTH} height={INFO_GRAPH_HEIGHT} />
       <Box sx={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "end" }}>
        <FormHelperText sx={{ textAlign: "center", mb: 2 }}>{error && "Could not save POI"}</FormHelperText>
        <Button
          variant={isAddingEdge ? "outlined" : "contained"}
          sx={{ p: 1.5 }}
          disabled={isSendDisabled()}
          onClick={() => handleSaveOrder(newOrderAction, newOrder)}
        >
          Save Order
        </Button>
      </Box>
    </>}
    </>
  )
}
