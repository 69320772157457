import { ROUTES } from "./routes";
import { useQuery } from "react-query";
import { UndockingParams } from "../model/Order";
import { DataFormatter } from "../utils/DataFormatter";
import { axiosInstance } from "./axios";

// TODO: remove everything regarding docking and undocking in the FE
export interface UndockParamsResponse {
  _id: string
  name: string,
  first_drive: number,
  second_drive: number,
  first_rotate: number,
  second_rotate: number
}

const getUnockParams = async () => {
  const { data } = await axiosInstance.get(`${ROUTES.undocking.url}`)
  return data.map((up: UndockParamsResponse) => DataFormatter.parseUndockParams(up));
};

export const getUndockParam = async (id: string) => {
  const { data } = await axiosInstance.get(`${ROUTES.undocking.url}/${id}`)
  return DataFormatter.parseUndockParams(data)
};

export function useUndockParams() {
  return useQuery(ROUTES.undocking.key, getUnockParams);
}

export function useUndockParam(id: string) {
  return useQuery([ROUTES.undocking.key, { id }], () => getUndockParam(id), { retry: false });
}

export const createUndockParam = async (data: { up: UndockingParams }) => {
  return axiosInstance.post(`${ROUTES.undocking.url}`, DataFormatter.formatUndockParams(data.up))
};

export const patchUndockParam = async (data: { up: UndockingParams }) => {
  return axiosInstance.patch(`${ROUTES.undocking.url}/${data.up.id}`, DataFormatter.formatUndockParams(data.up))
};

export const deleteUndockParam = async (undockParamID: string) => {
  return axiosInstance.delete(`${ROUTES.undocking.url}/${undockParamID}`)
};

