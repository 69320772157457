import {ROUTES} from "./routes";
import { axiosInstance } from "./axios";
import { IZone, mapZoneToApi, mapZoneFromApi } from "model/Zones"


export const getAllZones = async () => {
  const {data} = await axiosInstance.get(`${ROUTES.zones.url}`)
  return data.map(mapZoneFromApi)
};

export const getZone = async (id: string) => {
  const {data} = await axiosInstance.get(`${ROUTES.zones.url}/${id}`)
  return mapZoneFromApi(data)
};

export const createZone = async (zone: IZone) => {
  const { data } = await axiosInstance.post(`${ROUTES.zones.url}`, mapZoneToApi(zone))
  return data
}

export const deleteZone = async (id: string) => {
  const result = await axiosInstance.delete(`${ROUTES.zones.url}/${id}`)
  return result.status === 200
}
